"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubscriptionController = void 0;
const Utils_1 = require("@/Utils");
const snjs_1 = require("@standardnotes/snjs");
const mobx_1 = require("mobx");
const AbstractViewController_1 = require("../Abstract/AbstractViewController");
class SubscriptionController extends AbstractViewController_1.AbstractViewController {
    constructor(subscriptions, sessions, features, eventBus) {
        super(eventBus);
        this.subscriptions = subscriptions;
        this.sessions = sessions;
        this.features = features;
        this.ALLOWED_SUBSCRIPTION_INVITATIONS = 5;
        this.subscriptionInvitations = undefined;
        this.onlineSubscription = undefined;
        this.hasAccount = sessions.isSignedIn();
        (0, mobx_1.makeObservable)(this, {
            subscriptionInvitations: mobx_1.observable,
            hasAccount: mobx_1.observable,
            onlineSubscription: mobx_1.observable,
            usedInvitationsCount: mobx_1.computed,
            allowedInvitationsCount: mobx_1.computed,
            allInvitationsUsed: mobx_1.computed,
        });
        eventBus.addEventHandler(this, snjs_1.ApplicationEvent.Launched);
        eventBus.addEventHandler(this, snjs_1.ApplicationEvent.SignedIn);
        eventBus.addEventHandler(this, snjs_1.ApplicationEvent.UserRolesChanged);
        eventBus.addEventHandler(this, snjs_1.SubscriptionManagerEvent.DidFetchSubscription);
    }
    deinit() {
        super.deinit();
        this.subscriptionInvitations = undefined;
        (0, Utils_1.destroyAllObjectProperties)(this);
    }
    async handleEvent(event) {
        switch (event.type) {
            case snjs_1.ApplicationEvent.Launched: {
                if (this.sessions.isSignedIn()) {
                    this.reloadSubscriptionInvitations().catch(console.error);
                }
                (0, mobx_1.runInAction)(() => {
                    this.hasAccount = this.sessions.isSignedIn();
                });
                break;
            }
            case snjs_1.ApplicationEvent.SignedIn: {
                this.reloadSubscriptionInvitations().catch(console.error);
                (0, mobx_1.runInAction)(() => {
                    this.hasAccount = this.sessions.isSignedIn();
                });
                break;
            }
            case snjs_1.SubscriptionManagerEvent.DidFetchSubscription: {
                (0, mobx_1.runInAction)(() => {
                    this.onlineSubscription = this.subscriptions.getOnlineSubscription();
                });
                break;
            }
            case snjs_1.ApplicationEvent.UserRolesChanged: {
                this.reloadSubscriptionInvitations().catch(console.error);
                break;
            }
        }
    }
    hasFirstPartyOnlineOrOfflineSubscription() {
        const offline = this.features.hasFirstPartyOfflineSubscription();
        if (!this.sessions.isSignedIn() || !this.sessions.isSignedIntoFirstPartyServer()) {
            return offline;
        }
        return !!this.subscriptions.getOnlineSubscription() || offline;
    }
    get usedInvitationsCount() {
        var _a, _b;
        return ((_b = (_a = this.subscriptionInvitations) === null || _a === void 0 ? void 0 : _a.filter((invitation) => [snjs_1.InvitationStatus.Accepted, snjs_1.InvitationStatus.Sent].includes(invitation.status)).length) !== null && _b !== void 0 ? _b : 0);
    }
    get allowedInvitationsCount() {
        return this.ALLOWED_SUBSCRIPTION_INVITATIONS;
    }
    get allInvitationsUsed() {
        return this.usedInvitationsCount === this.ALLOWED_SUBSCRIPTION_INVITATIONS;
    }
    async sendSubscriptionInvitation(inviteeEmail) {
        const success = await this.subscriptions.inviteToSubscription(inviteeEmail);
        if (success) {
            await this.reloadSubscriptionInvitations();
        }
        return success;
    }
    async cancelSubscriptionInvitation(invitationUuid) {
        const success = await this.subscriptions.cancelInvitation(invitationUuid);
        if (success) {
            await this.reloadSubscriptionInvitations();
        }
        return success;
    }
    async reloadSubscriptionInvitations() {
        this.subscriptionInvitations = await this.subscriptions.listSubscriptionInvitations();
    }
}
exports.SubscriptionController = SubscriptionController;
