"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsApplicationUsingThirdPartyHost = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
class IsApplicationUsingThirdPartyHost {
    constructor(getHostUseCase) {
        this.getHostUseCase = getHostUseCase;
        this.APPLICATION_DEFAULT_HOSTS = ['api.standardnotes.com', 'sync.standardnotes.org', 'localhost:3123'];
        this.FILES_DEFAULT_HOSTS = ['files.standardnotes.com'];
    }
    execute() {
        const result = this.getHostUseCase.execute();
        if (result.isFailed()) {
            return domain_core_1.Result.fail(result.getError());
        }
        const host = result.getValue();
        return domain_core_1.Result.ok(!this.isUrlFirstParty(host));
    }
    isUrlFirstParty(url) {
        try {
            const { host } = new URL(url);
            return this.APPLICATION_DEFAULT_HOSTS.includes(host) || this.FILES_DEFAULT_HOSTS.includes(host);
        }
        catch (error) {
            return false;
        }
    }
}
exports.IsApplicationUsingThirdPartyHost = IsApplicationUsingThirdPartyHost;
