"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VaultDisplayService = void 0;
const services_1 = require("@standardnotes/services");
const models_1 = require("@standardnotes/models");
const VaultDisplayServiceEvent_1 = require("./VaultDisplayServiceEvent");
const AbstractUIService_1 = require("../Abstract/AbstractUIService");
const mobx_1 = require("mobx");
const domain_core_1 = require("@standardnotes/domain-core");
class VaultDisplayService extends AbstractUIService_1.AbstractUIService {
    constructor(application, internalEventBus) {
        super(application, internalEventBus);
        this.exclusivelyShownVault = undefined;
        this.isVaultExplicitlyExcluded = (vault) => {
            var _a;
            return (_a = this.options.isVaultExplicitlyExcluded(vault)) !== null && _a !== void 0 ? _a : false;
        };
        this.isVaultExclusivelyShown = (vault) => {
            return this.options.isVaultExclusivelyShown(vault);
        };
        this.hideVault = (vault) => {
            const lockedVaults = this.application.vaultLocks.getLockedvaults();
            const newOptions = this.options.newOptionsByExcludingVault(vault, lockedVaults);
            this.setVaultSelectionOptions(newOptions);
        };
        this.unhideVault = async (vault) => {
            if (this.application.vaultLocks.isVaultLocked(vault)) {
                const unlocked = await this.unlockVault(vault);
                if (!unlocked) {
                    return;
                }
            }
            const lockedVaults = this.application.vaultLocks.getLockedvaults();
            const newOptions = this.options.newOptionsByUnexcludingVault(vault, lockedVaults);
            this.setVaultSelectionOptions(newOptions);
        };
        this.showOnlyVault = async (vault) => {
            if (this.application.vaultLocks.isVaultLocked(vault)) {
                const unlocked = await this.unlockVault(vault);
                if (!unlocked) {
                    return;
                }
            }
            const newOptions = new models_1.VaultDisplayOptions({ exclusive: vault.systemIdentifier });
            this.setVaultSelectionOptions(newOptions);
        };
        this.setVaultSelectionOptions = (options) => {
            const previousOptions = this.options;
            this.options = options;
            const changingFromMultipleToExclusive = !previousOptions.isInExclusiveDisplayMode() && options.isInExclusiveDisplayMode();
            if (changingFromMultipleToExclusive) {
                this.previousMultipleSelectionOptions = previousOptions;
            }
            if (this.isInExclusiveDisplayMode()) {
                const vaultOrError = this.application.vaults.getVault({
                    keySystemIdentifier: this.options.getExclusivelyShownVault(),
                });
                this.exclusivelyShownVault = vaultOrError.isFailed() ? undefined : vaultOrError.getValue();
            }
            else {
                this.exclusivelyShownVault = undefined;
            }
            this.application.items.setVaultDisplayOptions(options);
            void this.notifyEvent(VaultDisplayServiceEvent_1.VaultDisplayServiceEvent.VaultDisplayOptionsChanged, options);
            if (this.application.isLaunched()) {
                this.application.setValue(services_1.StorageKey.VaultSelectionOptions, options.getPersistableValue());
            }
        };
        this.loadVaultSelectionOptionsFromDisk = () => {
            const raw = this.application.getValue(services_1.StorageKey.VaultSelectionOptions);
            if (!raw) {
                return;
            }
            const options = models_1.VaultDisplayOptions.FromPersistableValue(raw);
            this.options = options;
            void this.notifyEvent(VaultDisplayServiceEvent_1.VaultDisplayServiceEvent.VaultDisplayOptionsChanged, options);
        };
        this.options = new models_1.VaultDisplayOptions({ exclude: [], locked: [] });
        (0, mobx_1.makeObservable)(this, {
            options: mobx_1.observable,
            isVaultExplicitlyExcluded: mobx_1.observable,
            isVaultExclusivelyShown: mobx_1.observable,
            exclusivelyShownVault: mobx_1.observable,
            hideVault: mobx_1.action,
            unhideVault: mobx_1.action,
            showOnlyVault: mobx_1.action,
        });
        internalEventBus.addEventHandler(this, services_1.VaultLockServiceEvent.VaultLocked);
        internalEventBus.addEventHandler(this, services_1.VaultLockServiceEvent.VaultUnlocked);
        internalEventBus.addEventHandler(this, services_1.ApplicationEvent.ApplicationStageChanged);
        this.addObserver(application.items.streamItems(domain_core_1.ContentType.TYPES.VaultListing, ({ removed }) => {
            if (removed.some((vault) => { var _a; return vault.uuid === ((_a = this.exclusivelyShownVault) === null || _a === void 0 ? void 0 : _a.uuid); })) {
                this.changeToMultipleVaultDisplayMode();
            }
        }));
    }
    async handleEvent(event) {
        if (event.type === services_1.VaultLockServiceEvent.VaultLocked || event.type === services_1.VaultLockServiceEvent.VaultUnlocked) {
            this.handleVaultLockingStatusChanged();
        }
        else if (event.type === services_1.ApplicationEvent.ApplicationStageChanged) {
            const stage = event.payload.stage;
            if (stage === services_1.ApplicationStage.StorageDecrypted_09) {
                void this.loadVaultSelectionOptionsFromDisk();
            }
        }
    }
    handleVaultLockingStatusChanged() {
        const lockedVaults = this.application.vaultLocks.getLockedvaults();
        const options = this.options.newOptionsByIntakingLockedVaults(lockedVaults);
        this.setVaultSelectionOptions(options);
    }
    getOptions() {
        return this.options;
    }
    isVaultDisabledOrLocked(vault) {
        return this.options.isVaultDisabledOrLocked(vault);
    }
    isInExclusiveDisplayMode() {
        return this.options.isInExclusiveDisplayMode();
    }
    getItemVault(item) {
        if (this.application.items.isTemplateItem(item)) {
            if (this.exclusivelyShownVault) {
                return this.exclusivelyShownVault;
            }
            return undefined;
        }
        const vault = this.application.vaults.getItemVault(item);
        return vault;
    }
    changeToMultipleVaultDisplayMode() {
        const vaults = this.application.vaults.getVaults();
        const lockedVaults = this.application.vaultLocks.getLockedvaults();
        const exclude = this.previousMultipleSelectionOptions
            ? this.previousMultipleSelectionOptions.getOptions().exclude
            : vaults
                .map((vault) => vault.systemIdentifier)
                .filter((identifier) => { var _a; return identifier !== ((_a = this.exclusivelyShownVault) === null || _a === void 0 ? void 0 : _a.systemIdentifier); });
        const newOptions = new models_1.VaultDisplayOptions({
            exclude,
            locked: lockedVaults.map((vault) => vault.systemIdentifier),
        });
        this.setVaultSelectionOptions(newOptions);
    }
    async unlockVault(vault) {
        if (!this.application.vaultLocks.isVaultLocked(vault)) {
            throw new Error('Attempting to unlock a vault that is not locked.');
        }
        const challenge = new services_1.Challenge([new services_1.ChallengePrompt(services_1.ChallengeValidation.None, undefined, 'Password')], services_1.ChallengeReason.Custom, true, services_1.ChallengeStrings.UnlockVault(vault.name), services_1.ChallengeStrings.EnterVaultPassword);
        return new Promise((resolve) => {
            this.application.challenges.addChallengeObserver(challenge, {
                onCancel() {
                    resolve(false);
                },
                onNonvalidatedSubmit: async (challengeResponse) => {
                    const value = challengeResponse.getDefaultValue();
                    if (!value) {
                        this.application.challenges.completeChallenge(challenge);
                        resolve(false);
                        return;
                    }
                    const password = value.value;
                    const unlocked = await this.application.vaultLocks.unlockNonPersistentVault(vault, password);
                    if (!unlocked) {
                        this.application.challenges.setValidationStatusForChallenge(challenge, value, false);
                        resolve(false);
                        return;
                    }
                    this.application.challenges.completeChallenge(challenge);
                    resolve(true);
                },
            });
            void this.application.challenges.promptForChallengeResponse(challenge);
        });
    }
    deinit() {
        ;
        this.options = undefined;
        super.deinit();
    }
}
exports.VaultDisplayService = VaultDisplayService;
