"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetParagraphBlockOption = exports.ParagraphBlock = void 0;
const selection_1 = require("@lexical/selection");
const lexical_1 = require("lexical");
const BlockPickerOption_1 = require("../BlockPickerPlugin/BlockPickerOption");
exports.ParagraphBlock = {
    name: 'Paragraph',
    iconName: 'paragraph',
    keywords: ['normal', 'paragraph', 'p', 'text'],
    onSelect: (editor) => editor.update(() => {
        const selection = (0, lexical_1.$getSelection)();
        if ((0, lexical_1.$isRangeSelection)(selection)) {
            (0, selection_1.$setBlocksType)(selection, () => (0, lexical_1.$createParagraphNode)());
        }
    }),
};
function GetParagraphBlockOption(editor) {
    return new BlockPickerOption_1.BlockPickerOption(exports.ParagraphBlock.name, {
        iconName: exports.ParagraphBlock.iconName,
        keywords: exports.ParagraphBlock.keywords,
        onSelect: () => exports.ParagraphBlock.onSelect(editor),
    });
}
exports.GetParagraphBlockOption = GetParagraphBlockOption;
