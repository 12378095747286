"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HTMLConverter = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
const features_1 = require("@standardnotes/features");
const filepicker_1 = require("@standardnotes/filepicker");
const Utils_1 = require("../Utils");
class HTMLConverter {
    constructor(superConverterService, _generateUuid) {
        this.superConverterService = superConverterService;
        this._generateUuid = _generateUuid;
    }
    static isHTMLFile(file) {
        return file.type === 'text/html';
    }
    async convertHTMLFileToNote(file, isEntitledToSuper) {
        const content = await (0, Utils_1.readFileAsText)(file);
        const { name } = (0, filepicker_1.parseFileName)(file.name);
        const createdAtDate = file.lastModified ? new Date(file.lastModified) : new Date();
        const updatedAtDate = file.lastModified ? new Date(file.lastModified) : new Date();
        const text = isEntitledToSuper
            ? this.superConverterService.convertOtherFormatToSuperString(content, 'html')
            : content;
        return {
            created_at: createdAtDate,
            created_at_timestamp: createdAtDate.getTime(),
            updated_at: updatedAtDate,
            updated_at_timestamp: updatedAtDate.getTime(),
            uuid: this._generateUuid.execute().getValue(),
            content_type: domain_core_1.ContentType.TYPES.Note,
            content: {
                title: name,
                text,
                references: [],
                ...(isEntitledToSuper
                    ? {
                        noteType: features_1.NoteType.Super,
                        editorIdentifier: features_1.NativeFeatureIdentifier.TYPES.SuperEditor,
                    }
                    : {}),
            },
        };
    }
}
exports.HTMLConverter = HTMLConverter;
