"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsVaultAdmin = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
class IsVaultAdmin {
    constructor(users, cache) {
        this.users = users;
        this.cache = cache;
    }
    execute(vault) {
        var _a;
        if (!vault.sharing) {
            return domain_core_1.Result.ok(true);
        }
        if (!vault.sharing.ownerUserUuid) {
            throw new Error(`Shared vault ${vault.sharing.sharedVaultUuid} does not have an owner user uuid`);
        }
        const user = this.users.sureUser;
        const vaultUsers = this.cache.get(vault.sharing.sharedVaultUuid);
        const userPermission = (_a = vaultUsers === null || vaultUsers === void 0 ? void 0 : vaultUsers.find((vaultUser) => vaultUser.user_uuid === user.uuid)) === null || _a === void 0 ? void 0 : _a.permission;
        return domain_core_1.Result.ok(userPermission === domain_core_1.SharedVaultUserPermission.PERMISSIONS.Admin || vault.sharing.ownerUserUuid === user.uuid);
    }
}
exports.IsVaultAdmin = IsVaultAdmin;
