"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreferencesSessionController = void 0;
const mobx_1 = require("mobx");
const PackageProvider_1 = require("../Panes/General/Advanced/Packages/Provider/PackageProvider");
const securityPrefsHasBubble_1 = require("../Panes/Security/securityPrefsHasBubble");
const services_1 = require("@standardnotes/services");
const Utils_1 = require("@/Utils");
const FeatureTrunk_1 = require("@/FeatureTrunk");
const MenuItems_1 = require("./MenuItems");
/**
 * Unlike PreferencesController, the PreferencesSessionController is ephemeral and bound to a single opening of the
 * Preferences menu. It is created and destroyed each time the menu is opened and closed.
 */
class PreferencesSessionController {
    constructor(application, _enableUnfinishedFeatures) {
        this.application = application;
        this._enableUnfinishedFeatures = _enableUnfinishedFeatures;
        this._selectedPane = 'account';
        this._extensionLatestVersions = new PackageProvider_1.PackageProvider(new Map());
        this.selectPane = (key) => {
            this._selectedPane = key;
        };
        const menuItems = this._enableUnfinishedFeatures
            ? MenuItems_1.PREFERENCES_MENU_ITEMS.slice()
            : MenuItems_1.READY_PREFERENCES_MENU_ITEMS.slice();
        if (application.featuresController.isVaultsEnabled()) {
            menuItems.push({ id: 'vaults', label: 'Vaults', icon: 'safe-square', order: 5 });
        }
        if ((0, FeatureTrunk_1.featureTrunkHomeServerEnabled)() && (0, Utils_1.isDesktopApplication)()) {
            menuItems.push({ id: 'home-server', label: 'Home Server', icon: 'server', order: 5 });
        }
        this._menu = menuItems.sort((a, b) => a.order - b.order);
        this.loadLatestVersions();
        (0, mobx_1.makeAutoObservable)(this, {
            _twoFactorAuth: mobx_1.observable,
            _selectedPane: mobx_1.observable,
            _extensionPanes: mobx_1.observable.ref,
            _extensionLatestVersions: mobx_1.observable.ref,
            loadLatestVersions: mobx_1.action,
            updateMenuBubbleCounts: mobx_1.action,
        });
        this.application.status.addEventObserver((event) => {
            if (event === services_1.StatusServiceEvent.PreferencesBubbleCountChanged) {
                this.updateMenuBubbleCounts();
            }
        });
    }
    updateMenuBubbleCounts() {
        this._menu = this._menu.map((item) => {
            return {
                ...item,
                bubbleCount: this.application.status.getPreferencesBubbleCount(item.id),
            };
        });
    }
    loadLatestVersions() {
        PackageProvider_1.PackageProvider.load()
            .then((versions) => {
            if (versions) {
                this._extensionLatestVersions = versions;
            }
        })
            .catch(console.error);
    }
    get extensionsLatestVersions() {
        return this._extensionLatestVersions;
    }
    get menuItems() {
        const menuItems = this._menu.map((preference) => {
            const item = {
                ...preference,
                selected: preference.id === this._selectedPane,
                bubbleCount: this.application.status.getPreferencesBubbleCount(preference.id),
                hasErrorIndicator: this.sectionHasBubble(preference.id),
            };
            return item;
        });
        return menuItems;
    }
    get selectedMenuItem() {
        return this._menu.find((item) => item.id === this._selectedPane);
    }
    get selectedPaneId() {
        if (this.selectedMenuItem != undefined) {
            return this.selectedMenuItem.id;
        }
        return 'account';
    }
    sectionHasBubble(id) {
        if (id === 'security') {
            return (0, securityPrefsHasBubble_1.securityPrefsHasBubble)(this.application);
        }
        return false;
    }
}
exports.PreferencesSessionController = PreferencesSessionController;
