"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLongPressEvent = void 0;
const react_1 = require("react");
// According to https://reactnative.dev/docs/touchablewithoutfeedback#onlongpress
const ReactNativeLongpressDelay = 370;
const useLongPressEvent = (elementRef, listener, clearOnPointerMove = false, delay = ReactNativeLongpressDelay) => {
    const longPressTimeout = (0, react_1.useRef)();
    const pointerPosition = (0, react_1.useRef)();
    const clearLongPressTimeout = (0, react_1.useCallback)(() => {
        if (longPressTimeout.current) {
            clearTimeout(longPressTimeout.current);
        }
    }, []);
    const createLongPressTimeout = (0, react_1.useCallback)((event) => {
        clearLongPressTimeout();
        pointerPosition.current = { x: event.clientX, y: event.clientY };
        longPressTimeout.current = window.setTimeout(() => {
            var _a;
            (_a = elementRef.current) === null || _a === void 0 ? void 0 : _a.addEventListener('mousedown', (event) => {
                event.preventDefault();
                event.stopPropagation();
            }, { once: true, capture: true });
            const x = event.clientX;
            const y = event.clientY;
            listener(x, y);
        }, delay);
    }, [clearLongPressTimeout, delay, elementRef, listener]);
    const clearLongPressTimeoutIfMoved = (0, react_1.useCallback)((event) => {
        if (pointerPosition.current &&
            (event.clientX !== pointerPosition.current.x || event.clientY !== pointerPosition.current.y)) {
            clearLongPressTimeout();
        }
    }, [clearLongPressTimeout]);
    const attachEvents = (0, react_1.useCallback)(() => {
        if (!elementRef.current) {
            return;
        }
        elementRef.current.addEventListener('pointerdown', createLongPressTimeout);
        if (clearOnPointerMove) {
            elementRef.current.addEventListener('pointermove', clearLongPressTimeoutIfMoved);
        }
        elementRef.current.addEventListener('pointercancel', clearLongPressTimeout);
        elementRef.current.addEventListener('pointerup', clearLongPressTimeout);
    }, [clearLongPressTimeout, clearLongPressTimeoutIfMoved, clearOnPointerMove, createLongPressTimeout, elementRef]);
    const cleanupEvents = (0, react_1.useCallback)(() => {
        if (!elementRef.current) {
            return;
        }
        elementRef.current.removeEventListener('pointerdown', createLongPressTimeout);
        if (clearOnPointerMove) {
            elementRef.current.removeEventListener('pointermove', clearLongPressTimeoutIfMoved);
        }
        elementRef.current.removeEventListener('pointercancel', clearLongPressTimeout);
        elementRef.current.removeEventListener('pointerup', clearLongPressTimeout);
    }, [clearLongPressTimeout, clearLongPressTimeoutIfMoved, clearOnPointerMove, createLongPressTimeout, elementRef]);
    const memoizedReturn = (0, react_1.useMemo)(() => ({
        attachEvents,
        cleanupEvents,
    }), [attachEvents, cleanupEvents]);
    return memoizedReturn;
};
exports.useLongPressEvent = useLongPressEvent;
