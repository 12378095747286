"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItemListController = void 0;
const Utils_1 = require("@/Utils");
const snjs_1 = require("@standardnotes/snjs");
const mobx_1 = require("mobx");
const CrossControllerEvent_1 = require("../CrossControllerEvent");
const DateUtils_1 = require("@/Utils/DateUtils");
const AbstractViewController_1 = require("../Abstract/AbstractViewController");
const Logging_1 = require("@/Logging");
const NoteViewController_1 = require("@/Components/NoteView/Controller/NoteViewController");
const ItemsReloadSource_1 = require("./ItemsReloadSource");
const ui_services_1 = require("@standardnotes/ui-services");
const GetDayjsFormattedString_1 = require("@/Utils/GetDayjsFormattedString");
const CloseOpenModalsAndPopovers_1 = require("@/Utils/CloseOpenModalsAndPopovers");
const PaneLayout_1 = require("../PaneController/PaneLayout");
const MinNoteCellHeight = 51.0;
const DefaultListNumNotes = 20;
const ElementIdScrollContainer = 'notes-scrollable';
class ItemListController extends AbstractViewController_1.AbstractViewController {
    deinit() {
        super.deinit();
        this.noteFilterText = undefined;
        this.notes = undefined;
        this.renderedItems = undefined;
        this.navigationController = undefined;
        this.searchOptionsController = undefined;
        window.onresize = undefined;
        (0, Utils_1.destroyAllObjectProperties)(this);
    }
    constructor(keyboardService, paneController, navigationController, searchOptionsController, itemManager, preferences, itemControllerGroup, vaultDisplayService, desktopManager, protections, options, _isNativeMobileWeb, _changeAndSaveItem, eventBus) {
        super(eventBus);
        this.keyboardService = keyboardService;
        this.paneController = paneController;
        this.navigationController = navigationController;
        this.searchOptionsController = searchOptionsController;
        this.itemManager = itemManager;
        this.preferences = preferences;
        this.itemControllerGroup = itemControllerGroup;
        this.vaultDisplayService = vaultDisplayService;
        this.desktopManager = desktopManager;
        this.protections = protections;
        this.options = options;
        this._isNativeMobileWeb = _isNativeMobileWeb;
        this._changeAndSaveItem = _changeAndSaveItem;
        this.completedFullSync = false;
        this.noteFilterText = '';
        this.notes = [];
        this.items = [];
        this.notesToDisplay = 0;
        this.pageSize = 0;
        this.panelTitle = 'Notes';
        this.renderedItems = [];
        this.searchSubmitted = false;
        this.showDisplayOptionsMenu = false;
        this.displayOptions = {
            sortBy: snjs_1.CollectionSort.CreatedAt,
            sortDirection: 'dsc',
            includePinned: true,
            includeArchived: false,
            includeTrashed: false,
            includeProtected: true,
        };
        this.webDisplayOptions = {
            hideTags: true,
            hideDate: false,
            hideNotePreview: false,
            hideEditorIcon: false,
        };
        this.isTableViewEnabled = false;
        this.selectedUuids = (0, mobx_1.observable)(new Set());
        this.selectedItems = {};
        this.getPersistableValue = () => {
            return {
                selectedUuids: Array.from(this.selectedUuids),
            };
        };
        this.hydrateFromPersistedValue = (state) => {
            if (!state) {
                return;
            }
            if (!this.selectedUuids.size && state.selectedUuids.length > 0) {
                if (!this.options.allowNoteSelectionStatePersistence) {
                    const items = this.itemManager.findItems(state.selectedUuids).filter((item) => !(0, snjs_1.isNote)(item));
                    void this.selectUuids((0, snjs_1.Uuids)(items));
                }
                else {
                    void this.selectUuids(state.selectedUuids);
                }
            }
        };
        this.setCompletedFullSync = (completed) => {
            this.completedFullSync = completed;
        };
        this.setShowDisplayOptionsMenu = (enabled) => {
            this.showDisplayOptionsMenu = enabled;
        };
        this.reloadPanelTitle = () => {
            let title = this.panelTitle;
            if (this.isFiltering) {
                const resultCount = this.items.length;
                title = `${resultCount} search results`;
            }
            else if (this.navigationController.selected) {
                title = `${this.navigationController.selected.title}`;
            }
            this.panelTitle = title;
        };
        this.reloadItems = async (source) => {
            if (this.reloadItemsPromise) {
                await this.reloadItemsPromise;
            }
            this.reloadItemsPromise = this.performReloadItems(source);
            await this.reloadItemsPromise;
        };
        this.shouldLeaveSelectionUnchanged = (activeController) => {
            return activeController instanceof NoteViewController_1.NoteViewController && activeController.isTemplateNote;
        };
        /**
         * In some cases we want to keep the selected item open even if it doesn't appear in results,
         * for example if you are inside tag Foo and remove tag Foo from the note, we want to keep the note open.
         */
        this.shouldCloseActiveItem = (activeItem, source) => {
            if (source === ItemsReloadSource_1.ItemsReloadSource.UserTriggeredTagChange) {
                (0, Logging_1.log)(Logging_1.LoggingDomain.Selection, 'shouldCloseActiveItem true due to ItemsReloadSource.UserTriggeredTagChange');
                return true;
            }
            const activeItemExistsInUpdatedResults = this.items.find((item) => item.uuid === (activeItem === null || activeItem === void 0 ? void 0 : activeItem.uuid));
            const closeBecauseActiveItemIsFileAndDoesntExistInUpdatedResults = activeItem && (0, snjs_1.isFile)(activeItem) && !activeItemExistsInUpdatedResults;
            if (closeBecauseActiveItemIsFileAndDoesntExistInUpdatedResults) {
                (0, Logging_1.log)(Logging_1.LoggingDomain.Selection, 'shouldCloseActiveItem closeBecauseActiveItemIsFileAndDoesntExistInUpdatedResults');
                return true;
            }
            const firstItemInNewResults = this.getFirstNonProtectedItem();
            const closePreviousItemWhenSwitchingToFilesBasedView = firstItemInNewResults && (0, snjs_1.isFile)(firstItemInNewResults) && !activeItemExistsInUpdatedResults;
            if (closePreviousItemWhenSwitchingToFilesBasedView) {
                (0, Logging_1.log)(Logging_1.LoggingDomain.Selection, 'shouldCloseActiveItem closePreviousItemWhenSwitchingToFilesBasedView');
                return true;
            }
            const isSearching = this.noteFilterText.length > 0;
            const closeBecauseActiveItemDoesntExistInCurrentSystemView = !activeItemExistsInUpdatedResults && !isSearching && this.navigationController.isInAnySystemView();
            if (closeBecauseActiveItemDoesntExistInCurrentSystemView) {
                (0, Logging_1.log)(Logging_1.LoggingDomain.Selection, 'shouldCloseActiveItem closePreviousItemWhenSwitchingToFilesBasedView');
                return true;
            }
            (0, Logging_1.log)(Logging_1.LoggingDomain.Selection, 'shouldCloseActiveItem false');
            return false;
        };
        this.shouldSelectNextItemOrCreateNewNote = (activeItem) => {
            const selectedView = this.navigationController.selected;
            const isActiveItemTrashed = activeItem === null || activeItem === void 0 ? void 0 : activeItem.trashed;
            const isActiveItemArchived = activeItem === null || activeItem === void 0 ? void 0 : activeItem.archived;
            if (isActiveItemTrashed) {
                const selectedSmartViewShowsTrashed = selectedView instanceof snjs_1.SmartView && selectedView.predicate.keypathIncludesString('trashed');
                const shouldShowTrashedNotes = this.navigationController.isInSystemView(snjs_1.SystemViewId.TrashedNotes) ||
                    this.searchOptionsController.includeTrashed ||
                    selectedSmartViewShowsTrashed ||
                    this.displayOptions.includeTrashed;
                return !shouldShowTrashedNotes;
            }
            if (isActiveItemArchived) {
                const selectedSmartViewShowsArchived = selectedView instanceof snjs_1.SmartView && selectedView.predicate.keypathIncludesString('archived');
                const shouldShowArchivedNotes = this.navigationController.isInSystemView(snjs_1.SystemViewId.ArchivedNotes) ||
                    this.searchOptionsController.includeArchived ||
                    selectedSmartViewShowsArchived ||
                    this.displayOptions.includeArchived;
                return !shouldShowArchivedNotes;
            }
            return false;
        };
        this.shouldSelectActiveItem = (activeItem) => {
            return !this.isItemSelected(activeItem);
        };
        this.shouldSelectFirstItem = (itemsReloadSource) => {
            if (this._isNativeMobileWeb.execute().getValue()) {
                return false;
            }
            const item = this.getFirstNonProtectedItem();
            if (item && (0, snjs_1.isFile)(item)) {
                return false;
            }
            const selectedTag = this.navigationController.selected;
            const isDailyEntry = selectedTag && (0, snjs_1.isTag)(selectedTag) && selectedTag.isDailyEntry;
            if (isDailyEntry) {
                return false;
            }
            const userChangedTag = itemsReloadSource === ItemsReloadSource_1.ItemsReloadSource.UserTriggeredTagChange;
            const hasNoSelectedItem = !this.selectedUuids.size;
            return userChangedTag || hasNoSelectedItem;
        };
        this.reloadNotesDisplayOptions = () => {
            var _a, _b;
            const tag = this.navigationController.selected;
            const searchText = this.noteFilterText.toLowerCase();
            const isSearching = searchText.length;
            let includeArchived;
            let includeTrashed;
            if (isSearching) {
                includeArchived = this.searchOptionsController.includeArchived;
                includeTrashed = this.searchOptionsController.includeTrashed;
            }
            else {
                includeArchived = (_a = this.displayOptions.includeArchived) !== null && _a !== void 0 ? _a : false;
                includeTrashed = (_b = this.displayOptions.includeTrashed) !== null && _b !== void 0 ? _b : false;
            }
            const criteria = {
                sortBy: this.displayOptions.sortBy,
                sortDirection: this.displayOptions.sortDirection,
                tags: tag instanceof snjs_1.SNTag ? [tag] : [],
                views: tag instanceof snjs_1.SmartView ? [tag] : [],
                includeArchived,
                includeTrashed,
                includePinned: this.displayOptions.includePinned,
                includeProtected: this.displayOptions.includeProtected,
                searchQuery: {
                    query: searchText,
                    includeProtectedNoteText: this.searchOptionsController.includeProtectedContents,
                },
            };
            this.itemManager.setPrimaryItemDisplayOptions(criteria);
        };
        this.reloadDisplayPreferences = async ({ userTriggered, }) => {
            var _a;
            const newDisplayOptions = {};
            const newWebDisplayOptions = {};
            const selectedTag = this.navigationController.selected;
            const isSystemTag = selectedTag && (0, snjs_1.isSmartView)(selectedTag) && (0, snjs_1.isSystemView)(selectedTag);
            const selectedTagPreferences = isSystemTag
                ? (_a = this.preferences.getValue(snjs_1.PrefKey.SystemViewPreferences)) === null || _a === void 0 ? void 0 : _a[selectedTag.uuid]
                : selectedTag === null || selectedTag === void 0 ? void 0 : selectedTag.preferences;
            this.isTableViewEnabled = Boolean(selectedTagPreferences === null || selectedTagPreferences === void 0 ? void 0 : selectedTagPreferences.useTableView);
            const currentSortBy = this.displayOptions.sortBy;
            let sortBy = (selectedTagPreferences === null || selectedTagPreferences === void 0 ? void 0 : selectedTagPreferences.sortBy) ||
                this.preferences.getValue(snjs_1.PrefKey.SortNotesBy, snjs_1.PrefDefaults[snjs_1.PrefKey.SortNotesBy]);
            if (sortBy === snjs_1.CollectionSort.UpdatedAt || sortBy === 'client_updated_at') {
                sortBy = snjs_1.CollectionSort.UpdatedAt;
            }
            newDisplayOptions.sortBy = sortBy;
            const currentSortDirection = this.displayOptions.sortDirection;
            newDisplayOptions.sortDirection =
                (0, snjs_1.useBoolean)(selectedTagPreferences === null || selectedTagPreferences === void 0 ? void 0 : selectedTagPreferences.sortReverse, this.preferences.getValue(snjs_1.PrefKey.SortNotesReverse, snjs_1.PrefDefaults[snjs_1.PrefKey.SortNotesReverse])) === false
                    ? 'dsc'
                    : 'asc';
            newDisplayOptions.includeArchived = (0, snjs_1.useBoolean)(selectedTagPreferences === null || selectedTagPreferences === void 0 ? void 0 : selectedTagPreferences.showArchived, this.preferences.getValue(snjs_1.PrefKey.NotesShowArchived, snjs_1.PrefDefaults[snjs_1.PrefKey.NotesShowArchived]));
            newDisplayOptions.includeTrashed = (0, snjs_1.useBoolean)(selectedTagPreferences === null || selectedTagPreferences === void 0 ? void 0 : selectedTagPreferences.showTrashed, this.preferences.getValue(snjs_1.PrefKey.NotesShowTrashed, snjs_1.PrefDefaults[snjs_1.PrefKey.NotesShowTrashed]));
            newDisplayOptions.includePinned = !(0, snjs_1.useBoolean)(selectedTagPreferences === null || selectedTagPreferences === void 0 ? void 0 : selectedTagPreferences.hidePinned, this.preferences.getValue(snjs_1.PrefKey.NotesHidePinned, snjs_1.PrefDefaults[snjs_1.PrefKey.NotesHidePinned]));
            newDisplayOptions.includeProtected = !(0, snjs_1.useBoolean)(selectedTagPreferences === null || selectedTagPreferences === void 0 ? void 0 : selectedTagPreferences.hideProtected, this.preferences.getValue(snjs_1.PrefKey.NotesHideProtected, snjs_1.PrefDefaults[snjs_1.PrefKey.NotesHideProtected]));
            newWebDisplayOptions.hideNotePreview = (0, snjs_1.useBoolean)(selectedTagPreferences === null || selectedTagPreferences === void 0 ? void 0 : selectedTagPreferences.hideNotePreview, this.preferences.getValue(snjs_1.PrefKey.NotesHideNotePreview, snjs_1.PrefDefaults[snjs_1.PrefKey.NotesHideNotePreview]));
            newWebDisplayOptions.hideDate = (0, snjs_1.useBoolean)(selectedTagPreferences === null || selectedTagPreferences === void 0 ? void 0 : selectedTagPreferences.hideDate, this.preferences.getValue(snjs_1.PrefKey.NotesHideDate, snjs_1.PrefDefaults[snjs_1.PrefKey.NotesHideDate]));
            newWebDisplayOptions.hideTags = (0, snjs_1.useBoolean)(selectedTagPreferences === null || selectedTagPreferences === void 0 ? void 0 : selectedTagPreferences.hideTags, this.preferences.getValue(snjs_1.PrefKey.NotesHideTags, snjs_1.PrefDefaults[snjs_1.PrefKey.NotesHideTags]));
            newWebDisplayOptions.hideEditorIcon = (0, snjs_1.useBoolean)(selectedTagPreferences === null || selectedTagPreferences === void 0 ? void 0 : selectedTagPreferences.hideEditorIcon, this.preferences.getValue(snjs_1.PrefKey.NotesHideEditorIcon, snjs_1.PrefDefaults[snjs_1.PrefKey.NotesHideEditorIcon]));
            const displayOptionsChanged = newDisplayOptions.sortBy !== this.displayOptions.sortBy ||
                newDisplayOptions.sortDirection !== this.displayOptions.sortDirection ||
                newDisplayOptions.includePinned !== this.displayOptions.includePinned ||
                newDisplayOptions.includeArchived !== this.displayOptions.includeArchived ||
                newDisplayOptions.includeTrashed !== this.displayOptions.includeTrashed ||
                newDisplayOptions.includeProtected !== this.displayOptions.includeProtected ||
                newWebDisplayOptions.hideNotePreview !== this.webDisplayOptions.hideNotePreview ||
                newWebDisplayOptions.hideDate !== this.webDisplayOptions.hideDate ||
                newWebDisplayOptions.hideEditorIcon !== this.webDisplayOptions.hideEditorIcon ||
                newWebDisplayOptions.hideTags !== this.webDisplayOptions.hideTags;
            this.displayOptions = newDisplayOptions;
            this.webDisplayOptions = newWebDisplayOptions;
            if (!displayOptionsChanged) {
                return { didReloadItems: false };
            }
            this.reloadNotesDisplayOptions();
            await this.reloadItems(userTriggered ? ItemsReloadSource_1.ItemsReloadSource.UserTriggeredTagChange : ItemsReloadSource_1.ItemsReloadSource.DisplayOptionsChange);
            const didSortByChange = currentSortBy !== this.displayOptions.sortBy;
            const didSortDirectionChange = currentSortDirection !== this.displayOptions.sortDirection;
            const didSortPrefChange = didSortByChange || didSortDirectionChange;
            if (didSortPrefChange && this.shouldSelectFirstItem(ItemsReloadSource_1.ItemsReloadSource.DisplayOptionsChange)) {
                await this.selectFirstItem();
            }
            return { didReloadItems: true };
        };
        this.titleForNewNote = (createdAt) => {
            var _a, _b, _c;
            if (this.isFiltering) {
                return this.noteFilterText;
            }
            const selectedTag = this.navigationController.selected;
            const isSystemTag = selectedTag && (0, snjs_1.isSmartView)(selectedTag) && (0, snjs_1.isSystemView)(selectedTag);
            const selectedTagPreferences = isSystemTag
                ? (_a = this.preferences.getValue(snjs_1.PrefKey.SystemViewPreferences)) === null || _a === void 0 ? void 0 : _a[selectedTag.uuid]
                : selectedTag === null || selectedTag === void 0 ? void 0 : selectedTag.preferences;
            const titleFormat = (selectedTagPreferences === null || selectedTagPreferences === void 0 ? void 0 : selectedTagPreferences.newNoteTitleFormat) ||
                this.preferences.getValue(snjs_1.PrefKey.NewNoteTitleFormat, snjs_1.PrefDefaults[snjs_1.PrefKey.NewNoteTitleFormat]);
            if (titleFormat === snjs_1.NewNoteTitleFormat.CurrentNoteCount) {
                return `Note ${this.notes.length + 1}`;
            }
            if (titleFormat === snjs_1.NewNoteTitleFormat.CustomFormat) {
                const customFormat = ((_c = (_b = this.navigationController.selected) === null || _b === void 0 ? void 0 : _b.preferences) === null || _c === void 0 ? void 0 : _c.customNoteTitleFormat) ||
                    this.preferences.getValue(snjs_1.PrefKey.CustomNoteTitleFormat, snjs_1.PrefDefaults[snjs_1.PrefKey.CustomNoteTitleFormat]);
                try {
                    return (0, GetDayjsFormattedString_1.getDayjsFormattedString)(createdAt, customFormat);
                }
                catch (error) {
                    console.error(error);
                    return (0, DateUtils_1.formatDateAndTimeForNote)(createdAt || new Date());
                }
            }
            if (titleFormat === snjs_1.NewNoteTitleFormat.Empty) {
                return '';
            }
            return (0, DateUtils_1.formatDateAndTimeForNote)(createdAt || new Date());
        };
        this.createNewNote = async (title, createdAt, autofocusBehavior) => {
            void this.publishCrossControllerEventSync(CrossControllerEvent_1.CrossControllerEvent.UnselectAllNotes);
            if (this.navigationController.isInSmartView() && !this.navigationController.isInHomeView()) {
                await this.navigationController.selectHomeNavigationView();
            }
            const useTitle = title || this.titleForNewNote(createdAt);
            const controller = await this.createNewNoteController(useTitle, createdAt, autofocusBehavior);
            this.scrollToItem(controller.item);
        };
        this.createPlaceholderNote = () => {
            if (this.navigationController.isInSmartView() && !this.navigationController.isInHomeView()) {
                return;
            }
            return this.createNewNote();
        };
        this.paginate = () => {
            var _a;
            this.notesToDisplay += this.pageSize;
            void this.reloadItems(ItemsReloadSource_1.ItemsReloadSource.Pagination);
            if (this.searchSubmitted) {
                (_a = this.desktopManager) === null || _a === void 0 ? void 0 : _a.searchText(this.noteFilterText);
            }
        };
        this.resetPagination = (keepCurrentIfLarger = false) => {
            const clientHeight = document.documentElement.clientHeight;
            this.pageSize = Math.ceil(clientHeight / MinNoteCellHeight);
            if (this.pageSize === 0) {
                this.pageSize = DefaultListNumNotes;
            }
            if (keepCurrentIfLarger && this.notesToDisplay > this.pageSize) {
                return;
            }
            this.notesToDisplay = this.pageSize;
        };
        this.getFirstNonProtectedItem = () => {
            return this.items.find((item) => !item.protected);
        };
        this.selectFirstItem = async () => {
            const item = this.getFirstNonProtectedItem();
            if (this.isTableViewEnabled && !(0, Utils_1.isMobileScreen)()) {
                return;
            }
            if (item) {
                (0, Logging_1.log)(Logging_1.LoggingDomain.Selection, 'Selecting first item', item.uuid);
                await this.selectItemWithScrollHandling(item, {
                    userTriggered: false,
                    scrollIntoView: false,
                });
                this.resetScrollPosition();
            }
        };
        this.selectNextItemOrCreateNewNote = async () => {
            const item = this.getFirstNonProtectedItem();
            if (item) {
                (0, Logging_1.log)(Logging_1.LoggingDomain.Selection, 'selectNextItemOrCreateNewNote');
                await this.selectItemWithScrollHandling(item, {
                    userTriggered: false,
                    scrollIntoView: false,
                }).catch(console.error);
            }
            else {
                await this.createNewNote();
            }
        };
        this.setNoteFilterText = (text) => {
            if (text === this.noteFilterText) {
                return;
            }
            this.noteFilterText = text;
            this.handleFilterTextChanged();
        };
        this.handleEditorChange = async () => {
            var _a, _b;
            const activeNote = (_a = this.itemControllerGroup.activeItemViewController) === null || _a === void 0 ? void 0 : _a.item;
            if (activeNote && activeNote.conflictOf) {
                void this._changeAndSaveItem.execute(activeNote, (mutator) => {
                    mutator.conflictOf = undefined;
                });
            }
            if (this.isFiltering) {
                (_b = this.desktopManager) === null || _b === void 0 ? void 0 : _b.searchText(this.noteFilterText);
            }
        };
        this.resetScrollPosition = () => {
            if (this.notesListScrollContainer) {
                this.notesListScrollContainer.scrollTop = 0;
                this.notesListScrollContainer.scrollLeft = 0;
            }
        };
        this.handleTagChange = async (userTriggered) => {
            var _a;
            const activeNoteController = this.getActiveItemController();
            if (activeNoteController instanceof NoteViewController_1.NoteViewController && activeNoteController.isTemplateNote) {
                this.closeItemController(activeNoteController);
            }
            this.resetScrollPosition();
            this.setShowDisplayOptionsMenu(false);
            this.setNoteFilterText('');
            (_a = this.desktopManager) === null || _a === void 0 ? void 0 : _a.searchText();
            this.resetPagination();
            const { didReloadItems } = await this.reloadDisplayPreferences({ userTriggered });
            if (!didReloadItems) {
                this.reloadNotesDisplayOptions();
                void this.reloadItems(userTriggered ? ItemsReloadSource_1.ItemsReloadSource.UserTriggeredTagChange : ItemsReloadSource_1.ItemsReloadSource.TagChange);
            }
        };
        this.onFilterEnter = () => {
            var _a;
            /**
             * For Desktop, performing a search right away causes
             * input to lose focus. We wait until user explicity hits
             * enter before highlighting desktop search results.
             */
            this.searchSubmitted = true;
            (_a = this.desktopManager) === null || _a === void 0 ? void 0 : _a.searchText(this.noteFilterText);
        };
        this.handleFilterTextChanged = () => {
            if (this.searchSubmitted) {
                this.searchSubmitted = false;
            }
            this.reloadNotesDisplayOptions();
            void this.reloadItems(ItemsReloadSource_1.ItemsReloadSource.FilterTextChange);
        };
        this.clearFilterText = () => {
            this.setNoteFilterText('');
            this.onFilterEnter();
            this.handleFilterTextChanged();
            this.resetPagination();
        };
        this.getSelectedItems = () => {
            const uuids = Array.from(this.selectedUuids);
            return uuids.map((uuid) => this.itemManager.findSureItem(uuid)).filter((item) => !!item);
        };
        this.getFilteredSelectedItems = (contentType) => {
            return Object.values(this.selectedItems).filter((item) => {
                return !contentType ? true : item.content_type === contentType;
            });
        };
        this.setSelectedItems = () => {
            this.selectedItems = Object.fromEntries(this.getSelectedItems().map((item) => [item.uuid, item]));
        };
        this.setSelectedUuids = (selectedUuids) => {
            (0, Logging_1.log)(Logging_1.LoggingDomain.Selection, 'Setting selected uuids', selectedUuids);
            this.selectedUuids = new Set(selectedUuids);
            this.setSelectedItems();
        };
        this.removeSelectedItem = (uuid) => {
            this.selectedUuids.delete(uuid);
            this.setSelectedUuids(this.selectedUuids);
            delete this.selectedItems[uuid];
        };
        this.deselectItem = (item) => {
            var _a;
            (0, Logging_1.log)(Logging_1.LoggingDomain.Selection, 'Deselecting item', item.uuid);
            this.removeSelectedItem(item.uuid);
            if (item.uuid === ((_a = this.lastSelectedItem) === null || _a === void 0 ? void 0 : _a.uuid)) {
                this.lastSelectedItem = undefined;
            }
        };
        this.isItemSelected = (item) => {
            return this.selectedUuids.has(item.uuid);
        };
        this.selectItemsRange = async ({ selectedItem, startingIndex, endingIndex, }) => {
            const items = this.renderedItems;
            const lastSelectedItemIndex = startingIndex !== null && startingIndex !== void 0 ? startingIndex : items.findIndex((item) => { var _a; return item.uuid == ((_a = this.lastSelectedItem) === null || _a === void 0 ? void 0 : _a.uuid); });
            const selectedItemIndex = endingIndex !== null && endingIndex !== void 0 ? endingIndex : items.findIndex((item) => item.uuid == (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.uuid));
            let itemsToSelect = [];
            if (selectedItemIndex > lastSelectedItemIndex) {
                itemsToSelect = items.slice(lastSelectedItemIndex, selectedItemIndex + 1);
            }
            else {
                itemsToSelect = items.slice(selectedItemIndex, lastSelectedItemIndex + 1);
            }
            const authorizedItems = await this.protections.authorizeProtectedActionForItems(itemsToSelect, snjs_1.ChallengeReason.SelectProtectedNote);
            for (const item of authorizedItems) {
                (0, mobx_1.runInAction)(() => {
                    this.setSelectedUuids(this.selectedUuids.add(item.uuid));
                    this.lastSelectedItem = item;
                });
            }
        };
        this.cancelMultipleSelection = () => {
            this.keyboardService.cancelAllKeyboardModifiers();
            const firstSelectedItem = this.firstSelectedItem;
            if (firstSelectedItem) {
                this.replaceSelection(firstSelectedItem);
            }
            else {
                this.deselectAll();
            }
        };
        this.replaceSelection = (item) => {
            this.deselectAll();
            (0, mobx_1.runInAction)(() => this.setSelectedUuids(this.selectedUuids.add(item.uuid)));
            this.lastSelectedItem = item;
        };
        this.selectAll = () => {
            void this.selectItemsRange({
                startingIndex: 0,
                endingIndex: this.listLength - 1,
            });
        };
        this.deselectAll = () => {
            this.selectedUuids.clear();
            this.setSelectedUuids(this.selectedUuids);
            this.lastSelectedItem = undefined;
        };
        this.openSingleSelectedItem = async ({ userTriggered } = { userTriggered: true }) => {
            if (this.selectedItemsCount === 1) {
                const item = this.firstSelectedItem;
                if (item.content_type === snjs_1.ContentType.TYPES.Note) {
                    await this.openNote(item.uuid);
                }
                else if (item.content_type === snjs_1.ContentType.TYPES.File) {
                    await this.openFile(item.uuid);
                }
                if (!this.paneController.isInMobileView || userTriggered) {
                    void this.paneController.setPaneLayout(PaneLayout_1.PaneLayout.Editing);
                }
                if (this.paneController.isInMobileView && userTriggered) {
                    (0, CloseOpenModalsAndPopovers_1.requestCloseAllOpenModalsAndPopovers)();
                }
            }
        };
        this.selectItem = async (uuid, userTriggered) => {
            const item = this.itemManager.findItem(uuid);
            if (!item) {
                return {
                    didSelect: false,
                };
            }
            (0, Logging_1.log)(Logging_1.LoggingDomain.Selection, 'Select item', item.uuid);
            const supportsMultipleSelection = this.options.allowMultipleSelection;
            const hasMeta = this.keyboardService.activeModifiers.has(snjs_1.KeyboardModifier.Meta);
            const hasCtrl = this.keyboardService.activeModifiers.has(snjs_1.KeyboardModifier.Ctrl);
            const hasShift = this.keyboardService.activeModifiers.has(snjs_1.KeyboardModifier.Shift);
            const hasMoreThanOneSelected = this.selectedItemsCount > 1;
            const isAuthorizedForAccess = await this.protections.authorizeItemAccess(item);
            if (supportsMultipleSelection && userTriggered && (hasMeta || hasCtrl)) {
                if (this.selectedUuids.has(uuid) && hasMoreThanOneSelected) {
                    this.removeSelectedItem(uuid);
                }
                else if (isAuthorizedForAccess) {
                    this.selectedUuids.add(uuid);
                    this.setSelectedUuids(this.selectedUuids);
                    this.lastSelectedItem = item;
                }
            }
            else if (supportsMultipleSelection && userTriggered && hasShift) {
                await this.selectItemsRange({ selectedItem: item });
            }
            else {
                const shouldSelectNote = hasMoreThanOneSelected || !this.selectedUuids.has(uuid);
                if (shouldSelectNote && isAuthorizedForAccess) {
                    this.replaceSelection(item);
                }
            }
            await this.openSingleSelectedItem({ userTriggered: userTriggered !== null && userTriggered !== void 0 ? userTriggered : false });
            return {
                didSelect: this.selectedUuids.has(uuid),
            };
        };
        this.selectItemWithScrollHandling = async (item, { userTriggered = false, scrollIntoView = true, animated = true }) => {
            const { didSelect } = await this.selectItem(item.uuid, userTriggered);
            const avoidMobileScrollingDueToIncompatibilityWithPaneAnimations = (0, Utils_1.isMobileScreen)();
            if (didSelect && scrollIntoView && !avoidMobileScrollingDueToIncompatibilityWithPaneAnimations) {
                this.scrollToItem(item, animated);
            }
        };
        this.scrollToItem = (item, animated = true) => {
            const itemElement = document.getElementById(item.uuid);
            itemElement === null || itemElement === void 0 ? void 0 : itemElement.scrollIntoView({
                behavior: animated ? 'smooth' : 'auto',
            });
        };
        this.selectUuids = async (uuids, userTriggered = false) => {
            const itemsForUuids = this.itemManager.findItems(uuids).filter((item) => !(0, snjs_1.isFile)(item));
            if (itemsForUuids.length < 1) {
                return;
            }
            if (!userTriggered && itemsForUuids.some((item) => item.protected && (0, snjs_1.isFile)(item))) {
                return;
            }
            this.setSelectedUuids(new Set((0, snjs_1.Uuids)(itemsForUuids)));
            if (itemsForUuids.length === 1) {
                void this.openSingleSelectedItem({ userTriggered });
            }
        };
        this.selectNextItem = ({ userTriggered } = { userTriggered: true }) => {
            const displayableItems = this.items;
            const currentIndex = displayableItems.findIndex((candidate) => {
                var _a;
                return candidate.uuid === ((_a = this.lastSelectedItem) === null || _a === void 0 ? void 0 : _a.uuid);
            });
            let nextIndex = currentIndex + 1;
            while (nextIndex < displayableItems.length) {
                const nextItem = displayableItems[nextIndex];
                nextIndex++;
                if (nextItem.protected) {
                    continue;
                }
                this.selectItemWithScrollHandling(nextItem, { userTriggered }).catch(console.error);
                const nextNoteElement = document.getElementById(nextItem.uuid);
                nextNoteElement === null || nextNoteElement === void 0 ? void 0 : nextNoteElement.focus();
                return;
            }
        };
        this.selectPreviousItem = () => {
            const displayableItems = this.items;
            if (!this.lastSelectedItem) {
                return;
            }
            const currentIndex = displayableItems.indexOf(this.lastSelectedItem);
            let previousIndex = currentIndex - 1;
            while (previousIndex >= 0) {
                const previousItem = displayableItems[previousIndex];
                previousIndex--;
                if (previousItem.protected) {
                    continue;
                }
                this.selectItemWithScrollHandling(previousItem, { userTriggered: true }).catch(console.error);
                const previousNoteElement = document.getElementById(previousItem.uuid);
                previousNoteElement === null || previousNoteElement === void 0 ? void 0 : previousNoteElement.focus();
                return;
            }
        };
        (0, mobx_1.makeObservable)(this, {
            completedFullSync: mobx_1.observable,
            displayOptions: mobx_1.observable.struct,
            webDisplayOptions: mobx_1.observable.struct,
            noteFilterText: mobx_1.observable,
            notes: mobx_1.observable,
            notesToDisplay: mobx_1.observable,
            panelTitle: mobx_1.observable,
            items: mobx_1.observable,
            renderedItems: mobx_1.observable,
            showDisplayOptionsMenu: mobx_1.observable,
            reloadItems: mobx_1.action,
            reloadPanelTitle: mobx_1.action,
            reloadDisplayPreferences: mobx_1.action,
            resetPagination: mobx_1.action,
            setCompletedFullSync: mobx_1.action,
            setNoteFilterText: mobx_1.action,
            setShowDisplayOptionsMenu: mobx_1.action,
            onFilterEnter: mobx_1.action,
            handleFilterTextChanged: mobx_1.action,
            optionsSubtitle: mobx_1.computed,
            activeControllerItem: mobx_1.computed,
            selectedUuids: mobx_1.observable,
            selectedItems: mobx_1.observable,
            selectedItemsCount: mobx_1.computed,
            selectedFiles: mobx_1.computed,
            selectedFilesCount: mobx_1.computed,
            firstSelectedItem: mobx_1.computed,
            selectItem: mobx_1.action,
            setSelectedUuids: mobx_1.action,
            setSelectedItems: mobx_1.action,
            hydrateFromPersistedValue: mobx_1.action,
        });
        eventBus.addEventHandler(this, CrossControllerEvent_1.CrossControllerEvent.TagChanged);
        eventBus.addEventHandler(this, CrossControllerEvent_1.CrossControllerEvent.ActiveEditorChanged);
        eventBus.addEventHandler(this, ui_services_1.VaultDisplayServiceEvent.VaultDisplayOptionsChanged);
        this.resetPagination();
        this.disposers.push(itemManager.streamItems([snjs_1.ContentType.TYPES.Note, snjs_1.ContentType.TYPES.File], () => {
            void this.reloadItems(ItemsReloadSource_1.ItemsReloadSource.ItemStream);
        }));
        this.disposers.push(itemManager.streamItems([snjs_1.ContentType.TYPES.Tag, snjs_1.ContentType.TYPES.SmartView], async ({ changed, inserted }) => {
            const tags = [...changed, ...inserted];
            const { didReloadItems } = await this.reloadDisplayPreferences({ userTriggered: false });
            if (!didReloadItems) {
                /** A tag could have changed its relationships, so we need to reload the filter */
                this.reloadNotesDisplayOptions();
                void this.reloadItems(ItemsReloadSource_1.ItemsReloadSource.ItemStream);
            }
            if (this.navigationController.selected &&
                (0, snjs_1.findInArray)(tags, 'uuid', this.navigationController.selected.uuid)) {
                /** Tag title could have changed */
                this.reloadPanelTitle();
            }
        }));
        eventBus.addEventHandler(this, snjs_1.ApplicationEvent.PreferencesChanged);
        eventBus.addEventHandler(this, snjs_1.ApplicationEvent.SignedIn);
        eventBus.addEventHandler(this, snjs_1.ApplicationEvent.CompletedFullSync);
        eventBus.addEventHandler(this, snjs_1.WebAppEvent.EditorFocused);
        this.disposers.push((0, mobx_1.reaction)(() => [
            this.searchOptionsController.includeProtectedContents,
            this.searchOptionsController.includeArchived,
            this.searchOptionsController.includeTrashed,
        ], () => {
            this.reloadNotesDisplayOptions();
            void this.reloadItems(ItemsReloadSource_1.ItemsReloadSource.DisplayOptionsChange);
        }));
        this.disposers.push((0, mobx_1.reaction)(() => this.selectedUuids, () => {
            eventBus.publish({
                type: CrossControllerEvent_1.CrossControllerEvent.RequestValuePersistence,
                payload: undefined,
            });
        }));
        this.disposers.push(this.itemManager.streamItems([snjs_1.ContentType.TYPES.Note, snjs_1.ContentType.TYPES.File], ({ changed, inserted, removed }) => {
            (0, mobx_1.runInAction)(() => {
                for (const removedItem of removed) {
                    this.removeSelectedItem(removedItem.uuid);
                }
                for (const item of [...changed, ...inserted]) {
                    if (this.selectedItems[item.uuid]) {
                        this.selectedItems[item.uuid] = item;
                    }
                }
            });
        }));
        window.onresize = () => {
            this.resetPagination(true);
        };
    }
    async handleEvent(event) {
        switch (event.type) {
            case CrossControllerEvent_1.CrossControllerEvent.TagChanged: {
                const payload = event.payload;
                await this.handleTagChange(payload.userTriggered);
                break;
            }
            case CrossControllerEvent_1.CrossControllerEvent.ActiveEditorChanged: {
                await this.handleEditorChange();
                break;
            }
            case ui_services_1.VaultDisplayServiceEvent.VaultDisplayOptionsChanged: {
                void this.reloadItems(ItemsReloadSource_1.ItemsReloadSource.DisplayOptionsChange);
                break;
            }
            case snjs_1.ApplicationEvent.PreferencesChanged: {
                void this.reloadDisplayPreferences({ userTriggered: false });
                break;
            }
            case snjs_1.WebAppEvent.EditorFocused: {
                this.setShowDisplayOptionsMenu(false);
                break;
            }
            case snjs_1.ApplicationEvent.SignedIn: {
                this.itemControllerGroup.closeAllItemControllers();
                void this.selectFirstItem();
                this.setCompletedFullSync(false);
                break;
            }
            case snjs_1.ApplicationEvent.CompletedFullSync: {
                if (!this.completedFullSync) {
                    void this.reloadItems(ItemsReloadSource_1.ItemsReloadSource.SyncEvent).then(() => {
                        var _a;
                        if (this.notes.length === 0 &&
                            this.navigationController.selected instanceof snjs_1.SmartView &&
                            this.navigationController.selected.uuid === snjs_1.SystemViewId.AllNotes &&
                            this.noteFilterText === '' &&
                            !this.getActiveItemController()) {
                            (_a = this.createPlaceholderNote()) === null || _a === void 0 ? void 0 : _a.catch(console.error);
                        }
                    });
                    this.setCompletedFullSync(true);
                    break;
                }
            }
        }
    }
    get listLength() {
        return this.renderedItems.length;
    }
    getActiveItemController() {
        return this.itemControllerGroup.activeItemViewController;
    }
    get activeControllerItem() {
        var _a;
        return (_a = this.getActiveItemController()) === null || _a === void 0 ? void 0 : _a.item;
    }
    async openNote(uuid) {
        var _a;
        if (((_a = this.activeControllerItem) === null || _a === void 0 ? void 0 : _a.uuid) === uuid) {
            return;
        }
        const note = this.itemManager.findItem(uuid);
        if (!note) {
            console.warn('Tried accessing a non-existant note of UUID ' + uuid);
            return;
        }
        await this.itemControllerGroup.createItemController({ note });
        await this.publishCrossControllerEventSync(CrossControllerEvent_1.CrossControllerEvent.ActiveEditorChanged);
    }
    async openFile(fileUuid) {
        var _a;
        if (((_a = this.getActiveItemController()) === null || _a === void 0 ? void 0 : _a.item.uuid) === fileUuid) {
            return;
        }
        const file = this.itemManager.findItem(fileUuid);
        if (!file) {
            console.warn('Tried accessing a non-existant file of UUID ' + fileUuid);
            return;
        }
        await this.itemControllerGroup.createItemController({ file });
    }
    get isFiltering() {
        return !!this.noteFilterText && this.noteFilterText.length > 0;
    }
    async performReloadItems(source) {
        const tag = this.navigationController.selected;
        if (!tag) {
            return;
        }
        const notes = this.itemManager.getDisplayableNotes();
        const items = this.itemManager.getDisplayableNotesAndFiles();
        const renderedItems = items.slice(0, this.notesToDisplay);
        (0, mobx_1.runInAction)(() => {
            this.notes = notes;
            this.items = items;
            this.renderedItems = renderedItems;
        });
        await this.recomputeSelectionAfterItemsReload(source);
        this.reloadPanelTitle();
    }
    async recomputeSelectionAfterItemsReload(itemsReloadSource) {
        const activeController = this.getActiveItemController();
        if (this.shouldLeaveSelectionUnchanged(activeController)) {
            (0, Logging_1.log)(Logging_1.LoggingDomain.Selection, 'Leaving selection unchanged');
            return;
        }
        const activeItem = activeController === null || activeController === void 0 ? void 0 : activeController.item;
        if (activeController && activeItem && this.shouldCloseActiveItem(activeItem, itemsReloadSource)) {
            this.closeItemController(activeController);
            this.deselectItem(activeItem);
            if (this.shouldSelectFirstItem(itemsReloadSource)) {
                if (this.isTableViewEnabled && !(0, Utils_1.isMobileScreen)()) {
                    return;
                }
                (0, Logging_1.log)(Logging_1.LoggingDomain.Selection, 'Selecting next item after closing active one');
                this.selectNextItem({ userTriggered: false });
            }
        }
        else if (activeItem && this.shouldSelectActiveItem(activeItem)) {
            (0, Logging_1.log)(Logging_1.LoggingDomain.Selection, 'Selecting active item');
            await this.selectItem(activeItem.uuid).catch(console.error);
        }
        else if (this.shouldSelectFirstItem(itemsReloadSource)) {
            await this.selectFirstItem();
        }
        else if (this.shouldSelectNextItemOrCreateNewNote(activeItem)) {
            await this.selectNextItemOrCreateNewNote();
        }
        else {
            (0, Logging_1.log)(Logging_1.LoggingDomain.Selection, 'No selection change');
        }
    }
    async createNewNoteController(title, createdAt, autofocusBehavior = 'editor') {
        const selectedTag = this.navigationController.selected;
        const activeRegularTagUuid = selectedTag instanceof snjs_1.SNTag ? selectedTag.uuid : undefined;
        return this.itemControllerGroup.createItemController({
            templateOptions: {
                title,
                tag: activeRegularTagUuid,
                createdAt,
                autofocusBehavior,
                vault: this.vaultDisplayService.exclusivelyShownVault,
            },
        });
    }
    get optionsSubtitle() {
        if (!this.displayOptions.includePinned && !this.displayOptions.includeProtected) {
            return 'Excluding pinned and protected';
        }
        if (!this.displayOptions.includePinned) {
            return 'Excluding pinned';
        }
        if (!this.displayOptions.includeProtected) {
            return 'Excluding protected';
        }
        return undefined;
    }
    get notesListScrollContainer() {
        return document.getElementById(ElementIdScrollContainer);
    }
    closeItemController(controller) {
        (0, Logging_1.log)(Logging_1.LoggingDomain.Selection, 'Closing item controller', controller.runtimeId);
        this.itemControllerGroup.closeItemController(controller);
    }
    get isCurrentNoteTemplate() {
        const controller = this.getActiveItemController();
        if (!controller) {
            return false;
        }
        return controller instanceof NoteViewController_1.NoteViewController && controller.isTemplateNote;
    }
    async insertCurrentIfTemplate() {
        const controller = this.getActiveItemController();
        if (!controller) {
            return;
        }
        if (controller instanceof NoteViewController_1.NoteViewController && controller.isTemplateNote) {
            await controller.insertTemplatedNote();
        }
    }
    get selectedItemsCount() {
        return Object.keys(this.selectedItems).length;
    }
    get selectedFiles() {
        return this.getFilteredSelectedItems(snjs_1.ContentType.TYPES.File);
    }
    get selectedFilesCount() {
        return this.selectedFiles.length;
    }
    get firstSelectedItem() {
        return Object.values(this.selectedItems)[0];
    }
}
exports.ItemListController = ItemListController;
