"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const LexicalComposerContext_1 = require("@lexical/react/LexicalComposerContext");
const react_1 = require("react");
const markdown_1 = require("@lexical/markdown");
const lexical_1 = require("lexical");
const Utils_1 = require("../../Utils");
const SuperEditor_1 = require("../../SuperEditor");
const html_1 = require("@lexical/html");
const MarkdownTransformers_1 = require("../../MarkdownTransformers");
/** Note that markdown conversion does not insert new lines. See: https://github.com/facebook/lexical/issues/2815 */
function ImportPlugin({ text, format, onChange, customImportFunction, }) {
    const [editor] = (0, LexicalComposerContext_1.useLexicalComposerContext)();
    (0, react_1.useEffect)(() => {
        const dontAllowConversionOfEmptyStringWhichWouldResultInError = text.length === 0;
        if (dontAllowConversionOfEmptyStringWhichWouldResultInError) {
            return;
        }
        if (customImportFunction) {
            customImportFunction(editor, text);
            return;
        }
        editor.update(() => {
            if (format === 'md') {
                (0, markdown_1.$convertFromMarkdownString)(text, MarkdownTransformers_1.MarkdownTransformers);
            }
            else {
                const parser = new DOMParser();
                const dom = parser.parseFromString(text, 'text/html');
                const nodesToInsert = (0, html_1.$generateNodesFromDOM)(editor, dom);
                const selection = (0, lexical_1.$createRangeSelection)();
                const newLineNode = (0, lexical_1.$createParagraphNode)();
                selection.insertNodes([newLineNode, ...nodesToInsert]);
            }
        });
    }, [editor, text, format, customImportFunction]);
    (0, react_1.useEffect)(() => {
        return editor.registerUpdateListener(({ editorState }) => {
            editorState.read(() => {
                (0, Utils_1.handleEditorChange)(editorState, SuperEditor_1.SuperNotePreviewCharLimit, onChange);
            });
        });
    }, [editor, onChange]);
    return null;
}
exports.default = ImportPlugin;
