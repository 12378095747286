"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThemeManager = void 0;
const toast_1 = require("@standardnotes/toast");
const models_1 = require("@standardnotes/models");
const services_1 = require("@standardnotes/services");
const features_1 = require("@standardnotes/features");
const AbstractUIService_1 = require("../Abstract/AbstractUIService");
const GetAllThemesUseCase_1 = require("./GetAllThemesUseCase");
const ActiveThemeList_1 = require("./ActiveThemeList");
const Color_1 = require("./Color");
const CachedThemesKey = 'cachedThemes';
const TimeBeforeApplyingColorScheme = 5;
const DefaultThemeIdentifier = 'Default';
class ThemeManager extends AbstractUIService_1.AbstractUIService {
    constructor(application, preferences, components, internalEventBus) {
        super(application, internalEventBus);
        this.preferences = preferences;
        this.components = components;
        this.lastUseDeviceThemeSettings = false;
        this.colorSchemeEventHandler = this.colorSchemeEventHandler.bind(this);
        this.themesActiveInTheUI = new ActiveThemeList_1.ActiveThemeList(application.items);
    }
    deinit() {
        this.themesActiveInTheUI.clear();
        this.themesActiveInTheUI = undefined;
        this.preferences = undefined;
        this.components = undefined;
        const mq = window.matchMedia('(prefers-color-scheme: dark)');
        if (mq.removeEventListener != undefined) {
            mq.removeEventListener('change', this.colorSchemeEventHandler);
        }
        else {
            mq.removeListener(this.colorSchemeEventHandler);
        }
        super.deinit();
    }
    async onAppStart() {
        const desktopService = this.application.desktopManager;
        if (desktopService) {
            this.eventDisposers.push(desktopService.registerUpdateObserver((component) => {
                const uiFeature = new models_1.UIFeature(component);
                if (uiFeature.isThemeComponent) {
                    if (this.components.isThemeActive(uiFeature)) {
                        this.deactivateThemeInTheUI(uiFeature.uniqueIdentifier);
                        setTimeout(() => {
                            this.activateTheme(uiFeature);
                            this.cacheThemeState().catch(console.error);
                        }, 10);
                    }
                }
            }));
        }
        this.eventDisposers.push(this.preferences.addEventObserver(async (event) => {
            if (event !== services_1.PreferencesServiceEvent.PreferencesChanged) {
                return;
            }
            this.toggleTranslucentUIColors();
            let hasChange = false;
            const { features, uuids } = this.components.getActiveThemesIdentifiers();
            const featuresList = new ActiveThemeList_1.ActiveThemeList(this.application.items, features);
            const uuidsList = new ActiveThemeList_1.ActiveThemeList(this.application.items, uuids);
            for (const active of this.themesActiveInTheUI.getList()) {
                if (!featuresList.has(active) && !uuidsList.has(active)) {
                    this.deactivateThemeInTheUI(active);
                    hasChange = true;
                }
            }
            for (const feature of features) {
                if (!this.themesActiveInTheUI.has(feature)) {
                    const theme = (0, features_1.FindNativeTheme)(feature.value);
                    if (theme) {
                        const uiFeature = new models_1.UIFeature(theme);
                        this.activateTheme(uiFeature);
                        hasChange = true;
                    }
                }
            }
            for (const uuid of uuids) {
                if (!this.themesActiveInTheUI.has(uuid)) {
                    const theme = this.application.items.findItem(uuid.value);
                    if (theme) {
                        const uiFeature = new models_1.UIFeature(theme);
                        this.activateTheme(uiFeature);
                        hasChange = true;
                    }
                }
            }
            if (hasChange) {
                this.cacheThemeState().catch(console.error);
            }
        }));
    }
    async onAppEvent(event) {
        var _a;
        switch (event) {
            case services_1.ApplicationEvent.SignedOut: {
                this.deactivateAllThemes();
                this.themesActiveInTheUI.clear();
                (_a = this.application) === null || _a === void 0 ? void 0 : _a.removeValue(CachedThemesKey, services_1.StorageValueModes.Nonwrapped).catch(console.error);
                break;
            }
            case services_1.ApplicationEvent.StorageReady: {
                await this.activateCachedThemes();
                break;
            }
            case services_1.ApplicationEvent.FeaturesAvailabilityChanged: {
                this.handleFeaturesAvailabilityChanged();
                break;
            }
            case services_1.ApplicationEvent.Launched: {
                if (!this.application.isNativeMobileWeb()) {
                    const mq = window.matchMedia('(prefers-color-scheme: dark)');
                    if (mq.addEventListener != undefined) {
                        mq.addEventListener('change', this.colorSchemeEventHandler);
                    }
                    else {
                        mq.addListener(this.colorSchemeEventHandler);
                    }
                }
                break;
            }
            case services_1.ApplicationEvent.PreferencesChanged: {
                void this.handlePreferencesChangeEvent();
                break;
            }
        }
    }
    async handleMobileColorSchemeChangeEvent() {
        const useDeviceThemeSettings = this.application.getPreference(models_1.PrefKey.UseSystemColorScheme, false);
        if (useDeviceThemeSettings) {
            const prefersDarkColorScheme = (await this.application.mobileDevice.getColorScheme()) === 'dark';
            this.setThemeAsPerColorScheme(prefersDarkColorScheme);
        }
    }
    async handlePreferencesChangeEvent() {
        this.toggleTranslucentUIColors();
        const useDeviceThemeSettings = this.application.getPreference(models_1.PrefKey.UseSystemColorScheme, false);
        const hasPreferenceChanged = useDeviceThemeSettings !== this.lastUseDeviceThemeSettings;
        if (hasPreferenceChanged) {
            this.lastUseDeviceThemeSettings = useDeviceThemeSettings;
        }
        if (hasPreferenceChanged && useDeviceThemeSettings) {
            let prefersDarkColorScheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
            if (this.application.isNativeMobileWeb()) {
                prefersDarkColorScheme = (await this.application.mobileDevice.getColorScheme()) === 'dark';
            }
            this.setThemeAsPerColorScheme(prefersDarkColorScheme);
        }
    }
    handleFeaturesAvailabilityChanged() {
        let hasChange = false;
        for (const theme of this.themesActiveInTheUI.asThemes()) {
            const status = this.application.features.getFeatureStatus(theme.uniqueIdentifier);
            if (status !== services_1.FeatureStatus.Entitled) {
                this.deactivateThemeInTheUI(theme.uniqueIdentifier);
                hasChange = true;
            }
        }
        const activeThemes = this.components.getActiveThemes();
        for (const theme of activeThemes) {
            if (!this.themesActiveInTheUI.has(theme.uniqueIdentifier)) {
                this.activateTheme(theme);
                hasChange = true;
            }
        }
        if (hasChange) {
            void this.cacheThemeState();
        }
    }
    colorSchemeEventHandler(event) {
        const shouldChangeTheme = this.application.getPreference(models_1.PrefKey.UseSystemColorScheme, false);
        if (shouldChangeTheme) {
            this.setThemeAsPerColorScheme(event.matches);
        }
    }
    showColorSchemeToast(setThemeCallback) {
        const [toastId, intervalId] = (0, toast_1.addTimedToast)({
            type: toast_1.ToastType.Regular,
            message: (timeRemaining) => `Applying system color scheme in ${timeRemaining}s...`,
            actions: [
                {
                    label: 'Keep current theme',
                    handler: () => {
                        (0, toast_1.dismissToast)(toastId);
                        clearInterval(intervalId);
                    },
                },
                {
                    label: 'Apply now',
                    handler: () => {
                        (0, toast_1.dismissToast)(toastId);
                        clearInterval(intervalId);
                        setThemeCallback();
                    },
                },
            ],
        }, setThemeCallback, TimeBeforeApplyingColorScheme);
    }
    setThemeAsPerColorScheme(prefersDarkColorScheme) {
        const preference = prefersDarkColorScheme ? models_1.PrefKey.AutoDarkThemeIdentifier : models_1.PrefKey.AutoLightThemeIdentifier;
        const preferenceDefault = preference === models_1.PrefKey.AutoDarkThemeIdentifier ? features_1.NativeFeatureIdentifier.TYPES.DarkTheme : DefaultThemeIdentifier;
        const usecase = new GetAllThemesUseCase_1.GetAllThemesUseCase(this.application.items);
        const { thirdParty, native } = usecase.execute({ excludeLayerable: false });
        const themes = [...thirdParty, ...native];
        const activeTheme = themes.find((theme) => this.components.isThemeActive(theme) && !theme.layerable);
        const activeThemeIdentifier = activeTheme ? activeTheme.featureIdentifier : DefaultThemeIdentifier;
        const themeIdentifier = this.preferences.getValue(preference, preferenceDefault);
        const toggleActiveTheme = () => {
            if (activeTheme) {
                void this.components.toggleTheme(activeTheme);
            }
        };
        const setTheme = () => {
            if (themeIdentifier === DefaultThemeIdentifier) {
                toggleActiveTheme();
            }
            else {
                const theme = themes.find((theme) => theme.featureIdentifier === themeIdentifier);
                if (theme && !this.components.isThemeActive(theme)) {
                    this.components.toggleTheme(theme).catch(console.error);
                }
            }
        };
        const isPreferredThemeNotActive = activeThemeIdentifier !== themeIdentifier;
        if (isPreferredThemeNotActive) {
            this.showColorSchemeToast(setTheme);
        }
    }
    async activateCachedThemes() {
        const cachedThemes = this.getCachedThemes();
        for (const theme of cachedThemes) {
            this.activateTheme(theme, true);
        }
    }
    deactivateAllThemes() {
        const activeThemes = this.themesActiveInTheUI.getList();
        for (const uuid of activeThemes) {
            this.deactivateThemeInTheUI(uuid);
        }
    }
    activateTheme(theme, skipEntitlementCheck = false) {
        if (this.themesActiveInTheUI.has(theme.uniqueIdentifier)) {
            return;
        }
        if (!skipEntitlementCheck &&
            this.application.features.getFeatureStatus(theme.uniqueIdentifier) !== services_1.FeatureStatus.Entitled) {
            return;
        }
        const url = this.application.componentManager.urlForFeature(theme);
        if (!url) {
            return;
        }
        this.themesActiveInTheUI.add(theme.uniqueIdentifier);
        const link = document.createElement('link');
        link.href = url;
        link.type = 'text/css';
        link.rel = 'stylesheet';
        link.media = 'screen,print';
        link.id = theme.uniqueIdentifier.value;
        link.onload = () => {
            this.syncThemeColorMetadata();
            if (this.application.isNativeMobileWeb()) {
                setTimeout(() => {
                    const backgroundColorString = this.getBackgroundColor();
                    const backgroundColor = new Color_1.Color(backgroundColorString);
                    this.application.mobileDevice.handleThemeSchemeChange(backgroundColor.isDark(), backgroundColorString);
                });
            }
            this.toggleTranslucentUIColors();
        };
        document.getElementsByTagName('head')[0].appendChild(link);
    }
    deactivateThemeInTheUI(id) {
        var _a;
        if (!this.themesActiveInTheUI.has(id)) {
            return;
        }
        const element = document.getElementById(id.value);
        if (element) {
            element.disabled = true;
            (_a = element.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(element);
        }
        this.themesActiveInTheUI.remove(id);
        if (this.themesActiveInTheUI.isEmpty()) {
            if (this.application.isNativeMobileWeb()) {
                this.application.mobileDevice.handleThemeSchemeChange(false, '#ffffff');
            }
            this.toggleTranslucentUIColors();
        }
    }
    getBackgroundColor() {
        const bgColor = getComputedStyle(document.documentElement).getPropertyValue('--sn-stylekit-background-color').trim();
        return bgColor.length ? bgColor : '#ffffff';
    }
    shouldUseTranslucentUI() {
        return this.application.getPreference(models_1.PrefKey.UseTranslucentUI, models_1.PrefDefaults[models_1.PrefKey.UseTranslucentUI]);
    }
    toggleTranslucentUIColors() {
        if (!this.shouldUseTranslucentUI()) {
            document.documentElement.style.removeProperty('--popover-background-color');
            document.documentElement.style.removeProperty('--popover-backdrop-filter');
            document.body.classList.remove('translucent-ui');
            return;
        }
        try {
            const backgroundColor = new Color_1.Color(this.getBackgroundColor());
            const backdropFilter = backgroundColor.isDark()
                ? 'blur(12px) saturate(190%) contrast(70%) brightness(80%)'
                : 'blur(12px) saturate(190%) contrast(50%) brightness(130%)';
            const translucentBackgroundColor = backgroundColor.setAlpha(0.65).toString();
            document.documentElement.style.setProperty('--popover-background-color', translucentBackgroundColor);
            document.documentElement.style.setProperty('--popover-backdrop-filter', backdropFilter);
            document.body.classList.add('translucent-ui');
        }
        catch (error) {
            console.error(error);
        }
    }
    /**
     * Syncs the active theme's background color to the 'theme-color' meta tag
     * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/meta/name/theme-color
     */
    syncThemeColorMetadata() {
        const themeColorMetaElement = document.querySelector('meta[name="theme-color"]');
        if (!themeColorMetaElement) {
            return;
        }
        themeColorMetaElement.setAttribute('content', this.getBackgroundColor());
    }
    async cacheThemeState() {
        const themes = this.themesActiveInTheUI.asThemes();
        const mapped = themes.map((theme) => {
            if (theme.isComponent) {
                const payload = theme.asComponent.payloadRepresentation();
                return (0, models_1.CreateDecryptedLocalStorageContextPayload)(payload);
            }
            else {
                const payload = theme.asFeatureDescription;
                return payload;
            }
        });
        return this.application.setValue(CachedThemesKey, mapped, services_1.StorageValueModes.Nonwrapped);
    }
    getCachedThemes() {
        const cachedThemes = this.application.getValue(CachedThemesKey, services_1.StorageValueModes.Nonwrapped);
        if (!cachedThemes) {
            return [];
        }
        const features = [];
        for (const cachedTheme of cachedThemes) {
            if ('uuid' in cachedTheme) {
                const payload = this.application.items.createPayloadFromObject(cachedTheme);
                const theme = this.application.items.createItemFromPayload(payload);
                features.push(new models_1.UIFeature(theme));
            }
            else if ('identifier' in cachedTheme) {
                const feature = (0, features_1.FindNativeTheme)(cachedTheme.identifier);
                if (feature) {
                    features.push(new models_1.UIFeature(feature));
                }
            }
        }
        return features;
    }
}
exports.ThemeManager = ThemeManager;
