"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StringToAuthenticatedDataUseCase = void 0;
const utils_1 = require("@standardnotes/utils");
class StringToAuthenticatedDataUseCase {
    constructor(crypto) {
        this.crypto = crypto;
    }
    execute(rawAuthenticatedData, override) {
        const base = JSON.parse(this.crypto.base64Decode(rawAuthenticatedData));
        return (0, utils_1.sortedCopy)({
            ...base,
            ...override,
        });
    }
    executeRaw(rawAuthenticatedData) {
        const base = JSON.parse(this.crypto.base64Decode(rawAuthenticatedData));
        return base;
    }
}
exports.StringToAuthenticatedDataUseCase = StringToAuthenticatedDataUseCase;
