"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebSocketsServiceEvent = void 0;
var WebSocketsServiceEvent;
(function (WebSocketsServiceEvent) {
    WebSocketsServiceEvent["UserRoleMessageReceived"] = "WebSocketMessageReceived";
    WebSocketsServiceEvent["NotificationAddedForUser"] = "NotificationAddedForUser";
    WebSocketsServiceEvent["MessageSentToUser"] = "MessageSentToUser";
    WebSocketsServiceEvent["UserInvitedToSharedVault"] = "UserInvitedToSharedVault";
})(WebSocketsServiceEvent || (exports.WebSocketsServiceEvent = WebSocketsServiceEvent = {}));
