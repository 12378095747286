"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DesignateSurvivor = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
const responses_1 = require("@standardnotes/responses");
class DesignateSurvivor {
    constructor(sharedVaultUserServer) {
        this.sharedVaultUserServer = sharedVaultUserServer;
    }
    async execute(dto) {
        const sharedVaultUuidOrError = domain_core_1.Uuid.create(dto.sharedVaultUuid);
        if (sharedVaultUuidOrError.isFailed()) {
            return domain_core_1.Result.fail(sharedVaultUuidOrError.getError());
        }
        const sharedVaultUuid = sharedVaultUuidOrError.getValue();
        const sharedVaultMemberUuidOrError = domain_core_1.Uuid.create(dto.sharedVaultMemberUuid);
        if (sharedVaultMemberUuidOrError.isFailed()) {
            return domain_core_1.Result.fail(sharedVaultMemberUuidOrError.getError());
        }
        const sharedVaultMemberUuid = sharedVaultMemberUuidOrError.getValue();
        const response = await this.sharedVaultUserServer.designateSurvivor({
            sharedVaultUuid,
            sharedVaultMemberUuid,
        });
        if (response.status !== responses_1.HttpStatusCode.Success) {
            return domain_core_1.Result.fail('Failed to mark designated survivor on the server');
        }
        return domain_core_1.Result.ok();
    }
}
exports.DesignateSurvivor = DesignateSurvivor;
