"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetQuoteBlockOption = exports.QuoteBlock = void 0;
const selection_1 = require("@lexical/selection");
const lexical_1 = require("lexical");
const rich_text_1 = require("@lexical/rich-text");
const BlockPickerOption_1 = require("../BlockPickerPlugin/BlockPickerOption");
exports.QuoteBlock = {
    name: 'Quote',
    iconName: 'quote',
    keywords: ['block quote'],
    onSelect: (editor) => editor.update(() => {
        const selection = (0, lexical_1.$getSelection)();
        if ((0, lexical_1.$isRangeSelection)(selection)) {
            (0, selection_1.$setBlocksType)(selection, () => (0, rich_text_1.$createQuoteNode)());
        }
    }),
};
function GetQuoteBlockOption(editor) {
    return new BlockPickerOption_1.BlockPickerOption(exports.QuoteBlock.name, {
        iconName: exports.QuoteBlock.iconName,
        keywords: exports.QuoteBlock.keywords,
        onSelect: () => exports.QuoteBlock.onSelect(editor),
    });
}
exports.GetQuoteBlockOption = GetQuoteBlockOption;
