"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const snjs_1 = require("@standardnotes/snjs");
const CountBubble = ({ count, position, className }) => {
    if (!count) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)("div", { className: (0, snjs_1.classNames)('flex aspect-square h-5 w-5 items-center justify-center rounded-full border border-info-contrast bg-info text-[0.75rem] font-bold text-info-contrast md:text-[0.65rem]', 'absolute bottom-full translate-y-3 md:translate-y-2', position === 'left' ? 'right-full md:translate-x-2' : 'left-full -translate-x-3 md:-translate-x-2.5', className), children: count }));
};
exports.default = CountBubble;
