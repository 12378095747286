"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetDynamicPasswordBlocks = exports.GetPasswordBlockOption = exports.PasswordBlock = void 0;
const BlockPickerOption_1 = require("../BlockPickerPlugin/BlockPickerOption");
const Commands_1 = require("../Commands");
const MIN_PASSWORD_LENGTH = 8;
const DEFAULT_PASSWORD_LENGTH = 16;
exports.PasswordBlock = {
    name: 'Generate cryptographically secure password',
    iconName: 'password',
    keywords: ['password', 'secure'],
    onSelect: (editor) => editor.dispatchCommand(Commands_1.INSERT_PASSWORD_COMMAND, String(DEFAULT_PASSWORD_LENGTH)),
};
function GetPasswordBlockOption(editor) {
    return new BlockPickerOption_1.BlockPickerOption(exports.PasswordBlock.name, {
        iconName: exports.PasswordBlock.iconName,
        keywords: exports.PasswordBlock.keywords,
        onSelect: () => exports.PasswordBlock.onSelect(editor),
    });
}
exports.GetPasswordBlockOption = GetPasswordBlockOption;
function GetDynamicPasswordBlocks(editor, queryString) {
    if (queryString == null) {
        return [];
    }
    const lengthRegex = /^\d+$/;
    const match = lengthRegex.exec(queryString);
    if (!match) {
        return [];
    }
    const length = parseInt(match[0], 10);
    if (length < MIN_PASSWORD_LENGTH) {
        return [];
    }
    return [
        new BlockPickerOption_1.BlockPickerOption(`Generate ${length}-character cryptographically secure password`, {
            iconName: 'password',
            keywords: ['password', 'secure'],
            onSelect: () => editor.dispatchCommand(Commands_1.INSERT_PASSWORD_COMMAND, length.toString()),
        }),
    ];
}
exports.GetDynamicPasswordBlocks = GetDynamicPasswordBlocks;
