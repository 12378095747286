"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@ariakit/react");
const utils_1 = require("@standardnotes/utils");
function RadioButtonGroup({ value, items, onChange, className }) {
    const radio = (0, react_1.useRadioStore)({
        value,
        orientation: 'horizontal',
        setValue(value) {
            onChange(value);
        },
    });
    return ((0, jsx_runtime_1.jsx)(react_1.RadioGroup, { store: radio, className: `flex divide-x divide-border rounded border border-border md:translucent-ui:border-[--popover-border-color] ${className !== null && className !== void 0 ? className : ''}`, children: items.map(({ label, value: itemValue }) => ((0, jsx_runtime_1.jsxs)("label", { className: (0, utils_1.classNames)('flex-grow select-none px-3.5 py-1.5 text-center', 'first:rounded-bl first:rounded-tl last:rounded-br last:rounded-tr', itemValue === value &&
                'bg-info-backdrop font-medium text-info ring-1 ring-inset ring-info focus-within:ring-2'), children: [(0, jsx_runtime_1.jsx)(react_1.VisuallyHidden, { children: (0, jsx_runtime_1.jsx)(react_1.Radio, { value: itemValue }) }), label] }, itemValue))) }));
}
exports.default = RadioButtonGroup;
