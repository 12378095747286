"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetEmbedsBlockOptions = exports.GetEmbedsBlocks = void 0;
const LexicalAutoEmbedPlugin_1 = require("@lexical/react/LexicalAutoEmbedPlugin");
const AutoEmbedPlugin_1 = require("../AutoEmbedPlugin");
const BlockPickerOption_1 = require("../BlockPickerPlugin/BlockPickerOption");
function GetEmbedsBlocks(editor) {
    return AutoEmbedPlugin_1.EmbedConfigs.map((embedConfig) => ({
        name: `Embed ${embedConfig.contentName}`,
        iconName: embedConfig.iconName,
        keywords: [...embedConfig.keywords, 'embed'],
        onSelect: () => editor.dispatchCommand(LexicalAutoEmbedPlugin_1.INSERT_EMBED_COMMAND, embedConfig.type),
    }));
}
exports.GetEmbedsBlocks = GetEmbedsBlocks;
function GetEmbedsBlockOptions(editor) {
    return GetEmbedsBlocks(editor).map((block) => new BlockPickerOption_1.BlockPickerOption(block.name, {
        iconName: block.iconName,
        keywords: block.keywords,
        onSelect: block.onSelect,
    }));
}
exports.GetEmbedsBlockOptions = GetEmbedsBlockOptions;
