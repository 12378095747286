"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MarkdownTransformers = exports.TABLE = void 0;
const markdown_1 = require("@lexical/markdown");
const table_1 = require("@lexical/table");
const LexicalHorizontalRuleNode_1 = require("@lexical/react/LexicalHorizontalRuleNode");
const lexical_1 = require("lexical");
const RemoteImageNode_1 = require("./Plugins/RemoteImagePlugin/RemoteImageNode");
const HorizontalRule = {
    dependencies: [LexicalHorizontalRuleNode_1.HorizontalRuleNode],
    export: (node) => {
        return (0, LexicalHorizontalRuleNode_1.$isHorizontalRuleNode)(node) ? '***' : null;
    },
    regExp: /^(---|\*\*\*|___)\s?$/,
    replace: (parentNode, _1, _2, isImport) => {
        const line = (0, LexicalHorizontalRuleNode_1.$createHorizontalRuleNode)();
        if (isImport || parentNode.getNextSibling() != null) {
            parentNode.replace(line);
        }
        else {
            parentNode.insertBefore(line);
        }
        line.selectNext();
    },
    type: 'element',
};
const IMAGE = {
    dependencies: [RemoteImageNode_1.RemoteImageNode],
    export: (node) => {
        if (!(0, RemoteImageNode_1.$isRemoteImageNode)(node)) {
            return null;
        }
        return `![${node.__alt ? node.__alt : 'image'}](${node.__src})`;
    },
    importRegExp: /!(?:\[([^[]*)\])(?:\(([^(]+)\))/,
    regExp: /!(?:\[([^[]*)\])(?:\(([^(]+)\))$/,
    replace: (textNode, match) => {
        const [, alt, src] = match;
        const imageNode = (0, RemoteImageNode_1.$createRemoteImageNode)(src, alt);
        textNode.replace(imageNode);
    },
    trigger: ')',
    type: 'text-match',
};
// Table transformer, taken from Lexical Playground
const TABLE_ROW_REG_EXP = /^(?:\|)(.+)(?:\|)\s?$/;
const TABLE_ROW_DIVIDER_REG_EXP = /^(\| ?:?-*:? ?)+\|\s?$/;
function getTableColumnsSize(table) {
    const row = table.getFirstChild();
    return (0, table_1.$isTableRowNode)(row) ? row.getChildrenSize() : 0;
}
function createTableCell(textContent) {
    textContent = textContent.replace(/\\n/g, '\n');
    const cell = (0, table_1.$createTableCellNode)(table_1.TableCellHeaderStates.NO_STATUS);
    (0, markdown_1.$convertFromMarkdownString)(textContent, exports.MarkdownTransformers, cell);
    return cell;
}
function mapToTableCells(textContent) {
    const match = textContent.match(TABLE_ROW_REG_EXP);
    if (!match || !match[1]) {
        return null;
    }
    return match[1].split('|').map((text) => createTableCell(text));
}
exports.TABLE = {
    dependencies: [table_1.TableNode, table_1.TableRowNode, table_1.TableCellNode],
    export: (node) => {
        if (!(0, table_1.$isTableNode)(node)) {
            return null;
        }
        const output = [];
        for (const row of node.getChildren()) {
            const rowOutput = [];
            if (!(0, table_1.$isTableRowNode)(row)) {
                continue;
            }
            let isHeaderRow = false;
            for (const cell of row.getChildren()) {
                // It's TableCellNode so it's just to make flow happy
                if ((0, table_1.$isTableCellNode)(cell)) {
                    rowOutput.push((0, markdown_1.$convertToMarkdownString)(exports.MarkdownTransformers, cell).replace(/\n/g, '\\n'));
                    if (cell.__headerState === table_1.TableCellHeaderStates.ROW) {
                        isHeaderRow = true;
                    }
                }
            }
            output.push(`| ${rowOutput.join(' | ')} |`);
            if (isHeaderRow) {
                output.push(`| ${rowOutput.map((_) => '---').join(' | ')} |`);
            }
        }
        return output.join('\n');
    },
    regExp: TABLE_ROW_REG_EXP,
    replace: (parentNode, _1, match) => {
        // Header row
        if (TABLE_ROW_DIVIDER_REG_EXP.test(match[0])) {
            const table = parentNode.getPreviousSibling();
            if (!table || !(0, table_1.$isTableNode)(table)) {
                return;
            }
            const rows = table.getChildren();
            const lastRow = rows[rows.length - 1];
            if (!lastRow || !(0, table_1.$isTableRowNode)(lastRow)) {
                return;
            }
            // Add header state to row cells
            lastRow.getChildren().forEach((cell) => {
                if (!(0, table_1.$isTableCellNode)(cell)) {
                    return;
                }
                cell.toggleHeaderStyle(table_1.TableCellHeaderStates.ROW);
            });
            // Remove line
            parentNode.remove();
            return;
        }
        const matchCells = mapToTableCells(match[0]);
        if (matchCells == null) {
            return;
        }
        const rows = [matchCells];
        let sibling = parentNode.getPreviousSibling();
        let maxCells = matchCells.length;
        while (sibling) {
            if (!(0, lexical_1.$isParagraphNode)(sibling)) {
                break;
            }
            if (sibling.getChildrenSize() !== 1) {
                break;
            }
            const firstChild = sibling.getFirstChild();
            if (!(0, lexical_1.$isTextNode)(firstChild)) {
                break;
            }
            const cells = mapToTableCells(firstChild.getTextContent());
            if (cells == null) {
                break;
            }
            maxCells = Math.max(maxCells, cells.length);
            rows.unshift(cells);
            const previousSibling = sibling.getPreviousSibling();
            sibling.remove();
            sibling = previousSibling;
        }
        const table = (0, table_1.$createTableNode)();
        for (const cells of rows) {
            const tableRow = (0, table_1.$createTableRowNode)();
            table.append(tableRow);
            for (let i = 0; i < maxCells; i++) {
                tableRow.append(i < cells.length ? cells[i] : createTableCell(''));
            }
        }
        const previousSibling = parentNode.getPreviousSibling();
        if ((0, table_1.$isTableNode)(previousSibling) && getTableColumnsSize(previousSibling) === maxCells) {
            previousSibling.append(...table.getChildren());
            parentNode.remove();
        }
        else {
            parentNode.replace(table);
        }
        table.selectEnd();
    },
    type: 'element',
};
exports.MarkdownTransformers = [
    exports.TABLE,
    markdown_1.CHECK_LIST,
    IMAGE,
    ...markdown_1.ELEMENT_TRANSFORMERS,
    ...markdown_1.TEXT_FORMAT_TRANSFORMERS,
    ...markdown_1.TEXT_MATCH_TRANSFORMERS,
    HorizontalRule,
];
