"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AcceptVaultInvite = void 0;
const responses_1 = require("@standardnotes/responses");
const domain_core_1 = require("@standardnotes/domain-core");
class AcceptVaultInvite {
    constructor(inviteServer, processInvite) {
        this.inviteServer = inviteServer;
        this.processInvite = processInvite;
    }
    async execute(dto) {
        const acceptResponse = await this.inviteServer.acceptInvite({
            sharedVaultUuid: dto.invite.shared_vault_uuid,
            inviteUuid: dto.invite.uuid,
        });
        if ((0, responses_1.isErrorResponse)(acceptResponse)) {
            return domain_core_1.Result.fail(`Could not accept vault invitation: ${(0, responses_1.getErrorFromErrorResponse)(acceptResponse).message}`);
        }
        await this.processInvite.execute(dto.message, dto.invite.shared_vault_uuid, dto.invite.sender_uuid);
        return domain_core_1.Result.ok();
    }
}
exports.AcceptVaultInvite = AcceptVaultInvite;
