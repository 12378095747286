"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconNameToSvgMapping = void 0;
const icons = __importStar(require("@standardnotes/icons"));
exports.IconNameToSvgMapping = {
    'account-circle': icons.AccountCircleIcon,
    'align-center': icons.FormatAlignCenterIcon,
    'align-justify': icons.FormatAlignJustifyIcon,
    'align-left': icons.FormatAlignLeftIcon,
    'align-right': icons.FormatAlignRightIcon,
    'arrow-down': icons.ArrowDownIcon,
    'arrow-left': icons.ArrowLeftIcon,
    'arrow-right': icons.ArrowRightIcon,
    'arrow-up': icons.ArrowUpIcon,
    'arrows-sort-down': icons.ArrowsSortDownIcon,
    'arrows-sort-up': icons.ArrowsSortUpIcon,
    'arrows-vertical': icons.ArrowsVerticalIcon,
    'attachment-file': icons.AttachmentFileIcon,
    'caret-right': icons.CaretRightIcon,
    'check-bold': icons.CheckBoldIcon,
    'check-circle-filled': icons.CheckCircleFilledIcon,
    'check-circle': icons.CheckCircleIcon,
    'chevron-down': icons.ChevronDownIcon,
    'chevron-left': icons.ChevronLeftIcon,
    'chevron-right': icons.ChevronRightIcon,
    'chevron-up': icons.ChevronUpIcon,
    'clear-circle-filled': icons.ClearCircleFilledIcon,
    'cloud-off': icons.CloudOffIcon,
    'code-tags': icons.CodeTagsIcon,
    'details-block': icons.DetailsBlockIcon,
    'diamond-filled': icons.DiamondFilledIcon,
    'email-filled': icons.EmailFilledIcon,
    'eye-off': icons.EyeOffIcon,
    'file-doc': icons.FileDocIcon,
    'file-image': icons.FileImageIcon,
    'file-mov': icons.FileMovIcon,
    'file-music': icons.FileMusicIcon,
    'file-other': icons.FileOtherIcon,
    'file-pdf': icons.FilePdfIcon,
    'file-ppt': icons.FilePptIcon,
    'file-xls': icons.FileXlsIcon,
    'file-zip': icons.FileZipIcon,
    'format-align-center': icons.FormatAlignCenterIcon,
    'format-align-justify': icons.FormatAlignJustifyIcon,
    'format-align-left': icons.FormatAlignLeftIcon,
    'format-align-right': icons.FormatAlignRightIcon,
    'fullscreen-exit': icons.FullscreenExitIcon,
    'hashtag-off': icons.HashtagOffIcon,
    'keyboard-close': icons.KeyboardCloseIcon,
    'line-width': icons.LineWidthIcon,
    'link-off': icons.LinkOffIcon,
    'list-bulleted': icons.ListBulleted,
    'list-numbered': icons.ListNumbered,
    'lock-filled': icons.LockFilledIcon,
    'menu-arrow-down-alt': icons.MenuArrowDownAlt,
    'menu-arrow-down': icons.MenuArrowDownIcon,
    'menu-arrow-right': icons.MenuArrowRightIcon,
    'menu-close': icons.MenuCloseIcon,
    'menu-variant': icons.MenuVariantIcon,
    'notes-filled': icons.NotesFilledIcon,
    'open-in': icons.OpenInIcon,
    'pencil-filled': icons.PencilFilledIcon,
    'pencil-off': icons.PencilOffIcon,
    'pin-filled': icons.PinFilledIcon,
    'plain-text': icons.PlainTextIcon,
    'premium-feature': icons.PremiumFeatureIcon,
    'rich-text': icons.RichTextIcon,
    'safe-square': icons.SafeSquareIcon,
    'sort-descending': icons.SortDescendingIcon,
    'star-circle-filled': icons.StarCircleFilled,
    'star-filled': icons.StarFilledIcon,
    'star-variant-filled': icons.StarVariantFilledIcon,
    'trash-filled': icons.TrashFilledIcon,
    'trash-sweep': icons.TrashSweepIcon,
    'user-add': icons.UserAddIcon,
    'user-switch': icons.UserSwitch,
    accessibility: icons.AccessibilityIcon,
    add: icons.AddIcon,
    aegis: icons.AegisIcon,
    archive: icons.ArchiveIcon,
    asterisk: icons.AsteriskIcon,
    authenticator: icons.AuthenticatorIcon,
    backspace: icons.BackspaceIcon,
    bold: icons.BoldIcon,
    camera: icons.CameraIcon,
    check: icons.CheckIcon,
    close: icons.CloseIcon,
    code: icons.CodeIcon,
    comment: icons.FeedbackIcon,
    copy: icons.CopyIcon,
    dashboard: icons.DashboardIcon,
    diamond: icons.DiamondIcon,
    download: icons.DownloadIcon,
    drag: icons.DragIcon,
    draw: icons.DrawIcon,
    editor: icons.EditorIcon,
    email: icons.EmailIcon,
    evernote: icons.EvernoteIcon,
    eye: icons.EyeIcon,
    file: icons.FileIcon,
    folder: icons.FolderIcon,
    gkeep: icons.GoogleKeepIcon,
    group: icons.GroupIcon,
    hashtag: icons.HashtagIcon,
    help: icons.HelpIcon,
    history: icons.HistoryIcon,
    image: icons.ImageIcon,
    indent: icons.IndentIcon,
    info: icons.InfoIcon,
    italic: icons.ItalicIcon,
    keyboard: icons.KeyboardIcon,
    link: icons.LinkIcon,
    listed: icons.ListedIcon,
    lock: icons.LockIcon,
    markdown: icons.MarkdownIcon,
    merge: icons.MergeIcon,
    more: icons.MoreIcon,
    notes: icons.NotesIcon,
    outdent: icons.OutdentIcon,
    paragraph: icons.TextParagraphLongIcon,
    password: icons.PasswordIcon,
    pencil: icons.PencilIcon,
    pin: icons.PinIcon,
    redo: icons.RedoIcon,
    restore: icons.RestoreIcon,
    save: icons.SaveIcon,
    search: icons.SearchIcon,
    security: icons.SecurityIcon,
    server: icons.ServerIcon,
    settings: icons.SettingsIcon,
    share: icons.ShareIcon,
    signIn: icons.SignInIcon,
    signOut: icons.SignOutIcon,
    simplenote: icons.SimplenoteIcon,
    spreadsheets: icons.SpreadsheetsIcon,
    star: icons.StarIcon,
    strikethrough: icons.StrikethroughIcon,
    subscript: icons.SubscriptIcon,
    subtract: icons.SubtractIcon,
    superscript: icons.SuperscriptIcon,
    sync: icons.SyncIcon,
    tasks: icons.TasksIcon,
    text: icons.TextIcon,
    themes: icons.ThemesIcon,
    trash: icons.TrashIcon,
    tune: icons.TuneIcon,
    toc: icons.TableOfContentsIcon,
    unarchive: icons.UnarchiveIcon,
    underline: icons.UnderlineIcon,
    undo: icons.UndoIcon,
    unpin: icons.UnpinIcon,
    upload: icons.UploadIcon,
    user: icons.UserIcon,
    view: icons.ViewIcon,
    warning: icons.WarningIcon,
    window: icons.WindowIcon,
};
