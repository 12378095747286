"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useContextMenuEvent = void 0;
const react_1 = require("react");
const useLongPress_1 = require("./useLongPress");
const ui_services_1 = require("@standardnotes/ui-services");
const useContextMenuEvent = (elementRef, listener) => {
    const { attachEvents, cleanupEvents } = (0, useLongPress_1.useLongPressEvent)(elementRef, listener, true);
    const handleContextMenuEvent = (0, react_1.useCallback)((event) => {
        event.preventDefault();
        listener(event.clientX, event.clientY);
    }, [listener]);
    (0, react_1.useEffect)(() => {
        const element = elementRef.current;
        if (!element) {
            return;
        }
        const shouldUseLongPress = (0, ui_services_1.isIOS)();
        element.addEventListener('contextmenu', handleContextMenuEvent);
        if (shouldUseLongPress) {
            attachEvents();
        }
        return () => {
            element.removeEventListener('contextmenu', handleContextMenuEvent);
            if (shouldUseLongPress) {
                cleanupEvents();
            }
        };
    }, [attachEvents, cleanupEvents, elementRef, handleContextMenuEvent, listener]);
};
exports.useContextMenuEvent = useContextMenuEvent;
