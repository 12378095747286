"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetCodeBlockOption = exports.CodeBlock = void 0;
const selection_1 = require("@lexical/selection");
const lexical_1 = require("lexical");
const code_1 = require("@lexical/code");
const BlockPickerOption_1 = require("../BlockPickerPlugin/BlockPickerOption");
exports.CodeBlock = {
    name: 'Code Block',
    iconName: 'code',
    keywords: ['javascript', 'python', 'js', 'codeblock'],
    onSelect: (editor) => editor.update(() => {
        const selection = (0, lexical_1.$getSelection)();
        if ((0, lexical_1.$isRangeSelection)(selection)) {
            if (selection.isCollapsed()) {
                (0, selection_1.$setBlocksType)(selection, () => (0, code_1.$createCodeNode)());
            }
            else {
                const textContent = selection.getTextContent();
                const codeNode = (0, code_1.$createCodeNode)();
                selection.insertNodes([codeNode]);
                selection.insertRawText(textContent);
            }
        }
    }),
};
function GetCodeBlockOption(editor) {
    return new BlockPickerOption_1.BlockPickerOption(exports.CodeBlock.name, {
        iconName: exports.CodeBlock.iconName,
        keywords: exports.CodeBlock.keywords,
        onSelect: () => exports.CodeBlock.onSelect(editor),
    });
}
exports.GetCodeBlockOption = GetCodeBlockOption;
