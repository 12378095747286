"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlainEditor = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const usePrevious_1 = require("@/Components/ContentListView/Calendar/usePrevious");
const ElementIDs_1 = require("@/Constants/ElementIDs");
const Logging_1 = require("@/Logging");
const EditorEventSource_1 = require("@/Types/EditorEventSource");
const utils_1 = require("@standardnotes/utils");
const getPlaintextFontSize_1 = require("@/Utils/getPlaintextFontSize");
const snjs_1 = require("@standardnotes/snjs");
const ui_services_1 = require("@standardnotes/ui-services");
const react_1 = require("react");
exports.PlainEditor = (0, react_1.forwardRef)(({ application, spellcheck, controller, locked, onFocus, onBlur }, ref) => {
    const [editorText, setEditorText] = (0, react_1.useState)();
    const [textareaUnloading, setTextareaUnloading] = (0, react_1.useState)(false);
    const [lineHeight, setLineHeight] = (0, react_1.useState)();
    const [fontSize, setFontSize] = (0, react_1.useState)();
    const responsiveFontSize = (0, getPlaintextFontSize_1.useResponsiveEditorFontSize)(fontSize || snjs_1.EditorFontSize.Normal);
    const previousSpellcheck = (0, usePrevious_1.usePrevious)(spellcheck);
    const lastEditorFocusEventSource = (0, react_1.useRef)();
    const needsAdjustMobileCursor = (0, react_1.useRef)(false);
    const isAdjustingMobileCursor = (0, react_1.useRef)(false);
    const note = (0, react_1.useRef)(controller.item);
    const tabObserverDisposer = (0, react_1.useRef)();
    const mutationObserver = (0, react_1.useRef)(null);
    (0, react_1.useImperativeHandle)(ref, () => ({
        focus() {
            focusEditor();
        },
    }));
    (0, react_1.useEffect)(() => {
        return () => {
            var _a, _b;
            (_a = mutationObserver.current) === null || _a === void 0 ? void 0 : _a.disconnect();
            (_b = tabObserverDisposer.current) === null || _b === void 0 ? void 0 : _b.call(tabObserverDisposer);
            tabObserverDisposer.current = undefined;
            mutationObserver.current = null;
        };
    }, []);
    (0, react_1.useEffect)(() => {
        const disposer = controller.addNoteInnerValueChangeObserver((updatedNote, source) => {
            if (updatedNote.uuid !== note.current.uuid) {
                throw Error('Editor received changes for non-current note');
            }
            if ((0, snjs_1.isPayloadSourceRetrieved)(source) ||
                editorText == undefined ||
                updatedNote.editorIdentifier !== note.current.editorIdentifier ||
                updatedNote.noteType !== note.current.noteType) {
                setEditorText(updatedNote.text);
            }
            note.current = updatedNote;
        });
        return disposer;
    }, [controller, editorText, controller.item.uuid, controller.item.editorIdentifier, controller.item.noteType]);
    const onTextAreaChange = ({ currentTarget }) => {
        const text = currentTarget.value;
        setEditorText(text);
        void controller.saveAndAwaitLocalPropagation({ text: text, isUserModified: true });
    };
    const onContentFocus = (0, react_1.useCallback)(() => {
        if (!isAdjustingMobileCursor.current) {
            needsAdjustMobileCursor.current = true;
        }
        if (lastEditorFocusEventSource.current) {
            application.notifyWebEvent(snjs_1.WebAppEvent.EditorFocused, { eventSource: lastEditorFocusEventSource });
        }
        lastEditorFocusEventSource.current = undefined;
        onFocus();
    }, [application, isAdjustingMobileCursor, lastEditorFocusEventSource, onFocus]);
    const onContentBlur = (0, react_1.useCallback)(() => {
        if (lastEditorFocusEventSource.current) {
            application.notifyWebEvent(snjs_1.WebAppEvent.EditorFocused, { eventSource: lastEditorFocusEventSource });
        }
        lastEditorFocusEventSource.current = undefined;
        onBlur();
    }, [application, lastEditorFocusEventSource, onBlur]);
    const scrollMobileCursorIntoViewAfterWebviewResize = (0, react_1.useCallback)(() => {
        var _a, _b;
        if (needsAdjustMobileCursor.current) {
            needsAdjustMobileCursor.current = false;
            isAdjustingMobileCursor.current = true;
            (_a = document.getElementById('note-text-editor')) === null || _a === void 0 ? void 0 : _a.blur();
            (_b = document.getElementById('note-text-editor')) === null || _b === void 0 ? void 0 : _b.focus();
            isAdjustingMobileCursor.current = false;
        }
    }, [needsAdjustMobileCursor]);
    (0, react_1.useEffect)(() => {
        const disposer = application.addWebEventObserver((event) => {
            if (event === snjs_1.WebAppEvent.MobileKeyboardWillChangeFrame) {
                scrollMobileCursorIntoViewAfterWebviewResize();
            }
        });
        return disposer;
    }, [application, scrollMobileCursorIntoViewAfterWebviewResize]);
    const focusEditor = (0, react_1.useCallback)(() => {
        const element = document.getElementById(ElementIDs_1.ElementIds.NoteTextEditor);
        if (element) {
            lastEditorFocusEventSource.current = EditorEventSource_1.EditorEventSource.Script;
            element.focus();
        }
    }, []);
    (0, react_1.useEffect)(() => {
        var _a;
        const shouldFocus = controller.isTemplateNote && ((_a = controller.templateNoteOptions) === null || _a === void 0 ? void 0 : _a.autofocusBehavior) === 'editor';
        if (shouldFocus) {
            focusEditor();
        }
    }, [controller, focusEditor]);
    const reloadPreferences = (0, react_1.useCallback)(() => {
        const lineHeight = application.getPreference(snjs_1.PrefKey.EditorLineHeight, snjs_1.PrefDefaults[snjs_1.PrefKey.EditorLineHeight]);
        const fontSize = application.getPreference(snjs_1.PrefKey.EditorFontSize, snjs_1.PrefDefaults[snjs_1.PrefKey.EditorFontSize]);
        setLineHeight(lineHeight);
        setFontSize(fontSize);
    }, [application]);
    (0, react_1.useEffect)(() => {
        reloadPreferences();
        return application.addSingleEventObserver(snjs_1.ApplicationEvent.PreferencesChanged, async () => {
            reloadPreferences();
        });
    }, [reloadPreferences, application]);
    (0, react_1.useEffect)(() => {
        if (previousSpellcheck === undefined) {
            return;
        }
        if (spellcheck !== previousSpellcheck) {
            setTextareaUnloading(true);
            setTimeout(() => {
                setTextareaUnloading(false);
            }, 0);
        }
    }, [spellcheck, previousSpellcheck]);
    const onRef = (ref) => {
        if (tabObserverDisposer.current || !ref) {
            return;
        }
        (0, Logging_1.log)(Logging_1.LoggingDomain.NoteView, 'On system editor ref');
        /**
         * Insert 4 spaces when a tab key is pressed, only used when inside of the text editor.
         * If the shift key is pressed first, this event is not fired.
         */
        const editor = document.getElementById(ElementIDs_1.ElementIds.NoteTextEditor);
        if (!editor) {
            console.error('Editor is not yet mounted; unable to add tab observer.');
            return;
        }
        tabObserverDisposer.current = application.keyboardService.addCommandHandler({
            element: editor,
            command: ui_services_1.TAB_COMMAND,
            onKeyDown: (event) => {
                if (document.hidden || note.current.locked || event.shiftKey) {
                    return;
                }
                event.preventDefault();
                /** Using document.execCommand gives us undo support */
                const insertSuccessful = document.execCommand('insertText', false, '\t');
                if (!insertSuccessful) {
                    /** document.execCommand works great on Chrome/Safari but not Firefox */
                    const start = editor.selectionStart || 0;
                    const end = editor.selectionEnd || 0;
                    const spaces = '    ';
                    /** Insert 4 spaces */
                    editor.value = editor.value.substring(0, start) + spaces + editor.value.substring(end);
                    /** Place cursor 4 spaces away from where the tab key was pressed */
                    editor.selectionStart = editor.selectionEnd = start + 4;
                }
                setEditorText(editor.value);
                void controller.saveAndAwaitLocalPropagation({
                    text: editor.value,
                    bypassDebouncer: true,
                    isUserModified: true,
                });
            },
        });
        const observer = new MutationObserver((records) => {
            for (const record of records) {
                record.removedNodes.forEach((node) => {
                    var _a;
                    if (node.isEqualNode(editor)) {
                        (_a = tabObserverDisposer.current) === null || _a === void 0 ? void 0 : _a.call(tabObserverDisposer);
                        tabObserverDisposer.current = undefined;
                        observer.disconnect();
                    }
                });
            }
        });
        observer.observe(editor.parentElement, { childList: true });
        mutationObserver.current = observer;
    };
    if (textareaUnloading) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)("textarea", { autoComplete: "off", dir: "auto", id: ElementIDs_1.ElementIds.NoteTextEditor, onChange: onTextAreaChange, onFocus: onContentFocus, onBlur: onContentBlur, readOnly: locked, ref: onRef, spellCheck: spellcheck, value: editorText, className: (0, utils_1.classNames)('editable font-editor flex-grow', lineHeight && `leading-${lineHeight.toLowerCase()}`, responsiveFontSize) }));
});
