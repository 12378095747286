"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthorizeVaultDeletion = void 0;
const Challenge_1 = require("../../Challenge");
const Messages_1 = require("../../Strings/Messages");
const domain_core_1 = require("@standardnotes/domain-core");
class AuthorizeVaultDeletion {
    constructor(vaultLocks, protection, challenges, _validateVaultPassword) {
        this.vaultLocks = vaultLocks;
        this.protection = protection;
        this.challenges = challenges;
        this._validateVaultPassword = _validateVaultPassword;
    }
    async execute(vault) {
        if (!this.vaultLocks.isVaultLockable(vault)) {
            const authorized = await this.protection.authorizeAction(Challenge_1.ChallengeReason.Custom, {
                fallBackToAccountPassword: true,
                requireAccountPassword: false,
                forcePrompt: true,
            });
            return domain_core_1.Result.ok(authorized);
        }
        const challenge = new Challenge_1.Challenge([new Challenge_1.ChallengePrompt(Challenge_1.ChallengeValidation.None, undefined, 'Password')], Challenge_1.ChallengeReason.Custom, true, Messages_1.ChallengeStrings.DeleteVault(vault.name), Messages_1.ChallengeStrings.EnterVaultPassword);
        return new Promise((resolve) => {
            this.challenges.addChallengeObserver(challenge, {
                onCancel() {
                    resolve(domain_core_1.Result.ok(false));
                },
                onNonvalidatedSubmit: async (challengeResponse) => {
                    const value = challengeResponse.getDefaultValue();
                    if (!value) {
                        this.challenges.completeChallenge(challenge);
                        resolve(domain_core_1.Result.ok(false));
                        return;
                    }
                    const password = value.value;
                    if (this.vaultLocks.isVaultLocked(vault)) {
                        const unlocked = await this.vaultLocks.unlockNonPersistentVault(vault, password);
                        if (unlocked) {
                            this.challenges.completeChallenge(challenge);
                            resolve(domain_core_1.Result.ok(true));
                            return;
                        }
                    }
                    const validPassword = this._validateVaultPassword.execute(vault, password).getValue();
                    if (!validPassword) {
                        this.challenges.setValidationStatusForChallenge(challenge, value, false);
                        resolve(domain_core_1.Result.ok(false));
                        return;
                    }
                    this.challenges.completeChallenge(challenge);
                    resolve(domain_core_1.Result.ok(true));
                },
            });
            void this.challenges.promptForChallengeResponse(challenge);
        });
    }
}
exports.AuthorizeVaultDeletion = AuthorizeVaultDeletion;
