"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateSharedVault = void 0;
const responses_1 = require("@standardnotes/responses");
class CreateSharedVault {
    constructor(mutator, sharedVaultServer, _createVault, _moveItemsToVault, _getVaultItems) {
        this.mutator = mutator;
        this.sharedVaultServer = sharedVaultServer;
        this._createVault = _createVault;
        this._moveItemsToVault = _moveItemsToVault;
        this._getVaultItems = _getVaultItems;
    }
    async execute(dto) {
        var _a;
        const privateVault = await this._createVault.execute({
            vaultName: dto.vaultName,
            vaultDescription: dto.vaultDescription,
            vaultIcon: dto.vaultIcon,
            userInputtedPassword: dto.userInputtedPassword,
            storagePreference: dto.storagePreference,
        });
        const serverResult = await this.sharedVaultServer.createSharedVault();
        if ((0, responses_1.isErrorResponse)(serverResult)) {
            return responses_1.ClientDisplayableError.FromString(`Failed to create shared vault: ${(_a = serverResult.data.error) === null || _a === void 0 ? void 0 : _a.message}`);
        }
        const serverVaultHash = serverResult.data.sharedVault;
        const sharedVaultListing = await this.mutator.changeItem(privateVault, (mutator) => {
            mutator.sharing = {
                sharedVaultUuid: serverVaultHash.uuid,
                ownerUserUuid: serverVaultHash.user_uuid,
                fileBytesUsed: serverVaultHash.file_upload_bytes_used,
                designatedSurvivor: null,
            };
        });
        const vaultItems = this._getVaultItems.execute(sharedVaultListing).getValue();
        await this._moveItemsToVault.execute({ vault: sharedVaultListing, items: vaultItems });
        return sharedVaultListing;
    }
}
exports.CreateSharedVault = CreateSharedVault;
