"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useResponsiveEditorFontSize = void 0;
const useMediaQuery_1 = require("@/Hooks/useMediaQuery");
const useResponsiveEditorFontSize = (key) => {
    const desktopMapping = {
        ExtraSmall: 'text-xs',
        Small: 'text-sm',
        Normal: 'text-editor',
        Medium: 'text-lg',
        Large: 'text-xl',
    };
    const mobileMapping = {
        ExtraSmall: 'text-sm',
        Small: 'text-editor',
        Normal: 'text-lg',
        Medium: 'text-xl',
        Large: 'text-2xl',
    };
    const tabletMapping = {
        ExtraSmall: 'text-sm',
        Small: 'text-editor',
        Normal: 'text-base',
        Medium: 'text-xl',
        Large: 'text-2xl',
    };
    const isTabletScreen = (0, useMediaQuery_1.useMediaQuery)(useMediaQuery_1.MutuallyExclusiveMediaQueryBreakpoints.md);
    const isMobileScreen = (0, useMediaQuery_1.useMediaQuery)(useMediaQuery_1.MutuallyExclusiveMediaQueryBreakpoints.sm);
    if (isTabletScreen) {
        return tabletMapping[key];
    }
    return isMobileScreen ? mobileMapping[key] : desktopMapping[key];
};
exports.useResponsiveEditorFontSize = useResponsiveEditorFontSize;
