"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GoogleKeepConverter = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
const Utils_1 = require("../Utils");
const features_1 = require("@standardnotes/features");
class GoogleKeepConverter {
    constructor(superConverterService, _generateUuid) {
        this.superConverterService = superConverterService;
        this._generateUuid = _generateUuid;
    }
    async convertGoogleKeepBackupFileToNote(file, isEntitledToSuper) {
        const content = await (0, Utils_1.readFileAsText)(file);
        const possiblePayloadFromJson = this.tryParseAsJson(content, isEntitledToSuper);
        if (possiblePayloadFromJson) {
            return possiblePayloadFromJson;
        }
        const possiblePayloadFromHtml = this.tryParseAsHtml(content, file, isEntitledToSuper);
        if (possiblePayloadFromHtml) {
            return possiblePayloadFromHtml;
        }
        throw new Error('Could not parse Google Keep backup file');
    }
    tryParseAsHtml(data, file, isEntitledToSuper) {
        var _a;
        const rootElement = document.createElement('html');
        rootElement.innerHTML = data;
        const headingElement = rootElement.getElementsByClassName('heading')[0];
        const date = new Date((headingElement === null || headingElement === void 0 ? void 0 : headingElement.textContent) || '');
        headingElement === null || headingElement === void 0 ? void 0 : headingElement.remove();
        const contentElement = rootElement.getElementsByClassName('content')[0];
        if (!contentElement) {
            throw new Error('Could not parse content. Content element not found.');
        }
        let content;
        // Convert lists to readable plaintext format
        // or Super-convertable format
        const lists = contentElement.getElementsByTagName('ul');
        Array.from(lists).forEach((list) => {
            list.setAttribute('__lexicallisttype', 'check');
            const items = list.getElementsByTagName('li');
            Array.from(items).forEach((item) => {
                var _a;
                const bulletSpan = item.getElementsByClassName('bullet')[0];
                bulletSpan === null || bulletSpan === void 0 ? void 0 : bulletSpan.remove();
                const checked = item.classList.contains('checked');
                item.setAttribute('aria-checked', checked ? 'true' : 'false');
                if (!isEntitledToSuper) {
                    item.textContent = `- ${checked ? '[x]' : '[ ]'} ${(_a = item.textContent) === null || _a === void 0 ? void 0 : _a.trim()}\n`;
                }
            });
        });
        if (!isEntitledToSuper) {
            // Replace <br> with \n so line breaks get recognised
            contentElement.innerHTML = contentElement.innerHTML.replace(/<br>/g, '\n');
            content = contentElement.textContent;
        }
        else {
            content = this.superConverterService.convertOtherFormatToSuperString(rootElement.innerHTML, 'html');
        }
        if (!content) {
            throw new Error('Could not parse content');
        }
        const title = ((_a = rootElement.getElementsByClassName('title')[0]) === null || _a === void 0 ? void 0 : _a.textContent) || file.name;
        return {
            created_at: date,
            created_at_timestamp: date.getTime(),
            updated_at: date,
            updated_at_timestamp: date.getTime(),
            uuid: this._generateUuid.execute().getValue(),
            content_type: domain_core_1.ContentType.TYPES.Note,
            content: {
                title: title,
                text: content,
                references: [],
                ...(isEntitledToSuper
                    ? {
                        noteType: features_1.NoteType.Super,
                        editorIdentifier: features_1.NativeFeatureIdentifier.TYPES.SuperEditor,
                    }
                    : {}),
            },
        };
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static isValidGoogleKeepJson(json) {
        if (typeof json.textContent !== 'string') {
            if (typeof json.listContent === 'object' && Array.isArray(json.listContent)) {
                return json.listContent.every(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (item) => typeof item.text === 'string' && typeof item.isChecked === 'boolean');
            }
            return false;
        }
        return (typeof json.title === 'string' &&
            typeof json.userEditedTimestampUsec === 'number' &&
            typeof json.isArchived === 'boolean' &&
            typeof json.isTrashed === 'boolean' &&
            typeof json.isPinned === 'boolean' &&
            typeof json.color === 'string');
    }
    tryParseAsJson(data, isEntitledToSuper) {
        try {
            const parsed = JSON.parse(data);
            if (!GoogleKeepConverter.isValidGoogleKeepJson(parsed)) {
                return null;
            }
            const date = new Date(parsed.userEditedTimestampUsec / 1000);
            let text;
            if ('textContent' in parsed) {
                text = parsed.textContent;
            }
            else {
                text = parsed.listContent
                    .map((item) => {
                    return item.isChecked ? `- [x] ${item.text}` : `- [ ] ${item.text}`;
                })
                    .join('\n');
            }
            if (isEntitledToSuper) {
                text = this.superConverterService.convertOtherFormatToSuperString(text, 'md');
            }
            return {
                created_at: date,
                created_at_timestamp: date.getTime(),
                updated_at: date,
                updated_at_timestamp: date.getTime(),
                uuid: this._generateUuid.execute().getValue(),
                content_type: domain_core_1.ContentType.TYPES.Note,
                content: {
                    title: parsed.title,
                    text,
                    references: [],
                    archived: Boolean(parsed.isArchived),
                    trashed: Boolean(parsed.isTrashed),
                    pinned: Boolean(parsed.isPinned),
                    ...(isEntitledToSuper
                        ? {
                            noteType: features_1.NoteType.Super,
                            editorIdentifier: features_1.NativeFeatureIdentifier.TYPES.SuperEditor,
                        }
                        : {}),
                },
            };
        }
        catch (e) {
            console.error(e);
            return null;
        }
    }
}
exports.GoogleKeepConverter = GoogleKeepConverter;
