"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebSocketsService = void 0;
const responses_1 = require("@standardnotes/responses");
const WebSocketsServiceEvent_1 = require("./WebSocketsServiceEvent");
const AbstractService_1 = require("../Service/AbstractService");
const StorageKeys_1 = require("../Storage/StorageKeys");
class WebSocketsService extends AbstractService_1.AbstractService {
    constructor(storageService, webSocketUrl, webSocketApiService, internalEventBus) {
        super(internalEventBus);
        this.storageService = storageService;
        this.webSocketUrl = webSocketUrl;
        this.webSocketApiService = webSocketApiService;
        this.internalEventBus = internalEventBus;
    }
    setWebSocketUrl(url) {
        this.webSocketUrl = url;
        this.storageService.setValue(StorageKeys_1.StorageKey.WebSocketUrl, url);
    }
    loadWebSocketUrl() {
        const storedValue = this.storageService.getValue(StorageKeys_1.StorageKey.WebSocketUrl);
        this.webSocketUrl =
            storedValue ||
                this.webSocketUrl ||
                window._websocket_url;
    }
    async startWebSocketConnection() {
        if (!this.webSocketUrl) {
            return;
        }
        const webSocketConectionToken = await this.createWebSocketConnectionToken();
        if (webSocketConectionToken === undefined) {
            return;
        }
        try {
            this.webSocket = new WebSocket(`${this.webSocketUrl}?authToken=${webSocketConectionToken}`);
            this.webSocket.onmessage = this.onWebSocketMessage.bind(this);
            this.webSocket.onclose = this.onWebSocketClose.bind(this);
        }
        catch (e) {
            console.error('Error starting WebSocket connection', e);
        }
    }
    closeWebSocketConnection() {
        var _a;
        (_a = this.webSocket) === null || _a === void 0 ? void 0 : _a.close();
    }
    onWebSocketMessage(messageEvent) {
        const eventData = JSON.parse(messageEvent.data);
        switch (eventData.type) {
            case 'USER_ROLES_CHANGED':
                void this.notifyEvent(WebSocketsServiceEvent_1.WebSocketsServiceEvent.UserRoleMessageReceived, eventData);
                break;
            case 'NOTIFICATION_ADDED_FOR_USER':
                void this.notifyEvent(WebSocketsServiceEvent_1.WebSocketsServiceEvent.NotificationAddedForUser, eventData.payload);
                break;
            case 'MESSAGE_SENT_TO_USER':
                void this.notifyEvent(WebSocketsServiceEvent_1.WebSocketsServiceEvent.MessageSentToUser, eventData.payload);
                break;
            case 'USER_INVITED_TO_SHARED_VAULT':
                void this.notifyEvent(WebSocketsServiceEvent_1.WebSocketsServiceEvent.UserInvitedToSharedVault, eventData.payload);
                break;
            default:
                break;
        }
    }
    onWebSocketClose() {
        this.webSocket = undefined;
    }
    async createWebSocketConnectionToken() {
        try {
            const response = await this.webSocketApiService.createConnectionToken();
            if ((0, responses_1.isErrorResponse)(response)) {
                console.error(response.data.error);
                return undefined;
            }
            return response.data.token;
        }
        catch (error) {
            console.error('Caught error:', error.message);
            return undefined;
        }
    }
    deinit() {
        super.deinit();
        this.storageService = undefined;
        this.webSocketApiService = undefined;
        this.closeWebSocketConnection();
    }
}
exports.WebSocketsService = WebSocketsService;
