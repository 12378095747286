"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenerateDecryptedParametersUseCase = void 0;
const V004AlgorithmHelpers_1 = require("../../V004AlgorithmHelpers");
const StringToAuthenticatedData_1 = require("../Utils/StringToAuthenticatedData");
const CreateConsistentBase64JsonPayload_1 = require("../Utils/CreateConsistentBase64JsonPayload");
const GenerateSymmetricPayloadSignatureResult_1 = require("./GenerateSymmetricPayloadSignatureResult");
const DeriveHashingKey_1 = require("../Hash/DeriveHashingKey");
class GenerateDecryptedParametersUseCase {
    constructor(crypto) {
        this.crypto = crypto;
        this.base64DataUsecase = new CreateConsistentBase64JsonPayload_1.CreateConsistentBase64JsonPayloadUseCase(this.crypto);
        this.stringToAuthenticatedDataUseCase = new StringToAuthenticatedData_1.StringToAuthenticatedDataUseCase(this.crypto);
        this.signingVerificationUseCase = new GenerateSymmetricPayloadSignatureResult_1.GenerateSymmetricPayloadSignatureResultUseCase(this.crypto);
        this.deriveHashingKeyUseCase = new DeriveHashingKey_1.DeriveHashingKeyUseCase(this.crypto);
    }
    execute(encrypted, key) {
        const contentKeyResult = this.decryptContentKey(encrypted, key);
        if (!contentKeyResult) {
            console.error('Error decrypting contentKey from parameters', encrypted);
            return {
                uuid: encrypted.uuid,
                errorDecrypting: true,
            };
        }
        const contentResult = this.decryptContent(encrypted, contentKeyResult.decrypted);
        if (!contentResult) {
            return {
                uuid: encrypted.uuid,
                errorDecrypting: true,
            };
        }
        const hashingKey = this.deriveHashingKeyUseCase.execute(key);
        const signatureVerificationResult = this.signingVerificationUseCase.execute(encrypted, hashingKey, {
            additionalData: contentKeyResult.components.additionalData,
            plaintext: contentKeyResult.decrypted,
        }, {
            additionalData: contentResult.components.additionalData,
            plaintext: contentResult.decrypted,
        });
        return {
            uuid: encrypted.uuid,
            content: JSON.parse(contentResult.decrypted),
            signatureData: signatureVerificationResult,
        };
    }
    decryptContent(encrypted, contentKey) {
        const contentComponents = (0, V004AlgorithmHelpers_1.deconstructEncryptedPayloadString)(encrypted.content);
        return this.decrypt(encrypted, contentComponents, contentKey);
    }
    decryptContentKey(encrypted, key) {
        const contentKeyComponents = (0, V004AlgorithmHelpers_1.deconstructEncryptedPayloadString)(encrypted.enc_item_key);
        return this.decrypt(encrypted, contentKeyComponents, key.itemsKey);
    }
    decrypt(encrypted, components, key) {
        const rawAuthenticatedData = this.stringToAuthenticatedDataUseCase.executeRaw(components.authenticatedData);
        const doesRawContainLegacyUppercaseUuid = /[A-Z]/.test(rawAuthenticatedData.u);
        const authenticatedData = this.stringToAuthenticatedDataUseCase.execute(components.authenticatedData, {
            u: doesRawContainLegacyUppercaseUuid ? encrypted.uuid.toUpperCase() : encrypted.uuid,
            v: encrypted.version,
            ksi: encrypted.key_system_identifier,
            svu: encrypted.shared_vault_uuid,
        });
        const authenticatedDataString = this.base64DataUsecase.execute(authenticatedData);
        const decrypted = this.crypto.xchacha20Decrypt(components.ciphertext, components.nonce, key, authenticatedDataString);
        if (!decrypted) {
            return null;
        }
        return {
            decrypted,
            components: components,
            authenticatedDataString,
        };
    }
}
exports.GenerateDecryptedParametersUseCase = GenerateDecryptedParametersUseCase;
