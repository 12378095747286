"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useItemVaultInfo = void 0;
const ApplicationProvider_1 = require("@/Components/ApplicationProvider");
const snjs_1 = require("@standardnotes/snjs");
const react_1 = require("react");
const useStateRef_1 = require("./useStateRef");
const useItemVaultInfo = (item) => {
    const application = (0, ApplicationProvider_1.useApplication)();
    const [vault, setVault] = (0, react_1.useState)();
    const vaultRef = (0, useStateRef_1.useStateRef)(vault);
    const [lastEditedByContact, setLastEditedByContact] = (0, react_1.useState)();
    const [sharedByContact, setSharedByContact] = (0, react_1.useState)();
    const updateInfo = (0, react_1.useCallback)(() => {
        if (!application.featuresController.isVaultsEnabled()) {
            return;
        }
        setVault(application.vaultDisplayService.getItemVault(item));
        setLastEditedByContact((lastEditedBy) => application.sharedVaults.getItemLastEditedBy(item) || lastEditedBy);
        setSharedByContact(application.sharedVaults.getItemSharedBy(item));
    }, [application.featuresController, application.sharedVaults, application.vaultDisplayService, item]);
    (0, react_1.useLayoutEffect)(() => {
        updateInfo();
    }, [updateInfo]);
    (0, react_1.useEffect)(() => {
        return application.items.streamItems(snjs_1.ContentType.TYPES.VaultListing, ({ changed, inserted }) => {
            const matchingItem = changed.concat(inserted).find((vault) => { var _a; return vault.uuid === ((_a = vaultRef.current) === null || _a === void 0 ? void 0 : _a.uuid); });
            if (matchingItem) {
                setVault(matchingItem);
            }
        });
    }, [application.items, vaultRef]);
    (0, react_1.useEffect)(() => {
        return application.items.streamItems(snjs_1.ContentType.TYPES.Note, ({ changed }) => {
            const matchingItem = changed.find((note) => note.uuid === item.uuid);
            if (matchingItem) {
                updateInfo();
            }
        });
    }, [application.items, item.uuid, updateInfo]);
    return {
        vault,
        lastEditedByContact,
        sharedByContact,
    };
};
exports.useItemVaultInfo = useItemVaultInfo;
