"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetDatetimeBlockOptions = exports.GetDatetimeBlocks = void 0;
const BlockPickerOption_1 = require("../BlockPickerPlugin/BlockPickerOption");
const Commands_1 = require("../Commands");
function GetDatetimeBlocks(editor) {
    return [
        {
            name: 'Current date and time',
            iconName: 'authenticator',
            keywords: ['date', 'current'],
            onSelect: () => editor.dispatchCommand(Commands_1.INSERT_DATETIME_COMMAND, 'datetime'),
        },
        {
            name: 'Current time',
            iconName: 'authenticator',
            keywords: ['time', 'current'],
            onSelect: () => editor.dispatchCommand(Commands_1.INSERT_TIME_COMMAND, 'datetime'),
        },
        {
            name: 'Current date',
            iconName: 'authenticator',
            keywords: ['date', 'current'],
            onSelect: () => editor.dispatchCommand(Commands_1.INSERT_DATE_COMMAND, 'datetime'),
        },
    ];
}
exports.GetDatetimeBlocks = GetDatetimeBlocks;
function GetDatetimeBlockOptions(editor) {
    return GetDatetimeBlocks(editor).map((block) => new BlockPickerOption_1.BlockPickerOption(block.name, {
        iconName: block.iconName,
        keywords: block.keywords,
        onSelect: block.onSelect,
    }));
}
exports.GetDatetimeBlockOptions = GetDatetimeBlockOptions;
