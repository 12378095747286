"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetRemoteImageBlockOption = void 0;
const BlockPickerOption_1 = require("../BlockPickerPlugin/BlockPickerOption");
function GetRemoteImageBlockOption(onSelect) {
    return new BlockPickerOption_1.BlockPickerOption('Image from URL', {
        iconName: 'image',
        keywords: ['image', 'url'],
        onSelect: onSelect,
    });
}
exports.GetRemoteImageBlockOption = GetRemoteImageBlockOption;
