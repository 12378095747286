"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.featureTrunkHomeServerEnabled = exports.featureTrunkVaultsEnabled = exports.featureTrunkEnabled = exports.FeatureTrunkName = void 0;
const snjs_1 = require("@standardnotes/snjs");
var FeatureTrunkName;
(function (FeatureTrunkName) {
})(FeatureTrunkName || (exports.FeatureTrunkName = FeatureTrunkName = {}));
const FeatureTrunkStatus = {};
function featureTrunkEnabled(trunk) {
    return FeatureTrunkStatus[trunk];
}
exports.featureTrunkEnabled = featureTrunkEnabled;
function featureTrunkVaultsEnabled() {
    return snjs_1.InternalFeatureService.get().isFeatureEnabled(snjs_1.InternalFeature.Vaults);
}
exports.featureTrunkVaultsEnabled = featureTrunkVaultsEnabled;
function featureTrunkHomeServerEnabled() {
    return snjs_1.InternalFeatureService.get().isFeatureEnabled(snjs_1.InternalFeature.HomeServer);
}
exports.featureTrunkHomeServerEnabled = featureTrunkHomeServerEnabled;
