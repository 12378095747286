"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useVault = void 0;
const ApplicationProvider_1 = require("@/Components/ApplicationProvider");
const snjs_1 = require("@standardnotes/snjs");
const react_1 = require("react");
const useVault = (vault) => {
    const application = (0, ApplicationProvider_1.useApplication)();
    const canShowLockOption = vault.keyPasswordType === snjs_1.KeySystemPasswordType.UserInputted &&
        vault.keyStorageMode === snjs_1.KeySystemRootKeyStorageMode.Ephemeral;
    const [isLocked, setIsLocked] = (0, react_1.useState)(() => application.vaultLocks.isVaultLocked(vault));
    (0, react_1.useEffect)(() => {
        return application.vaultLocks.addEventObserver((event) => {
            if (event === snjs_1.VaultLockServiceEvent.VaultLocked || event === snjs_1.VaultLockServiceEvent.VaultUnlocked) {
                setIsLocked(application.vaultLocks.isVaultLocked(vault));
            }
        });
    }, [application.vaultLocks, vault]);
    const toggleLock = (0, react_1.useCallback)(async () => {
        if (!canShowLockOption) {
            return;
        }
        if (isLocked) {
            application.vaultDisplayService.unlockVault(vault).catch(console.error);
        }
        else {
            application.vaultLocks.lockNonPersistentVault(vault).catch(console.error);
        }
    }, [application.vaultDisplayService, application.vaultLocks, canShowLockOption, isLocked, vault]);
    const isCurrentUserAdmin = !vault.isSharedVaultListing()
        ? true
        : application.vaultUsers.isCurrentUserSharedVaultAdmin(vault);
    const isCurrentUserOwner = !vault.isSharedVaultListing()
        ? true
        : application.vaultUsers.isCurrentUserSharedVaultOwner(vault);
    const ensureVaultIsUnlocked = (0, react_1.useCallback)(async () => {
        if (!application.vaultLocks.isVaultLocked(vault)) {
            return true;
        }
        const unlocked = await application.vaultDisplayService.unlockVault(vault);
        return unlocked;
    }, [application, vault]);
    return {
        canShowLockOption,
        isLocked,
        toggleLock,
        ensureVaultIsUnlocked,
        isCurrentUserAdmin,
        isCurrentUserOwner,
    };
};
exports.useVault = useVault;
