"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const mobx_react_lite_1 = require("mobx-react-lite");
const Content_1 = require("@/Components/Preferences/PreferencesComponents/Content");
const SharingStatusText = ({ subscriptionState }) => {
    const { usedInvitationsCount, allowedInvitationsCount } = subscriptionState;
    return ((0, jsx_runtime_1.jsxs)(Content_1.Text, { className: "mt-1", children: ["You've used ", (0, jsx_runtime_1.jsx)("span", { className: "font-bold", children: usedInvitationsCount }), " out of ", allowedInvitationsCount, ' ', "subscription invites."] }));
};
exports.default = (0, mobx_react_lite_1.observer)(SharingStatusText);
