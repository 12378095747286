"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Importer = void 0;
const filepicker_1 = require("@standardnotes/filepicker");
const services_1 = require("@standardnotes/services");
const features_1 = require("@standardnotes/features");
const AegisToAuthenticatorConverter_1 = require("./AegisConverter/AegisToAuthenticatorConverter");
const EvernoteConverter_1 = require("./EvernoteConverter/EvernoteConverter");
const GoogleKeepConverter_1 = require("./GoogleKeepConverter/GoogleKeepConverter");
const PlaintextConverter_1 = require("./PlaintextConverter/PlaintextConverter");
const SimplenoteConverter_1 = require("./SimplenoteConverter/SimplenoteConverter");
const Utils_1 = require("./Utils");
const HTMLConverter_1 = require("./HTMLConverter/HTMLConverter");
const SuperConverter_1 = require("./SuperConverter/SuperConverter");
class Importer {
    constructor(features, mutator, items, superConverterService, _generateUuid) {
        this.features = features;
        this.mutator = mutator;
        this.items = items;
        this.superConverterService = superConverterService;
        this.detectService = async (file) => {
            const content = await (0, Utils_1.readFileAsText)(file);
            const { ext } = (0, filepicker_1.parseFileName)(file.name);
            if (ext === 'enex') {
                return 'evernote';
            }
            try {
                const json = JSON.parse(content);
                if (AegisToAuthenticatorConverter_1.AegisToAuthenticatorConverter.isValidAegisJson(json)) {
                    return 'aegis';
                }
                if (GoogleKeepConverter_1.GoogleKeepConverter.isValidGoogleKeepJson(json)) {
                    return 'google-keep';
                }
                if (SimplenoteConverter_1.SimplenoteConverter.isValidSimplenoteJson(json)) {
                    return 'simplenote';
                }
            }
            catch {
                /* empty */
            }
            if (file.type === 'application/json' && this.superConverterService.isValidSuperString(content)) {
                return 'super';
            }
            if (PlaintextConverter_1.PlaintextConverter.isValidPlaintextFile(file)) {
                return 'plaintext';
            }
            if (HTMLConverter_1.HTMLConverter.isHTMLFile(file)) {
                return 'html';
            }
            return null;
        };
        this.aegisConverter = new AegisToAuthenticatorConverter_1.AegisToAuthenticatorConverter(_generateUuid);
        this.googleKeepConverter = new GoogleKeepConverter_1.GoogleKeepConverter(this.superConverterService, _generateUuid);
        this.simplenoteConverter = new SimplenoteConverter_1.SimplenoteConverter(_generateUuid);
        this.plaintextConverter = new PlaintextConverter_1.PlaintextConverter(_generateUuid);
        this.evernoteConverter = new EvernoteConverter_1.EvernoteConverter(this.superConverterService, _generateUuid);
        this.htmlConverter = new HTMLConverter_1.HTMLConverter(this.superConverterService, _generateUuid);
        this.superConverter = new SuperConverter_1.SuperConverter(this.superConverterService, _generateUuid);
    }
    async getPayloadsFromFile(file, type) {
        const isEntitledToSuper = this.features.getFeatureStatus(features_1.NativeFeatureIdentifier.create(features_1.NativeFeatureIdentifier.TYPES.SuperEditor).getValue()) === services_1.FeatureStatus.Entitled;
        if (type === 'super') {
            if (!isEntitledToSuper) {
                throw new Error('Importing Super notes requires a subscription.');
            }
            return [await this.superConverter.convertSuperFileToNote(file)];
        }
        else if (type === 'aegis') {
            const isEntitledToAuthenticator = this.features.getFeatureStatus(features_1.NativeFeatureIdentifier.create(features_1.NativeFeatureIdentifier.TYPES.TokenVaultEditor).getValue()) === services_1.FeatureStatus.Entitled;
            return [await this.aegisConverter.convertAegisBackupFileToNote(file, isEntitledToAuthenticator)];
        }
        else if (type === 'google-keep') {
            return [await this.googleKeepConverter.convertGoogleKeepBackupFileToNote(file, isEntitledToSuper)];
        }
        else if (type === 'simplenote') {
            return await this.simplenoteConverter.convertSimplenoteBackupFileToNotes(file);
        }
        else if (type === 'evernote') {
            return await this.evernoteConverter.convertENEXFileToNotesAndTags(file, isEntitledToSuper);
        }
        else if (type === 'plaintext') {
            return [await this.plaintextConverter.convertPlaintextFileToNote(file)];
        }
        else if (type === 'html') {
            return [await this.htmlConverter.convertHTMLFileToNote(file, isEntitledToSuper)];
        }
        return [];
    }
    async importFromTransferPayloads(payloads) {
        const insertedItems = await Promise.all(payloads.map(async (payload) => {
            const content = payload.content;
            const note = this.items.createTemplateItem(payload.content_type, {
                text: content.text,
                title: content.title,
                noteType: content.noteType,
                editorIdentifier: content.editorIdentifier,
                references: content.references,
            }, {
                created_at: payload.created_at,
                updated_at: payload.updated_at,
                uuid: payload.uuid,
            });
            return this.mutator.insertItem(note);
        }));
        return insertedItems;
    }
}
exports.Importer = Importer;
