"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatusService = void 0;
const utils_1 = require("@standardnotes/utils");
const AbstractService_1 = require("../Service/AbstractService");
const StatusServiceInterface_1 = require("./StatusServiceInterface");
/* istanbul ignore file */
class StatusService extends AbstractService_1.AbstractService {
    constructor() {
        super(...arguments);
        this.preferencesBubbleCounts = {
            general: 0,
            account: 0,
            security: 0,
            'home-server': 0,
            vaults: 0,
            appearance: 0,
            backups: 0,
            listed: 0,
            shortcuts: 0,
            accessibility: 0,
            'get-free-month': 0,
            'help-feedback': 0,
            'whats-new': 0,
        };
        this._message = '';
        this.dynamicMessages = [];
    }
    getPreferencesBubbleCount(preferencePaneId) {
        return this.preferencesBubbleCounts[preferencePaneId];
    }
    setPreferencesBubbleCount(preferencePaneId, count) {
        this.preferencesBubbleCounts[preferencePaneId] = count;
        const totalCount = this.totalPreferencesBubbleCount;
        void this.notifyEvent(StatusServiceInterface_1.StatusServiceEvent.PreferencesBubbleCountChanged, totalCount > 0 ? totalCount.toString() : undefined);
    }
    get totalPreferencesBubbleCount() {
        return Object.values(this.preferencesBubbleCounts).reduce((total, count) => total + count, 0);
    }
    get message() {
        return this._message;
    }
    setMessage(message) {
        this.directSetMessage = message;
        this.recomputeMessage();
    }
    addMessage(message) {
        this.dynamicMessages.push(message);
        this.recomputeMessage();
        return message;
    }
    removeMessage(message) {
        (0, utils_1.removeFromArray)(this.dynamicMessages, message);
        this.recomputeMessage();
    }
    recomputeMessage() {
        const messages = [...this.dynamicMessages];
        if (this.directSetMessage) {
            messages.unshift(this.directSetMessage);
        }
        this._message = this.messageFromArray(messages);
        void this.notifyEvent(StatusServiceInterface_1.StatusServiceEvent.MessageChanged, this._message);
    }
    messageFromArray(messages) {
        let message = '';
        messages.forEach((value, index) => {
            const isLast = index === messages.length - 1;
            message += value;
            if (!isLast) {
                message += ', ';
            }
        });
        return message;
    }
}
exports.StatusService = StatusService;
