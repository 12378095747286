"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetCollapsibleBlockOption = exports.CollapsibleBlock = void 0;
const CollapsiblePlugin_1 = require("../../Plugins/CollapsiblePlugin");
const BlockPickerOption_1 = require("../BlockPickerPlugin/BlockPickerOption");
exports.CollapsibleBlock = {
    name: 'Collapsible',
    iconName: 'details-block',
    keywords: ['collapse', 'collapsible', 'toggle'],
    onSelect: (editor) => editor.dispatchCommand(CollapsiblePlugin_1.INSERT_COLLAPSIBLE_COMMAND, undefined),
};
function GetCollapsibleBlockOption(editor) {
    return new BlockPickerOption_1.BlockPickerOption(exports.CollapsibleBlock.name, {
        iconName: exports.CollapsibleBlock.iconName,
        keywords: exports.CollapsibleBlock.keywords,
        onSelect: () => exports.CollapsibleBlock.onSelect(editor),
    });
}
exports.GetCollapsibleBlockOption = GetCollapsibleBlockOption;
