"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetDividerBlockOption = exports.DividerBlock = void 0;
const LexicalHorizontalRuleNode_1 = require("@lexical/react/LexicalHorizontalRuleNode");
const BlockPickerOption_1 = require("../BlockPickerPlugin/BlockPickerOption");
exports.DividerBlock = {
    name: 'Divider',
    iconName: 'horizontal-rule',
    keywords: ['horizontal rule', 'divider', 'hr'],
    onSelect: (editor) => editor.dispatchCommand(LexicalHorizontalRuleNode_1.INSERT_HORIZONTAL_RULE_COMMAND, undefined),
};
function GetDividerBlockOption(editor) {
    return new BlockPickerOption_1.BlockPickerOption(exports.DividerBlock.name, {
        iconName: exports.DividerBlock.iconName,
        keywords: exports.DividerBlock.keywords,
        onSelect: () => exports.DividerBlock.onSelect(editor),
    });
}
exports.GetDividerBlockOption = GetDividerBlockOption;
