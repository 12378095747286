"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTypeaheadAllowingSpacesAndPunctuation = void 0;
const react_1 = require("react");
/**
 * Derived from
 * https://github.com/facebook/lexical/blob/main/packages/lexical-react/src/LexicalTypeaheadMenuPlugin.tsx#L545
 */
function useTypeaheadAllowingSpacesAndPunctuation(trigger, { minLength = 1, maxLength = 75 }) {
    return (0, react_1.useCallback)((text) => {
        const validChars = '[^' + trigger + ']';
        const TypeaheadTriggerRegex = new RegExp('(^|\\s|\\()(' + '[' + trigger + ']' + '(\\S(?:' + validChars + '){0,' + maxLength + '})' + ')$');
        const match = TypeaheadTriggerRegex.exec(text);
        if (match !== null) {
            const maybeLeadingWhitespace = match[1];
            const matchingString = match[3];
            if (matchingString.length >= minLength) {
                return {
                    leadOffset: match.index + maybeLeadingWhitespace.length,
                    matchingString,
                    replaceableString: match[2],
                };
            }
        }
        return null;
    }, [maxLength, minLength, trigger]);
}
exports.useTypeaheadAllowingSpacesAndPunctuation = useTypeaheadAllowingSpacesAndPunctuation;
