"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const mergeRefs_1 = require("@/Hooks/mergeRefs");
const react_1 = require("@ariakit/react");
const react_2 = require("react");
const useModalAnimation_1 = require("../Modal/useModalAnimation");
const useMediaQuery_1 = require("@/Hooks/useMediaQuery");
const snjs_1 = require("@standardnotes/snjs");
const ModalOverlay = (0, react_2.forwardRef)(({ isOpen, children, animationVariant, close, className, backdropClassName, animate, ...props }, ref) => {
    const isMobileScreen = (0, useMediaQuery_1.useMediaQuery)(useMediaQuery_1.MutuallyExclusiveMediaQueryBreakpoints.sm);
    const [isMounted, setElement] = (0, useModalAnimation_1.useModalAnimation)(isOpen, isMobileScreen, animationVariant, (animate === 'mobile' && !isMobileScreen) || (animate === 'desktop' && isMobileScreen));
    const dialog = (0, react_1.useDialogStore)({
        open: isMounted,
        setOpen: (open) => {
            if (!open) {
                close();
            }
        },
        animated: !isMobileScreen,
    });
    const portalId = (0, react_2.useId)();
    const getPortalElement = (0, react_2.useCallback)(() => {
        const id = 'portal/' + portalId;
        const existing = document.getElementById(id);
        if (existing) {
            return existing;
        }
        const div = document.createElement('div');
        div.id = id;
        div.className = 'fixed flex items-center justify-center left-0 top-0 z-modal h-full w-full pointer-events-none';
        div.setAttribute('data-dialog-portal', '');
        document.body.appendChild(div);
        return div;
    }, [portalId]);
    const addCloseMethod = (0, react_2.useCallback)((element) => {
        if (element) {
            ;
            element.close = close;
        }
    }, [close]);
    if (!isMounted) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(react_1.Dialog, { tabIndex: 0, className: (0, snjs_1.classNames)('z-[1] pointer-events-auto m-0 flex h-full w-full flex-col border-[--popover-border-color] bg-default md:bg-[--popover-background-color] md:[backdrop-filter:var(--popover-backdrop-filter)] p-0 md:h-auto md:max-h-[85vh] md:w-160 md:rounded md:border md:shadow-main', 'focus-visible:shadow-none focus-visible:outline-none', className), backdrop: (0, jsx_runtime_1.jsx)("div", { className: (0, snjs_1.classNames)('absolute z-0 h-full w-full bg-passive-5 opacity-0 pointer-events-auto', 'md:transition-opacity md:duration-75 md:opacity-50 [&[data-enter]]:md:opacity-75', backdropClassName), onClick: close }), ref: (0, mergeRefs_1.mergeRefs)([setElement, addCloseMethod, ref]), store: dialog, modal: false, portal: true, portalElement: getPortalElement, preventBodyScroll: true, hideOnInteractOutside: false, ...props, children: children }));
});
exports.default = ModalOverlay;
