"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilesController = exports.FilesControllerEvent = void 0;
const files_1 = require("@standardnotes/files");
const PopoverFileItemAction_1 = require("@/Components/AttachedFilesPopover/PopoverFileItemAction");
const Constants_1 = require("@/Constants/Constants");
const ui_services_1 = require("@standardnotes/ui-services");
const Strings_1 = require("@/Constants/Strings");
const ConcatenateUint8Arrays_1 = require("@/Utils/ConcatenateUint8Arrays");
const filepicker_1 = require("@standardnotes/filepicker");
const snjs_1 = require("@standardnotes/snjs");
const toast_1 = require("@standardnotes/toast");
const mobx_1 = require("mobx");
const AbstractViewController_1 = require("./Abstract/AbstractViewController");
const DownloadOrShareBasedOnPlatform_1 = require("@/Utils/DownloadOrShareBasedOnPlatform");
const UnprotectedFileActions = [PopoverFileItemAction_1.FileItemActionType.ToggleFileProtection];
const NonMutatingFileActions = [PopoverFileItemAction_1.FileItemActionType.DownloadFile, PopoverFileItemAction_1.FileItemActionType.PreviewFile];
var FilesControllerEvent;
(function (FilesControllerEvent) {
    FilesControllerEvent[FilesControllerEvent["FileUploadedToNote"] = 0] = "FileUploadedToNote";
})(FilesControllerEvent || (exports.FilesControllerEvent = FilesControllerEvent = {}));
class FilesController extends AbstractViewController_1.AbstractViewController {
    deinit() {
        super.deinit();
        this.notesController = undefined;
        this.filePreviewModalController = undefined;
    }
    constructor(notesController, filePreviewModalController, archiveService, vaultDisplayService, vaults, items, files, mutator, sync, protections, alerts, platform, mobileDevice, _isNativeMobileWeb, eventBus) {
        super(eventBus);
        this.notesController = notesController;
        this.filePreviewModalController = filePreviewModalController;
        this.archiveService = archiveService;
        this.vaultDisplayService = vaultDisplayService;
        this.vaults = vaults;
        this.items = items;
        this.files = files;
        this.mutator = mutator;
        this.sync = sync;
        this.protections = protections;
        this.alerts = alerts;
        this.platform = platform;
        this.mobileDevice = mobileDevice;
        this._isNativeMobileWeb = _isNativeMobileWeb;
        this.allFiles = [];
        this.attachedFiles = [];
        this.showFileContextMenu = false;
        this.showProtectedOverlay = false;
        this.fileContextMenuLocation = { x: 0, y: 0 };
        this.shouldUseStreamingReader = filepicker_1.StreamingFileSaver.available();
        this.reader = this.shouldUseStreamingReader ? filepicker_1.StreamingFileReader : filepicker_1.ClassicFileReader;
        this.maxFileSize = this.reader.maximumFileSize();
        this.setShowFileContextMenu = (enabled) => {
            this.showFileContextMenu = enabled;
        };
        this.setShowProtectedOverlay = (enabled) => {
            this.showProtectedOverlay = enabled;
        };
        this.setFileContextMenuLocation = (location) => {
            this.fileContextMenuLocation = location;
        };
        this.reloadAllFiles = () => {
            this.allFiles = this.items.getDisplayableFiles();
        };
        this.reloadAttachedFiles = () => {
            const note = this.notesController.firstSelectedNote;
            if (note) {
                this.attachedFiles = this.items.itemsReferencingItem(note).filter(snjs_1.isFile);
            }
        };
        this.deleteFile = async (file) => {
            const shouldDelete = await (0, ui_services_1.confirmDialog)({
                text: `Are you sure you want to permanently delete "${file.name}"?`,
                confirmButtonStyle: 'danger',
            });
            if (shouldDelete) {
                const deletingToastId = (0, toast_1.addToast)({
                    type: toast_1.ToastType.Loading,
                    message: `Deleting file "${file.name}"...`,
                });
                await this.files.deleteFile(file);
                (0, toast_1.addToast)({
                    type: toast_1.ToastType.Success,
                    message: `Deleted file "${file.name}"`,
                });
                (0, toast_1.dismissToast)(deletingToastId);
            }
        };
        this.attachFileToSelectedNote = async (file) => {
            const note = this.notesController.firstSelectedNote;
            if (!note) {
                (0, toast_1.addToast)({
                    type: toast_1.ToastType.Error,
                    message: 'Could not attach file because selected note was deleted',
                });
                return;
            }
            await this.mutator.associateFileWithNote(file, note);
            void this.sync.sync();
        };
        this.detachFileFromNote = async (file) => {
            const note = this.notesController.firstSelectedNote;
            if (!note) {
                (0, toast_1.addToast)({
                    type: toast_1.ToastType.Error,
                    message: 'Could not attach file because selected note was deleted',
                });
                return;
            }
            await this.mutator.disassociateFileWithNote(file, note);
            void this.sync.sync();
        };
        this.toggleFileProtection = async (file) => {
            let result;
            if (file.protected) {
                result = await this.protections.unprotectFile(file);
            }
            else {
                result = await this.protections.protectFile(file);
            }
            void this.sync.sync();
            const isProtected = result ? result.protected : file.protected;
            return isProtected;
        };
        this.authorizeProtectedActionForFile = async (file, challengeReason) => {
            const authorizedFiles = await this.protections.authorizeProtectedActionForItems([file], challengeReason);
            const isAuthorized = authorizedFiles.length > 0 && authorizedFiles.includes(file);
            return isAuthorized;
        };
        this.renameFile = async (file, fileName) => {
            await this.mutator.renameFile(file, fileName);
            void this.sync.sync();
        };
        this.handleFileAction = async (action) => {
            const file = action.payload.file;
            let isAuthorizedForAction = true;
            const requiresAuthorization = file.protected && !UnprotectedFileActions.includes(action.type);
            if (requiresAuthorization) {
                isAuthorizedForAction = await this.authorizeProtectedActionForFile(file, snjs_1.ChallengeReason.AccessProtectedFile);
            }
            if (!isAuthorizedForAction) {
                return {
                    didHandleAction: false,
                };
            }
            switch (action.type) {
                case PopoverFileItemAction_1.FileItemActionType.AttachFileToNote:
                    await this.attachFileToSelectedNote(file);
                    break;
                case PopoverFileItemAction_1.FileItemActionType.DetachFileToNote:
                    await this.detachFileFromNote(file);
                    break;
                case PopoverFileItemAction_1.FileItemActionType.DeleteFile:
                    await this.deleteFile(file);
                    break;
                case PopoverFileItemAction_1.FileItemActionType.DownloadFile:
                    await this.downloadFile(file);
                    break;
                case PopoverFileItemAction_1.FileItemActionType.ToggleFileProtection: {
                    const isProtected = await this.toggleFileProtection(file);
                    action.callback(isProtected);
                    break;
                }
                case PopoverFileItemAction_1.FileItemActionType.RenameFile:
                    await this.renameFile(file, action.payload.name);
                    break;
                case PopoverFileItemAction_1.FileItemActionType.PreviewFile:
                    this.filePreviewModalController.activate(file, action.payload.otherFiles);
                    break;
            }
            if (!NonMutatingFileActions.includes(action.type)) {
                this.sync.sync().catch(console.error);
            }
            return {
                didHandleAction: true,
            };
        };
        this.alertIfFileExceedsSizeLimit = (file) => {
            if (!this.shouldUseStreamingReader && this.maxFileSize && file.size >= this.maxFileSize) {
                this.alerts
                    .alert(`This file exceeds the limits supported in this browser. To upload files greater than ${this.maxFileSize / Constants_1.BYTES_IN_ONE_MEGABYTE}MB, please use the desktop application or the Chrome browser.`, `Cannot upload file "${file.name}"`)
                    .catch(console.error);
                return true;
            }
            return false;
        };
        this.deleteFilesPermanently = async (files) => {
            const title = Strings_1.Strings.trashItemsTitle;
            const text = files.length === 1 ? Strings_1.StringUtils.deleteFile(files[0].name) : Strings_1.Strings.deleteMultipleFiles;
            if (await (0, ui_services_1.confirmDialog)({
                title,
                text,
                confirmButtonStyle: 'danger',
            })) {
                await Promise.all(files.map((file) => this.files.deleteFile(file)));
                void this.sync.sync();
            }
        };
        this.setProtectionForFiles = async (protect, files) => {
            if (protect) {
                const protectedItems = await this.protections.protectItems(files);
                if (protectedItems) {
                    this.setShowProtectedOverlay(true);
                }
            }
            else {
                const unprotectedItems = await this.protections.unprotectItems(files, snjs_1.ChallengeReason.UnprotectFile);
                if (unprotectedItems) {
                    this.setShowProtectedOverlay(false);
                }
            }
            void this.sync.sync();
        };
        this.downloadFiles = async (files) => {
            if (this.platform === snjs_1.Platform.MacDesktop) {
                for (const file of files) {
                    await this.handleFileAction({
                        type: PopoverFileItemAction_1.FileItemActionType.DownloadFile,
                        payload: {
                            file,
                        },
                    });
                }
                return;
            }
            await Promise.all(files.map((file) => this.handleFileAction({
                type: PopoverFileItemAction_1.FileItemActionType.DownloadFile,
                payload: {
                    file,
                },
            })));
        };
        (0, mobx_1.makeObservable)(this, {
            allFiles: mobx_1.observable,
            attachedFiles: mobx_1.observable,
            showFileContextMenu: mobx_1.observable,
            fileContextMenuLocation: mobx_1.observable,
            showProtectedOverlay: mobx_1.observable,
            reloadAllFiles: mobx_1.action,
            reloadAttachedFiles: mobx_1.action,
            setShowFileContextMenu: mobx_1.action,
            setShowProtectedOverlay: mobx_1.action,
            setFileContextMenuLocation: mobx_1.action,
        });
        this.disposers.push(items.streamItems(snjs_1.ContentType.TYPES.File, () => {
            this.reloadAllFiles();
            this.reloadAttachedFiles();
        }));
        this.disposers.push((0, mobx_1.reaction)(() => notesController.selectedNotes, () => {
            this.reloadAttachedFiles();
        }));
    }
    async downloadFile(file) {
        let downloadingToastId = '';
        try {
            const saver = filepicker_1.StreamingFileSaver.available() ? new filepicker_1.StreamingFileSaver(file.name) : new filepicker_1.ClassicFileSaver();
            const isUsingStreamingSaver = saver instanceof filepicker_1.StreamingFileSaver;
            if (isUsingStreamingSaver) {
                await saver.selectFileToSaveTo();
            }
            downloadingToastId = (0, toast_1.addToast)({
                type: toast_1.ToastType.Progress,
                message: `Downloading file "${file.name}" (0%)`,
                progress: 0,
            });
            const decryptedBytesArray = [];
            let lastProgress;
            const result = await this.files.downloadFile(file, async (decryptedBytes, progress) => {
                if (isUsingStreamingSaver) {
                    await saver.pushBytes(decryptedBytes);
                }
                else {
                    decryptedBytesArray.push(decryptedBytes);
                }
                const progressPercent = Math.floor(progress.percentComplete);
                (0, toast_1.updateToast)(downloadingToastId, {
                    message: (0, files_1.fileProgressToHumanReadableString)(progress, file.name, { showPercent: true }),
                    progress: progressPercent,
                });
                lastProgress = progress;
            });
            if (result instanceof snjs_1.ClientDisplayableError) {
                throw new Error(result.text);
            }
            if (isUsingStreamingSaver) {
                await saver.finish();
            }
            else {
                const finalBytes = (0, ConcatenateUint8Arrays_1.concatenateUint8Arrays)(decryptedBytesArray);
                const blob = new Blob([finalBytes], {
                    type: file.mimeType,
                });
                // await downloadOrShareBlobBasedOnPlatform(this, blob, file.name, false)
                await (0, DownloadOrShareBasedOnPlatform_1.downloadOrShareBlobBasedOnPlatform)({
                    archiveService: this.archiveService,
                    platform: this.platform,
                    mobileDevice: this.mobileDevice,
                    blob,
                    filename: file.name,
                    isNativeMobileWeb: this._isNativeMobileWeb.execute().getValue(),
                    showToastOnAndroid: false,
                });
            }
            (0, toast_1.addToast)({
                type: toast_1.ToastType.Success,
                message: `Successfully downloaded file${lastProgress && lastProgress.source === 'local' ? ' from local backup' : ''}`,
            });
        }
        catch (error) {
            console.error(error);
            (0, toast_1.addToast)({
                type: toast_1.ToastType.Error,
                message: 'There was an error while downloading the file',
            });
        }
        if (downloadingToastId.length > 0) {
            (0, toast_1.dismissToast)(downloadingToastId);
        }
    }
    async selectAndUploadNewFiles(note, callback) {
        const selectedFiles = await this.reader.selectFiles();
        selectedFiles.forEach(async (file) => {
            if (this.alertIfFileExceedsSizeLimit(file)) {
                return;
            }
            const uploadedFile = await this.uploadNewFile(file, {
                note,
            });
            if (uploadedFile && callback) {
                callback(uploadedFile);
            }
        });
    }
    async uploadNewFile(fileOrHandle, options = {}) {
        const { showToast = true, note } = options;
        let toastId;
        try {
            const minimumChunkSize = this.files.minimumChunkSize();
            const fileToUpload = fileOrHandle instanceof File
                ? fileOrHandle
                : fileOrHandle instanceof FileSystemFileHandle && this.shouldUseStreamingReader
                    ? await fileOrHandle.getFile()
                    : undefined;
            if (!fileToUpload) {
                return;
            }
            if (this.alertIfFileExceedsSizeLimit(fileToUpload)) {
                return;
            }
            const vaultForNote = note ? this.vaults.getItemVault(note) : undefined;
            const operation = await this.files.beginNewFileUpload(fileToUpload.size, vaultForNote || this.vaultDisplayService.exclusivelyShownVault);
            if (operation instanceof snjs_1.ClientDisplayableError) {
                (0, toast_1.addToast)({
                    type: toast_1.ToastType.Error,
                    message: 'Unable to start upload session',
                });
                throw new Error('Unable to start upload session');
            }
            const initialProgress = operation.getProgress().percentComplete;
            if (showToast) {
                toastId = (0, toast_1.addToast)({
                    type: toast_1.ToastType.Progress,
                    message: `Uploading file "${fileToUpload.name}" (${initialProgress}%)`,
                    progress: initialProgress,
                });
            }
            const onChunk = async ({ data, index, isLast }) => {
                await this.files.pushBytesForUpload(operation, data, index, isLast);
                const percentComplete = Math.round(operation.getProgress().percentComplete);
                if (toastId) {
                    (0, toast_1.updateToast)(toastId, {
                        message: `Uploading file "${fileToUpload.name}" (${percentComplete}%)`,
                        progress: percentComplete,
                    });
                }
            };
            const fileResult = await this.reader.readFile(fileToUpload, minimumChunkSize, onChunk);
            if (!fileResult.mimeType) {
                const { ext } = (0, filepicker_1.parseFileName)(fileToUpload.name);
                fileResult.mimeType = await this.archiveService.getMimeType(ext);
            }
            const uploadedFile = await this.files.finishUpload(operation, fileResult);
            if (uploadedFile instanceof snjs_1.ClientDisplayableError) {
                (0, toast_1.addToast)({
                    type: toast_1.ToastType.Error,
                    message: 'Unable to close upload session',
                });
                throw new Error('Unable to close upload session');
            }
            if (toastId) {
                (0, toast_1.dismissToast)(toastId);
            }
            if (showToast) {
                (0, toast_1.addToast)({
                    type: toast_1.ToastType.Success,
                    message: `Uploaded file "${uploadedFile.name}"`,
                    actions: [
                        {
                            label: 'Open',
                            handler: (toastId) => {
                                void this.handleFileAction({
                                    type: PopoverFileItemAction_1.FileItemActionType.PreviewFile,
                                    payload: { file: uploadedFile },
                                });
                                (0, toast_1.dismissToast)(toastId);
                            },
                        },
                    ],
                    autoClose: true,
                });
            }
            return uploadedFile;
        }
        catch (error) {
            console.error(error);
            if (toastId) {
                (0, toast_1.dismissToast)(toastId);
            }
            (0, toast_1.addToast)({
                type: toast_1.ToastType.Error,
                message: 'There was an error while uploading the file',
            });
        }
        return undefined;
    }
    notifyObserversOfUploadedFileLinkingToCurrentNote(fileUuid) {
        this.notifyEvent(FilesControllerEvent.FileUploadedToNote, {
            [FilesControllerEvent.FileUploadedToNote]: { uuid: fileUuid },
        });
    }
}
exports.FilesController = FilesController;
