"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VaultUserService = void 0;
const responses_1 = require("@standardnotes/responses");
const AbstractService_1 = require("./../Service/AbstractService");
const VaultUserServiceEvent_1 = require("./VaultUserServiceEvent");
const domain_core_1 = require("@standardnotes/domain-core");
class VaultUserService extends AbstractService_1.AbstractService {
    constructor(vaults, vaultLocks, _getVaultUsers, _removeVaultMember, _isVaultOwner, _isVaultAdmin, _isReadonlyVaultMember, _getVault, _leaveVault, designateSurvivorUseCase, eventBus) {
        super(eventBus);
        this.vaults = vaults;
        this.vaultLocks = vaultLocks;
        this._getVaultUsers = _getVaultUsers;
        this._removeVaultMember = _removeVaultMember;
        this._isVaultOwner = _isVaultOwner;
        this._isVaultAdmin = _isVaultAdmin;
        this._isReadonlyVaultMember = _isReadonlyVaultMember;
        this._getVault = _getVault;
        this._leaveVault = _leaveVault;
        this.designateSurvivorUseCase = designateSurvivorUseCase;
    }
    deinit() {
        super.deinit();
        this.vaults = undefined;
        this._getVaultUsers = undefined;
        this._removeVaultMember = undefined;
        this._isVaultOwner = undefined;
        this._getVault = undefined;
        this._leaveVault = undefined;
    }
    async designateSurvivor(sharedVault, userUuid) {
        const result = await this.designateSurvivorUseCase.execute({
            sharedVaultMemberUuid: userUuid,
            sharedVaultUuid: sharedVault.sharing.sharedVaultUuid,
        });
        if (result.isFailed()) {
            return domain_core_1.Result.fail(`Could not designate survivor: ${result.getError()}`);
        }
        return domain_core_1.Result.ok();
    }
    async invalidateVaultUsersCache(sharedVaultUuid) {
        if (sharedVaultUuid) {
            await this._getVaultUsers.execute({
                sharedVaultUuid: sharedVaultUuid,
                readFromCache: false,
            });
            void this.notifyEvent(VaultUserServiceEvent_1.VaultUserServiceEvent.InvalidatedUserCacheForVault, sharedVaultUuid);
            return;
        }
        await Promise.all(this.vaults.getVaults().map(async (vault) => {
            if (!vault.isSharedVaultListing()) {
                return;
            }
            await this._getVaultUsers.execute({
                sharedVaultUuid: vault.sharing.sharedVaultUuid,
                readFromCache: false,
            });
            void this.notifyEvent(VaultUserServiceEvent_1.VaultUserServiceEvent.InvalidatedUserCacheForVault, vault.sharing.sharedVaultUuid);
        }));
        void this.notifyEvent(VaultUserServiceEvent_1.VaultUserServiceEvent.InvalidatedAllUserCache);
    }
    async getSharedVaultUsersFromServer(sharedVault) {
        const result = await this._getVaultUsers.execute({
            sharedVaultUuid: sharedVault.sharing.sharedVaultUuid,
            readFromCache: false,
        });
        if (result.isFailed()) {
            return undefined;
        }
        return result.getValue();
    }
    isCurrentUserSharedVaultOwner(sharedVault) {
        return this._isVaultOwner.execute(sharedVault).getValue();
    }
    isCurrentUserSharedVaultAdmin(sharedVault) {
        return this._isVaultAdmin.execute(sharedVault).getValue();
    }
    isCurrentUserReadonlyVaultMember(vault) {
        if (!vault.isSharedVaultListing()) {
            return false;
        }
        return this._isReadonlyVaultMember.execute(vault).getValue();
    }
    async removeUserFromSharedVault(sharedVault, userUuid) {
        if (!this.isCurrentUserSharedVaultOwner(sharedVault)) {
            throw new Error('Only vault admins can remove users');
        }
        if (this.vaultLocks.isVaultLocked(sharedVault)) {
            throw new Error('Cannot remove user from locked vault');
        }
        const result = await this._removeVaultMember.execute({
            sharedVaultUuid: sharedVault.sharing.sharedVaultUuid,
            userUuid,
        });
        if (result.isFailed()) {
            return result;
        }
        void this.notifyEvent(VaultUserServiceEvent_1.VaultUserServiceEvent.UsersChanged);
        await this.vaults.rotateVaultRootKey(sharedVault);
        return result;
    }
    isVaultUserOwner(user) {
        const result = this._getVault.execute({ sharedVaultUuid: user.shared_vault_uuid });
        if (result.isFailed()) {
            return false;
        }
        const vault = result.getValue();
        return vault != undefined && vault.sharing.ownerUserUuid === user.user_uuid;
    }
    async leaveSharedVault(sharedVault) {
        const result = await this._leaveVault.execute({
            sharedVault: sharedVault,
        });
        if ((0, responses_1.isClientDisplayableError)(result)) {
            return result;
        }
        void this.notifyEvent(VaultUserServiceEvent_1.VaultUserServiceEvent.UsersChanged);
    }
    getFormattedMemberPermission(permission) {
        switch (permission) {
            case 'admin':
                return 'Admin';
            case 'write':
                return 'Read / Write';
            case 'read':
                return 'Read-only';
            default:
                return 'Unknown';
        }
    }
}
exports.VaultUserService = VaultUserService;
