"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetOutdentBlockOption = exports.GetIndentBlockOption = exports.OutdentBlock = exports.IndentBlock = void 0;
const lexical_1 = require("lexical");
const BlockPickerOption_1 = require("../BlockPickerPlugin/BlockPickerOption");
exports.IndentBlock = {
    name: 'Indent',
    iconName: 'indent',
    keywords: ['indent'],
    onSelect: (editor) => editor.dispatchCommand(lexical_1.INDENT_CONTENT_COMMAND, undefined),
};
exports.OutdentBlock = {
    name: 'Outdent',
    iconName: 'outdent',
    keywords: ['outdent'],
    onSelect: (editor) => editor.dispatchCommand(lexical_1.OUTDENT_CONTENT_COMMAND, undefined),
};
function GetIndentBlockOption(editor) {
    return new BlockPickerOption_1.BlockPickerOption(exports.IndentBlock.name, {
        iconName: exports.IndentBlock.iconName,
        keywords: exports.IndentBlock.keywords,
        onSelect: () => exports.IndentBlock.onSelect(editor),
    });
}
exports.GetIndentBlockOption = GetIndentBlockOption;
function GetOutdentBlockOption(editor) {
    return new BlockPickerOption_1.BlockPickerOption(exports.OutdentBlock.name, {
        iconName: exports.OutdentBlock.iconName,
        keywords: exports.OutdentBlock.keywords,
        onSelect: () => exports.OutdentBlock.onSelect(editor),
    });
}
exports.GetOutdentBlockOption = GetOutdentBlockOption;
