"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNoteBlob = exports.getNoteFileName = exports.getNoteFormat = void 0;
const HeadlessSuperConverter_1 = require("@/Components/SuperEditor/Tools/HeadlessSuperConverter");
const snjs_1 = require("@standardnotes/snjs");
const getNoteFormat = (application, note) => {
    if (note.noteType === snjs_1.NoteType.Super) {
        const superNoteExportFormatPref = application.getPreference(snjs_1.PrefKey.SuperNoteExportFormat, snjs_1.PrefDefaults[snjs_1.PrefKey.SuperNoteExportFormat]);
        return superNoteExportFormatPref;
    }
    const editor = application.componentManager.editorForNote(note);
    return editor.fileType;
};
exports.getNoteFormat = getNoteFormat;
const getNoteFileName = (application, note) => {
    const format = (0, exports.getNoteFormat)(application, note);
    return `${note.title}.${format}`;
};
exports.getNoteFileName = getNoteFileName;
const getNoteBlob = (application, note) => {
    const format = (0, exports.getNoteFormat)(application, note);
    let type;
    switch (format) {
        case 'html':
            type = 'text/html';
            break;
        case 'json':
            type = 'application/json';
            break;
        case 'md':
            type = 'text/markdown';
            break;
        default:
            type = 'text/plain';
            break;
    }
    const content = note.noteType === snjs_1.NoteType.Super
        ? new HeadlessSuperConverter_1.HeadlessSuperConverter().convertSuperStringToOtherFormat(note.text, format)
        : note.text;
    const blob = new Blob([content], {
        type,
    });
    return blob;
};
exports.getNoteBlob = getNoteBlob;
