"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetNumberedListBlockOption = exports.GetChecklistBlockOption = exports.GetBulletedListBlockOption = exports.NumberedListBlock = exports.ChecklistBlock = exports.BulletedListBlock = void 0;
const list_1 = require("@lexical/list");
const BlockPickerOption_1 = require("../BlockPickerPlugin/BlockPickerOption");
exports.BulletedListBlock = {
    name: 'Bulleted List',
    iconName: 'list-bulleted',
    keywords: ['bulleted list', 'unordered list', 'ul'],
    onSelect: (editor) => editor.dispatchCommand(list_1.INSERT_UNORDERED_LIST_COMMAND, undefined),
};
exports.ChecklistBlock = {
    name: 'Check List',
    iconName: 'check',
    keywords: ['check list', 'todo list'],
    onSelect: (editor) => editor.dispatchCommand(list_1.INSERT_CHECK_LIST_COMMAND, undefined),
};
exports.NumberedListBlock = {
    name: 'Numbered List',
    iconName: 'list-numbered',
    keywords: ['numbered list', 'ordered list', 'ol'],
    onSelect: (editor) => editor.dispatchCommand(list_1.INSERT_ORDERED_LIST_COMMAND, undefined),
};
function GetBulletedListBlockOption(editor) {
    return new BlockPickerOption_1.BlockPickerOption(exports.BulletedListBlock.name, {
        iconName: exports.BulletedListBlock.iconName,
        keywords: exports.BulletedListBlock.keywords,
        onSelect: () => exports.BulletedListBlock.onSelect(editor),
    });
}
exports.GetBulletedListBlockOption = GetBulletedListBlockOption;
function GetChecklistBlockOption(editor) {
    return new BlockPickerOption_1.BlockPickerOption(exports.ChecklistBlock.name, {
        iconName: exports.ChecklistBlock.iconName,
        keywords: exports.ChecklistBlock.keywords,
        onSelect: () => exports.ChecklistBlock.onSelect(editor),
    });
}
exports.GetChecklistBlockOption = GetChecklistBlockOption;
function GetNumberedListBlockOption(editor) {
    return new BlockPickerOption_1.BlockPickerOption(exports.NumberedListBlock.name, {
        iconName: exports.NumberedListBlock.iconName,
        keywords: exports.NumberedListBlock.keywords,
        onSelect: () => exports.NumberedListBlock.onSelect(editor),
    });
}
exports.GetNumberedListBlockOption = GetNumberedListBlockOption;
