"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AsymmetricMessageService = void 0;
const models_1 = require("@standardnotes/models");
const domain_core_1 = require("@standardnotes/domain-core");
const SyncEvent_1 = require("../Event/SyncEvent");
const AbstractService_1 = require("../Service/AbstractService");
const WebSocketsServiceEvent_1 = require("../Api/WebSocketsServiceEvent");
class AsymmetricMessageService extends AbstractService_1.AbstractService {
    constructor(encryption, mutator, sessions, sync, messageServer, _createOrEditContact, _findContact, _replaceContactData, _getTrustedPayload, _getVault, _handleRootKeyChangedMessage, _getOutboundMessagesUseCase, _getInboundMessagesUseCase, _getUntrustedPayload, _getKeyPairs, eventBus) {
        super(eventBus);
        this.encryption = encryption;
        this.mutator = mutator;
        this.sessions = sessions;
        this.sync = sync;
        this.messageServer = messageServer;
        this._createOrEditContact = _createOrEditContact;
        this._findContact = _findContact;
        this._replaceContactData = _replaceContactData;
        this._getTrustedPayload = _getTrustedPayload;
        this._getVault = _getVault;
        this._handleRootKeyChangedMessage = _handleRootKeyChangedMessage;
        this._getOutboundMessagesUseCase = _getOutboundMessagesUseCase;
        this._getInboundMessagesUseCase = _getInboundMessagesUseCase;
        this._getUntrustedPayload = _getUntrustedPayload;
        this._getKeyPairs = _getKeyPairs;
        this.handledMessages = new Set();
    }
    deinit() {
        super.deinit();
        this.messageServer = undefined;
        this.encryption = undefined;
        this.mutator = undefined;
        this._createOrEditContact = undefined;
        this._findContact = undefined;
        this._replaceContactData = undefined;
        this._getTrustedPayload = undefined;
        this._getVault = undefined;
        this._handleRootKeyChangedMessage = undefined;
        this._getOutboundMessagesUseCase = undefined;
        this._getInboundMessagesUseCase = undefined;
        this._getUntrustedPayload = undefined;
    }
    async handleEvent(event) {
        switch (event.type) {
            case SyncEvent_1.SyncEvent.ReceivedAsymmetricMessages:
                void this.handleRemoteReceivedAsymmetricMessages(event.payload);
                break;
            case WebSocketsServiceEvent_1.WebSocketsServiceEvent.MessageSentToUser:
                void this.handleRemoteReceivedAsymmetricMessages([event.payload.message]);
                break;
        }
    }
    async getOutboundMessages() {
        return this._getOutboundMessagesUseCase.execute();
    }
    async getInboundMessages() {
        return this._getInboundMessagesUseCase.execute();
    }
    async downloadAndProcessInboundMessages() {
        const messages = await this.getInboundMessages();
        if (messages.isFailed()) {
            return;
        }
        await this.handleRemoteReceivedAsymmetricMessages(messages.getValue());
    }
    sortServerMessages(messages) {
        const SortedPriorityTypes = [models_1.AsymmetricMessagePayloadType.SenderKeypairChanged];
        const priority = [];
        const regular = [];
        const allMessagesOldestFirst = messages.slice().sort((a, b) => a.created_at_timestamp - b.created_at_timestamp);
        const messageTypeMap = {};
        for (const message of allMessagesOldestFirst) {
            const messageType = this.getServerMessageType(message);
            if (!messageType) {
                continue;
            }
            messageTypeMap[message.uuid] = messageType;
            if (SortedPriorityTypes.includes(messageType)) {
                priority.push(message);
            }
            else {
                regular.push(message);
            }
        }
        const sortedPriority = priority.sort((a, b) => {
            const typeA = messageTypeMap[a.uuid];
            const typeB = messageTypeMap[b.uuid];
            if (typeA !== typeB) {
                return SortedPriorityTypes.indexOf(typeA) - SortedPriorityTypes.indexOf(typeB);
            }
            return a.created_at_timestamp - b.created_at_timestamp;
        });
        const regularMessagesOldestFirst = regular.sort((a, b) => a.created_at_timestamp - b.created_at_timestamp);
        return [...sortedPriority, ...regularMessagesOldestFirst];
    }
    getServerMessageType(message) {
        const result = this.getUntrustedMessagePayload(message);
        if (result.isFailed()) {
            return undefined;
        }
        return result.getValue().type;
    }
    async handleRemoteReceivedAsymmetricMessages(messages) {
        if (messages.length === 0) {
            return;
        }
        const sortedMessages = this.sortServerMessages(messages);
        for (const message of sortedMessages) {
            const trustedPayload = this.getTrustedMessagePayload(message);
            if (trustedPayload.isFailed()) {
                continue;
            }
            await this.handleTrustedMessageResult(message, trustedPayload.getValue());
        }
        void this.sync.sync();
    }
    async handleTrustedMessageResult(message, payload) {
        if (this.handledMessages.has(message.uuid)) {
            return;
        }
        this.handledMessages.add(message.uuid);
        if (payload.type === models_1.AsymmetricMessagePayloadType.ContactShare) {
            await this.handleTrustedContactShareMessage(message, payload);
        }
        else if (payload.type === models_1.AsymmetricMessagePayloadType.SenderKeypairChanged) {
            await this.handleTrustedSenderKeypairChangedMessage(message, payload);
        }
        else if (payload.type === models_1.AsymmetricMessagePayloadType.SharedVaultRootKeyChanged) {
            await this.handleTrustedSharedVaultRootKeyChangedMessage(message, payload);
        }
        else if (payload.type === models_1.AsymmetricMessagePayloadType.SharedVaultMetadataChanged) {
            await this.handleTrustedVaultMetadataChangedMessage(message, payload);
        }
        else if (payload.type === models_1.AsymmetricMessagePayloadType.SharedVaultInvite) {
            throw new Error('Shared vault invites payloads are not handled as part of asymmetric messages');
        }
        await this.deleteMessageAfterProcessing(message);
    }
    getUntrustedMessagePayload(message) {
        const keys = this._getKeyPairs.execute();
        if (keys.isFailed()) {
            return domain_core_1.Result.fail(keys.getError());
        }
        const result = this._getUntrustedPayload.execute({
            privateKey: keys.getValue().encryption.privateKey,
            payload: message,
        });
        if (result.isFailed()) {
            return domain_core_1.Result.fail(result.getError());
        }
        return result;
    }
    getTrustedMessagePayload(message) {
        const contact = this._findContact.execute({ userUuid: message.sender_uuid });
        if (contact.isFailed()) {
            return domain_core_1.Result.fail(contact.getError());
        }
        const keys = this._getKeyPairs.execute();
        if (keys.isFailed()) {
            return domain_core_1.Result.fail(keys.getError());
        }
        const result = this._getTrustedPayload.execute({
            privateKey: keys.getValue().encryption.privateKey,
            sender: contact.getValue(),
            ownUserUuid: this.sessions.userUuid,
            payload: message,
        });
        if (result.isFailed()) {
            return domain_core_1.Result.fail(result.getError());
        }
        return result;
    }
    async deleteMessageAfterProcessing(message) {
        await this.messageServer.deleteMessage({ messageUuid: message.uuid });
    }
    async handleTrustedVaultMetadataChangedMessage(_message, trustedPayload) {
        const vault = this._getVault.execute({
            sharedVaultUuid: trustedPayload.data.sharedVaultUuid,
        });
        if (vault.isFailed()) {
            return;
        }
        await this.mutator.changeItem(vault.getValue(), (mutator) => {
            mutator.name = trustedPayload.data.name;
            mutator.description = trustedPayload.data.description;
        }, models_1.MutationType.UpdateUserTimestamps, models_1.PayloadEmitSource.RemoteRetrieved);
    }
    async handleTrustedContactShareMessage(_message, trustedPayload) {
        if (trustedPayload.data.trustedContact.isMe) {
            return;
        }
        await this._replaceContactData.execute(trustedPayload.data.trustedContact);
    }
    async handleTrustedSenderKeypairChangedMessage(message, trustedPayload) {
        await this._createOrEditContact.execute({
            contactUuid: message.sender_uuid,
            publicKey: trustedPayload.data.newEncryptionPublicKey,
            signingPublicKey: trustedPayload.data.newSigningPublicKey,
        });
    }
    async handleTrustedSharedVaultRootKeyChangedMessage(_message, trustedPayload) {
        await this._handleRootKeyChangedMessage.execute(trustedPayload);
    }
}
exports.AsymmetricMessageService = AsymmetricMessageService;
