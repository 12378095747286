"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetJustifyAlignBlockOption = exports.GetRightAlignBlockOption = exports.GetCenterAlignBlockOption = exports.GetLeftAlignBlockOption = exports.JustifyAlignBlock = exports.RightAlignBlock = exports.CenterAlignBlock = exports.LeftAlignBlock = void 0;
const lexical_1 = require("lexical");
const BlockPickerOption_1 = require("../BlockPickerPlugin/BlockPickerOption");
exports.LeftAlignBlock = {
    name: 'Align left',
    iconName: 'align-left',
    keywords: ['align', 'justify', 'left'],
    onSelect: (editor) => editor.dispatchCommand(lexical_1.FORMAT_ELEMENT_COMMAND, 'left'),
};
exports.CenterAlignBlock = {
    name: 'Align center',
    iconName: 'align-center',
    keywords: ['align', 'justify', 'center'],
    onSelect: (editor) => editor.dispatchCommand(lexical_1.FORMAT_ELEMENT_COMMAND, 'center'),
};
exports.RightAlignBlock = {
    name: 'Align right',
    iconName: 'align-right',
    keywords: ['align', 'justify', 'right'],
    onSelect: (editor) => editor.dispatchCommand(lexical_1.FORMAT_ELEMENT_COMMAND, 'right'),
};
exports.JustifyAlignBlock = {
    name: 'Align justify',
    iconName: 'align-justify',
    keywords: ['align', 'justify', 'justify'],
    onSelect: (editor) => editor.dispatchCommand(lexical_1.FORMAT_ELEMENT_COMMAND, 'justify'),
};
function GetLeftAlignBlockOption(editor) {
    return new BlockPickerOption_1.BlockPickerOption(exports.LeftAlignBlock.name, {
        iconName: exports.LeftAlignBlock.iconName,
        keywords: exports.LeftAlignBlock.keywords,
        onSelect: () => exports.LeftAlignBlock.onSelect(editor),
    });
}
exports.GetLeftAlignBlockOption = GetLeftAlignBlockOption;
function GetCenterAlignBlockOption(editor) {
    return new BlockPickerOption_1.BlockPickerOption(exports.CenterAlignBlock.name, {
        iconName: exports.CenterAlignBlock.iconName,
        keywords: exports.CenterAlignBlock.keywords,
        onSelect: () => exports.CenterAlignBlock.onSelect(editor),
    });
}
exports.GetCenterAlignBlockOption = GetCenterAlignBlockOption;
function GetRightAlignBlockOption(editor) {
    return new BlockPickerOption_1.BlockPickerOption(exports.RightAlignBlock.name, {
        iconName: exports.RightAlignBlock.iconName,
        keywords: exports.RightAlignBlock.keywords,
        onSelect: () => exports.RightAlignBlock.onSelect(editor),
    });
}
exports.GetRightAlignBlockOption = GetRightAlignBlockOption;
function GetJustifyAlignBlockOption(editor) {
    return new BlockPickerOption_1.BlockPickerOption(exports.JustifyAlignBlock.name, {
        iconName: exports.JustifyAlignBlock.iconName,
        keywords: exports.JustifyAlignBlock.keywords,
        onSelect: () => exports.JustifyAlignBlock.onSelect(editor),
    });
}
exports.GetJustifyAlignBlockOption = GetJustifyAlignBlockOption;
