"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const mobx_react_lite_1 = require("mobx-react-lite");
const Content_1 = require("@/Components/Preferences/PreferencesComponents/Content");
const ApplicationProvider_1 = require("@/Components/ApplicationProvider");
const SubscriptionStatusText = () => {
    const application = (0, ApplicationProvider_1.useApplication)();
    const { userSubscriptionName, userSubscriptionExpirationDate, isUserSubscriptionExpired, isUserSubscriptionCanceled, } = application.subscriptions;
    const expirationDateString = userSubscriptionExpirationDate === null || userSubscriptionExpirationDate === void 0 ? void 0 : userSubscriptionExpirationDate.toLocaleString();
    if (isUserSubscriptionCanceled) {
        return ((0, jsx_runtime_1.jsxs)(Content_1.Text, { className: "mt-1", children: ["Your", ' ', (0, jsx_runtime_1.jsxs)("span", { className: "font-bold", children: ["Standard Notes", userSubscriptionName ? ' ' : '', userSubscriptionName] }), ' ', "subscription has been canceled", ' ', isUserSubscriptionExpired ? ((0, jsx_runtime_1.jsxs)("span", { className: "font-bold", children: ["and expired on ", expirationDateString] })) : ((0, jsx_runtime_1.jsxs)("span", { className: "font-bold", children: ["but will remain valid until ", expirationDateString] })), ". You may resubscribe below if you wish."] }));
    }
    if (isUserSubscriptionExpired) {
        return ((0, jsx_runtime_1.jsxs)(Content_1.Text, { className: "mt-1", children: ["Your", ' ', (0, jsx_runtime_1.jsxs)("span", { className: "font-bold", children: ["Standard Notes", userSubscriptionName ? ' ' : '', userSubscriptionName] }), ' ', "subscription ", (0, jsx_runtime_1.jsxs)("span", { className: "font-bold", children: ["expired on ", expirationDateString] }), ". You may resubscribe below if you wish."] }));
    }
    return ((0, jsx_runtime_1.jsxs)(Content_1.Text, { className: "mt-1", children: ["Your", ' ', (0, jsx_runtime_1.jsxs)("span", { className: "font-bold", children: ["Standard Notes", userSubscriptionName ? ' ' : '', userSubscriptionName] }), ' ', "subscription will be ", (0, jsx_runtime_1.jsxs)("span", { className: "font-bold", children: ["renewed on ", expirationDateString] }), "."] }));
};
exports.default = (0, mobx_react_lite_1.observer)(SubscriptionStatusText);
