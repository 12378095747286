"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetH3BlockOption = exports.H3Block = exports.GetH2BlockOption = exports.H2Block = exports.GetH1BlockOption = exports.H1Block = void 0;
const selection_1 = require("@lexical/selection");
const lexical_1 = require("lexical");
const rich_text_1 = require("@lexical/rich-text");
const BlockPickerOption_1 = require("../BlockPickerPlugin/BlockPickerOption");
exports.H1Block = {
    name: 'Heading 1',
    iconName: 'h1',
    keywords: ['heading', 'header', 'h1'],
    onSelect: (editor) => editor.update(() => {
        const selection = (0, lexical_1.$getSelection)();
        if ((0, lexical_1.$isRangeSelection)(selection)) {
            (0, selection_1.$setBlocksType)(selection, () => (0, rich_text_1.$createHeadingNode)('h1'));
        }
    }),
};
function GetH1BlockOption(editor) {
    return new BlockPickerOption_1.BlockPickerOption(exports.H1Block.name, {
        iconName: exports.H1Block.iconName,
        keywords: exports.H1Block.keywords,
        onSelect: () => exports.H1Block.onSelect(editor),
    });
}
exports.GetH1BlockOption = GetH1BlockOption;
exports.H2Block = {
    name: 'Heading 2',
    iconName: 'h2',
    keywords: ['heading', 'header', 'h2'],
    onSelect: (editor) => editor.update(() => {
        const selection = (0, lexical_1.$getSelection)();
        if ((0, lexical_1.$isRangeSelection)(selection)) {
            (0, selection_1.$setBlocksType)(selection, () => (0, rich_text_1.$createHeadingNode)('h2'));
        }
    }),
};
function GetH2BlockOption(editor) {
    return new BlockPickerOption_1.BlockPickerOption(exports.H2Block.name, {
        iconName: exports.H2Block.iconName,
        keywords: exports.H2Block.keywords,
        onSelect: () => exports.H2Block.onSelect(editor),
    });
}
exports.GetH2BlockOption = GetH2BlockOption;
exports.H3Block = {
    name: 'Heading 3',
    iconName: 'h3',
    keywords: ['heading', 'header', 'h3'],
    onSelect: (editor) => editor.update(() => {
        const selection = (0, lexical_1.$getSelection)();
        if ((0, lexical_1.$isRangeSelection)(selection)) {
            (0, selection_1.$setBlocksType)(selection, () => (0, rich_text_1.$createHeadingNode)('h3'));
        }
    }),
};
function GetH3BlockOption(editor) {
    return new BlockPickerOption_1.BlockPickerOption(exports.H3Block.name, {
        iconName: exports.H3Block.iconName,
        keywords: exports.H3Block.keywords,
        onSelect: () => exports.H3Block.onSelect(editor),
    });
}
exports.GetH3BlockOption = GetH3BlockOption;
