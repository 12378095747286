"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlockEditorNodes = void 0;
const code_1 = require("@lexical/code");
const hashtag_1 = require("@lexical/hashtag");
const link_1 = require("@lexical/link");
const list_1 = require("@lexical/list");
const mark_1 = require("@lexical/mark");
const overflow_1 = require("@lexical/overflow");
const LexicalHorizontalRuleNode_1 = require("@lexical/react/LexicalHorizontalRuleNode");
const rich_text_1 = require("@lexical/rich-text");
const table_1 = require("@lexical/table");
const TweetNode_1 = require("./TweetNode");
const YouTubeNode_1 = require("./YouTubeNode");
const CollapsibleContainerNode_1 = require("../../Plugins/CollapsiblePlugin/CollapsibleContainerNode");
const CollapsibleContentNode_1 = require("../../Plugins/CollapsiblePlugin/CollapsibleContentNode");
const CollapsibleTitleNode_1 = require("../../Plugins/CollapsiblePlugin/CollapsibleTitleNode");
const FileNode_1 = require("../../Plugins/EncryptedFilePlugin/Nodes/FileNode");
const BubbleNode_1 = require("../../Plugins/ItemBubblePlugin/Nodes/BubbleNode");
const RemoteImageNode_1 = require("../../Plugins/RemoteImagePlugin/RemoteImageNode");
const InlineFileNode_1 = require("../../Plugins/InlineFilePlugin/InlineFileNode");
exports.BlockEditorNodes = [
    link_1.AutoLinkNode,
    code_1.CodeHighlightNode,
    code_1.CodeNode,
    CollapsibleContainerNode_1.CollapsibleContainerNode,
    CollapsibleContentNode_1.CollapsibleContentNode,
    CollapsibleTitleNode_1.CollapsibleTitleNode,
    hashtag_1.HashtagNode,
    rich_text_1.HeadingNode,
    LexicalHorizontalRuleNode_1.HorizontalRuleNode,
    link_1.LinkNode,
    list_1.ListItemNode,
    list_1.ListNode,
    mark_1.MarkNode,
    overflow_1.OverflowNode,
    rich_text_1.QuoteNode,
    table_1.TableCellNode,
    table_1.TableNode,
    table_1.TableRowNode,
    TweetNode_1.TweetNode,
    YouTubeNode_1.YouTubeNode,
    FileNode_1.FileNode,
    BubbleNode_1.BubbleNode,
    RemoteImageNode_1.RemoteImageNode,
    InlineFileNode_1.InlineFileNode,
];
