"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetDynamicTableBlocks = exports.GetTableBlockOption = void 0;
const BlockPickerOption_1 = require("../BlockPickerPlugin/BlockPickerOption");
const table_1 = require("@lexical/table");
function GetTableBlockOption(onSelect) {
    return new BlockPickerOption_1.BlockPickerOption('Table', {
        iconName: 'table',
        keywords: ['table', 'grid', 'spreadsheet', 'rows', 'columns'],
        onSelect: onSelect,
    });
}
exports.GetTableBlockOption = GetTableBlockOption;
function GetDynamicTableBlocks(editor, queryString) {
    const options = [];
    if (queryString == null) {
        return options;
    }
    const fullTableRegex = new RegExp(/^([1-9]|10)x([1-9]|10)$/);
    const partialTableRegex = new RegExp(/^([1-9]|10)x?$/);
    const fullTableMatch = fullTableRegex.exec(queryString);
    const partialTableMatch = partialTableRegex.exec(queryString);
    if (fullTableMatch) {
        const [rows, columns] = fullTableMatch[0].split('x').map((n) => parseInt(n, 10));
        options.push(new BlockPickerOption_1.BlockPickerOption(`${rows}x${columns} Table`, {
            iconName: 'table',
            keywords: ['table'],
            onSelect: () => editor.dispatchCommand(table_1.INSERT_TABLE_COMMAND, { columns: String(columns), rows: String(rows) }),
        }));
    }
    else if (partialTableMatch) {
        const rows = parseInt(partialTableMatch[0], 10);
        options.push(...Array.from({ length: 5 }, (_, i) => i + 1).map((columns) => new BlockPickerOption_1.BlockPickerOption(`${rows}x${columns} Table`, {
            iconName: 'table',
            keywords: ['table'],
            onSelect: () => editor.dispatchCommand(table_1.INSERT_TABLE_COMMAND, { columns: String(columns), rows: String(rows) }),
        })));
    }
    return options;
}
exports.GetDynamicTableBlocks = GetDynamicTableBlocks;
