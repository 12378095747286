"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResendMessage = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
class ResendMessage {
    constructor(sendMessage, encryptMessage) {
        this.sendMessage = sendMessage;
        this.encryptMessage = encryptMessage;
    }
    async execute(params) {
        const encryptedMessage = this.encryptMessage.execute({
            message: params.decryptedMessage,
            keys: params.keys,
            recipientPublicKey: params.recipient.publicKeySet.encryption,
        });
        if (encryptedMessage.isFailed()) {
            return domain_core_1.Result.fail(encryptedMessage.getError());
        }
        const sendMessageResult = await this.sendMessage.execute({
            recipientUuid: params.recipient.contactUuid,
            encryptedMessage: encryptedMessage.getValue(),
            replaceabilityIdentifier: params.rawMessage.replaceability_identifier || undefined,
        });
        return sendMessageResult;
    }
}
exports.ResendMessage = ResendMessage;
